import React from 'react';
import ReactTooltip from 'react-tooltip';
import theme from '../../../style/theme';
import './ToolTips.css';

const ToolTip = (props) => {
    return (
        <ReactTooltip
            backgroundColor={theme.global.colors.black}
            className="tool-tip"
            {...props}
            style={{ zIndex: '9999' }}
            effect="solid"
            place="right"
        />
    );
};

ToolTip.rebuild = ReactTooltip.rebuild;
ToolTip.hide = ReactTooltip.hide;
ToolTip.show = ReactTooltip.show;

export default ToolTip;
