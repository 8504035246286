import { Box } from 'grommet';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import useClientHTML from '../../../hooks/useClientHTML';
import ClientHTML from '../../atoms/ClientHTML/ClientHTML';
import { Navbar, OuterScroll } from '../../MDW/MDW';
import SideBar from '../../organisms/SideBar/SideBar';
import useActionWhenLocationAspectChanges, {
    locationAspectFunctions,
} from '../../../hooks/useActionWhenLocationAspectChanges';
import { updateCurrentClass } from '../../../redux/actions/actions';
import store from '../../../redux/store';
import theme from '../../../style/theme';
export const SideBarAndDataDownloadContext = React.createContext();

export const sidebarTitles = {
    browserSearch: 'Browser',
    structuralMetadata: 'Structural',
};

const GenericPageTemplate = ({ children, ...props }) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const [openFirstClass, setOpenFirstClass] = useState(false);
    const [showSidebarGlossary, setShowSidebarGlossary] = useState(false);
    const [showDataDownloadBrowser, setShowDataDownloadBrowser] = React.useState(false);
    const [showAssets, setShowAssets] = useState(false);
    const [showVersionHistory, setShowVersionHistory] = useState(false);
    const [showTreeData, setShowTreeData] = useState(false);
    const sideBarAndDataDownloadState = useMemo(() => {
        return {
            showSidebar,
            setShowSidebar,
            openFirstClass,
            setOpenFirstClass,
            showDataDownloadBrowser,
            setShowDataDownloadBrowser,
            showAssets,
            setShowAssets,
            showVersionHistory,
            setShowVersionHistory,
            showTreeData,
            setShowTreeData,
            showSidebarGlossary,
            setShowSidebarGlossary,
        };
    }, [
        showSidebar,
        setShowSidebar,
        openFirstClass,
        setOpenFirstClass,
        showDataDownloadBrowser,
        setShowDataDownloadBrowser,
        showAssets,
        setShowAssets,
        showVersionHistory,
        setShowVersionHistory,
        showTreeData,
        setShowTreeData,
        showSidebarGlossary,
        setShowSidebarGlossary,
    ]);
    const location = useLocation();
    const [sideBarTitle, setSideBarTitle] = useState();
    let adrConfig = window.config.branding === 'adr';
    const { clientHTML } = useClientHTML(['header', 'footer'], props.force);

    React.useEffect(() => {
        const endpoint = location.pathname.split('/').reverse()[0];
        if (endpoint === 'search') {
            setSideBarTitle(sidebarTitles.browserSearch);
        } else if (endpoint === 'dataset') {
            setSideBarTitle(sidebarTitles.structuralMetadata);
        }
    }, [location]);

    const clearSpecialModesWhenExitingIndividualDatasetView = useCallback(
        (location) => {
            if (location.pathname !== '/browser/dataset') {
                setShowDataDownloadBrowser(false);
                store.dispatch(updateCurrentClass({}));
            }
        },
        [setShowDataDownloadBrowser],
    );
    useActionWhenLocationAspectChanges(
        clearSpecialModesWhenExitingIndividualDatasetView,
        locationAspectFunctions.locationPathname,
    );

    const onScroll = useCallback((e) => {
        const element = e.currentTarget.previousSibling?.firstChild;
        const isNum = !isNaN(element?.style.opacity);
        const isDefinedNonZero = element?.style.opacity && parseInt(element?.style.opacity);
        if (element && isNum && !isDefinedNonZero) {
            element.style.opacity = '1.0';
            setTimeout(() => {
                element.style.opacity = '0.0';
            }, 1000);
        }
    }, []);

    return (
        <Box fill>
            <OuterScroll heightRelativeToParent="100%" allowOuterScroll onScroll={onScroll}>
                <Box height={{ min: '100vh' }}>
                    <ClientHTML HTML={clientHTML.header} />
                    <Box
                        fill
                        className="generic-page-template"
                        direction="row"
                        background={
                            theme.global.colors[window.localStorage.getItem('branding') || 'default']
                                ?.mainBackgroundColor
                        }
                        flex="grow"
                    >
                        <Navbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} sideBarTitle={sideBarTitle} />
                        <Box
                            direction="row"
                            flex
                            overflow={{
                                horizontal: 'hidden',
                            }}
                        >
                            <SideBarAndDataDownloadContext.Provider value={sideBarAndDataDownloadState}>
                                <SideBar
                                    showSidebar={showSidebar}
                                    setShowSidebar={setShowSidebar}
                                    setOpenFirstClass={setOpenFirstClass}
                                    openFirstClass={openFirstClass}
                                    title={sideBarTitle}
                                    showTreeData={showTreeData}
                                    showSidebarGlossary={showSidebarGlossary}
                                    setShowSidebarGlossary={setShowSidebarGlossary}
                                />
                                <Box flex align="center" justify="center" overflow={'auto'}>
                                    {children}
                                </Box>
                            </SideBarAndDataDownloadContext.Provider>
                        </Box>
                    </Box>
                    <ClientHTML HTML={clientHTML.footer} />
                </Box>
            </OuterScroll>
        </Box>
    );
};

export const useSideBarAndDataDownloadContext = () => {
    const context = React.useContext(SideBarAndDataDownloadContext);

    // if (context === undefined) {
    //     throw new Error('useSetShowSidebar must be used within a ShowSidebarProvider');
    // }

    return context;
};

GenericPageTemplate.propTypes = {
    children: PropTypes.node.isRequired,
};

export default GenericPageTemplate;
