import React, { Suspense, useState, useEffect } from 'react';
import ErrorBoundary from '../utils/ErrorBoundary';
import { useIdleTimer } from 'react-idle-timer';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import CookieConsentModal from '../components/organisms/CookieConsentModal/CookieConsentModal';
import useGoogleAnalytics from '../hooks/useGoogleAnalytics/useGoogleAnalytics';
// import { handleLocalStorageChange } from '../utils/authentication';
import history from '../utils/history';

const PageNotFoundPage = React.lazy(() => import('../components/pages/PageNotFoundPage/PageNotFoundPage'));
const LandingPage = React.lazy(() => import('../components/pages/LandingPage/LandingPage'));
const SomethingWentWrongPage = React.lazy(() =>
    import('../components/pages/SomethingWentWrongPage/SomethingWentWrongPage'),
);

const BoundedLandingPage = () => (
    <ErrorBoundary>
        <LandingPage />
    </ErrorBoundary>
);
const Pages = React.lazy(() => import('../components/pages/Pages/Pages'));

const redirectFunction = () => {
    window.location.href = window.config.homepageOverride;
};

const Routes = () => {
    useGoogleAnalytics();
    return (
        <>
            <CookieConsentModal />
            <Switch>
                {window.localStorage.getItem('branding') === 'adr' ? (
                    <Route path="/" exact>
                        <Redirect to="browser/search" />
                    </Route>
                ) : (
                    <Route
                        path="/"
                        exact
                        {...(window?.config?.homepageOverride
                            ? { render: redirectFunction }
                            : { component: BoundedLandingPage })}
                    />
                )}

                <Route
                    path="/home"
                    exact
                    {...(window?.config?.homepageOverride
                        ? { render: redirectFunction }
                        : { component: BoundedLandingPage })}
                />
                <Route path="/browser" component={Pages} />
                <Route path="/something-went-wrong" component={SomethingWentWrongPage} />
                <Route path="/page-not-found" component={PageNotFoundPage} />
                <Route component={PageNotFoundPage} />
            </Switch>
        </>
    );
};

const App = () => {
    useIdleTimer({
        timeout: 1000 * 60 * 30,
        onIdle: () => {
            console.log('idle');
        },
        debounce: 500,
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        waitForConfig();
    }, []);

    const waitForConfig = () => {
        if (window.configLoaded) setIsLoading(false);
        else window.setTimeout(waitForConfig.bind(this), 100);
    };

    // React.useEffect(() => {
    //     window.addEventListener('storage', handleLocalStorageChange);
    //     return () => {
    //         window.removeEventListener('storage', handleLocalStorageChange);
    //     };
    // }, []);

    if (isLoading) return null;
    else
        return (
            <Router history={history}>
                <LastLocationProvider>
                    <Suspense fallback={<div></div>}>
                        <Routes />
                    </Suspense>
                </LastLocationProvider>
            </Router>
        );
};

export default App;
