import { useEffect } from 'react';

export const MDX_BROWSER_PREFIX = 'MDX Browser > ';
export const ADR_BROWSER_PREFIX = 'ADR Browser > ';
export function useTitle(title: string) {
    useEffect(() => {
        const prevTitle = document.title;
        document.title = title;
        return () => {
            document.title = prevTitle;
        };
    }, [title]);
}
