import { Layer } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import DataElementPopupContents from './DataElementPopupContents';
import { useLocation, useHistory } from 'react-router-dom';

const StyledLayer = styled(Layer)`
    background-color: transparent;
`;

const DataElementPopup = ({
    rebuildToolTip,
    setIsModalShown,
    currentClass,
    currentElement,
    heightNumber,
    isHeader,
    publisher,
    ...props
}) => {
    const element = currentElement;
    const popUpItemRefs = Array(5)
        .fill({})
        .map((item) => React.createRef());

    const dataElementPopupListRef = React.useRef();
    const [ping, pingState] = React.useState(0);
    const [height, setHeight] = React.useState();
    const location = useLocation();
    const history = useHistory();
    React.useEffect(() => {
        let sum = 0;
        const padding = 12;
        popUpItemRefs.forEach((ref, index) => {
            if (index !== 0 && ref && ref.current) {
                sum += padding * 3 + 1;
            }
        });
        popUpItemRefs.forEach((item) => {
            if (item && item.current && item.current.clientHeight) {
                sum += item.current.clientHeight;
            }
        });
        Math.abs(height - sum) > 10 && setHeight(sum);
    }, [ping, popUpItemRefs, height]);
    const handleClose = () => {
        setIsModalShown && setIsModalShown(false);
        history.push('dataset' + location.search.split('&')[0] + '&' + location.search.split('&')[1]);
    };
    return (
        <StyledLayer onEsc={handleClose} animation="fadeIn">
            <DataElementPopupContents
                element={element}
                popUpItemRefs={popUpItemRefs}
                pingState={pingState}
                ping={ping}
                height={height}
                rebuildToolTip={rebuildToolTip}
                setIsModalShown={setIsModalShown}
                listRef={dataElementPopupListRef}
                isHeader={isHeader}
                publisher={publisher}
            ></DataElementPopupContents>
        </StyledLayer>
    );
};

export default DataElementPopup;

DataElementPopup.propTypes = {
    rebuildToolTip: PropTypes.func.isRequired,
    setIsModalShown: PropTypes.func.isRequired,
    currentClass: PropTypes.object.isRequired,
    currentElement: PropTypes.object.isRequired,
    heightNumber: PropTypes.number.isRequired,
    round: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
