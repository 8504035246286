import { Box, Text } from 'grommet';
import React from 'react';
import styled from 'styled-components';
import theme from '../../../../style/theme';
import { isTesting } from '../../../../utils/testingUtils';
import { Button, List, ToolTip } from '../../../MDW/MDW';
import { classItemIdPrefix } from '../../IndividualDatasetPage/IndividualDatasetPageContents/IndividualDatasetPageContents';

export const StyledListItemText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ListItemButton = styled(Button)`
    &span: hover {
        font-weight: bold;
    }
    &:focus {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

export const ListContainerBox = styled(Box)`
    font-weight: inherit;
`;

const ListWrapper = ({ className, ...props }) => {
    return (
        <div className={className}>
            <List {...props} />
        </div>
    );
};

const StyledClassList = styled(ListWrapper)`
    .track-vertical {
        background-color: transparent;
    }
`;

export const dataClassTestId = 'Data class list item';

export const DataClassList = (props) => {
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

    const rebuildToolTip = React.useCallback(() => {
        ToolTip.rebuild();
    }, []);

    const onResize = React.useCallback(() => {
        setWindowHeight(window.innerHeight);
    }, []);

    React.useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [onResize]);

    return (
        <Box ref={props.listItemContainerRef}>
            <StyledClassList
                onScroll={rebuildToolTip}
                plain
                round={props.round}
                listLength={(props.optionLength && props.optionLength) || 0}
                rowDirection="column"
                heightNumber={
                    props.optionLength && props.optionLength > 9
                        ? windowHeight * 0.7
                        : (props.optionLength && props.optionLength * 25) + 25
                }
                listRef={props.listRef}
                dataTestId="class-element-enum-list"
                rowPadding={{
                    horizontal: `xsmall`,
                    vertical: '2px',
                }}
            >
                {({ index }) => {
                    const listItemRef = React.createRef();
                    const selectItem =
                        props.processClassListItemClicked &&
                        props.processClassListItemClicked(
                            listItemRef,
                            props.listItemContainerRef,
                            `${classItemIdPrefix}${props.options && props.options[index].id}`,
                        );

                    if (props.sidebarPreviouslyNotShownButNowShown && index === 0) {
                        const selectItemWhenRefsPopulated = async () => {
                            await Promise.all([
                                listItemRef,
                                listItemRef.current,
                                props.listItemContainerRef,
                                props.listItemContainerRef.current,
                            ]);
                            selectItem(null);
                        };
                        selectItemWhenRefsPopulated();
                    }

                    const itemSelected = props.options && props.options[index]?.id === props.currentClassId;

                    return (
                        <ListItemButton
                            className="list-item-button"
                            plain
                            onClick={selectItem}
                            key={`menu-class-list-${index}`}
                        >
                            <ListContainerBox
                                ref={listItemRef}
                                width={{
                                    max: '180px',
                                }}
                                pad={{
                                    horizontal: 'medium',
                                    vertical: '3px',
                                }}
                                data-tip={props.options && props.options[index].name}
                            >
                                <StyledListItemText
                                    size="small"
                                    data-testid={dataClassTestId}
                                    color="dark"
                                    weight={itemSelected ? 'bold' : ''}
                                >
                                    {props.options && props.options[index].name
                                        ? props.options && props.options[index].name
                                        : props.options && props.options[index]}{' '}
                                </StyledListItemText>
                            </ListContainerBox>
                        </ListItemButton>
                    );
                }}
            </StyledClassList>
            {!isTesting && <ToolTip />}
        </Box>
    );
};
