import { Box, Heading, ResponsiveContext, Text } from 'grommet';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { List, ScrollButtons, Tag, ToolTip } from '../../MDW/MDW';
import { MDWListProps } from '../../organisms/List/List';
import BrokerSearchPageListItem, { SearchAPIResponse } from './BrokerSearchPageListItem';
import MemoizationOfTags from './MemoizationOfTags';
import theme from '../../../style/theme';

interface BoxWrapperType extends MDWListProps {
    className?: string;
}

const BrokerSearchPageListWrapper = ({ className, ...props }: BoxWrapperType) => (
    <Box {...{ className }}>
        <List {...props} />
    </Box>
);

const StyledBrokerSearchPageList = styled(BrokerSearchPageListWrapper)`
    .track-vertical {
        display: none;
    }
`;

export interface TagObjectType {
    label: any;
    ref: React.RefObject<unknown>;
    type: string;
}

interface BrokerSearchPageListType {
    dataSets: SearchAPIResponse[];
    errorMessage: string;
    onTagClick: (tag: TagObjectType) => void;
    listRef: any;
}

const BrokerSearchPageList = React.memo(({ dataSets, errorMessage, onTagClick, listRef }: BrokerSearchPageListType) => {
    const size = React.useContext(ResponsiveContext);
    // @ts-ignore
    const state = useSelector((state) => state.sortbyIntegratedItem);
    let sortedDataSets = dataSets.filter((index) => index.dataModelType === state.key);

    let adrConfig = window.config.branding === 'adr';
    let OriginColors = {
        'ONS Secure Research Service': '#000080',
        'Scottish Government': '#159947',
        'SAIL Databank (Wales)': '#ADD8E6',
        'NISRA Data Catalogue': ' #0000FF',
    };
    let colors = [
        { color: '#427AA9', isDark: true }, // SteelBlue
        { color: '#2F4F4F', isDark: true }, // DarkSlateGray
        { color: '#808000', isDark: true }, // Olive
        { color: '#6A5ACD', isDark: true }, // SlateBlue
        { color: '#556B2F', isDark: true }, // DarkOliveGreen
        { color: '#8B4513', isDark: true }, // SaddleBrown
    ];

    const rebuildToolTip = React.useCallback(() => {
        ToolTip.rebuild();
    }, []);

    const rowHeight = size !== 'small' ? 142 : 90;

    return !dataSets || (dataSets && dataSets.length === 0) ? (
        <Box fill margin={{ top: 'medium', left: 'medium' }}>
            No items to show in list...
        </Box>
    ) : !errorMessage ? (
        <React.Fragment>
            <StyledBrokerSearchPageList
                onScroll={rebuildToolTip}
                // @ts-ignore
                listLength={
                    Object.keys(state).length !== 0
                        ? sortedDataSets && sortedDataSets.length
                        : dataSets && dataSets.length
                }
                rowHeight={rowHeight}
                heightNumber={dataSets?.length ? rowHeight * dataSets?.length : 0}
                listRef={listRef}
                border={`none`}
                rowBorderRadius={'6px'}
                rowBorder={adrConfig ? { color: 'black', size: '1px' } : {}}
                percentageMargin={3}
            >
                {({ index: datasetIndex }: { index: number }) => {
                    const dataset =
                        Object.keys(state).length !== 0 ? sortedDataSets[datasetIndex] : dataSets[datasetIndex];
                    return (
                        <BrokerSearchPageListItem dataset={dataset}>
                            {(dataset?.searchResultType === 'DataClass' ||
                                dataset?.searchResultType === 'DataElement') && (
                                <Box align="between" gap="3px">
                                    <Box width={{ max: '300px' }} height={{ min: '20px' }} margin={{ top: '2px' }}>
                                        <Text
                                            weight={'bold'}
                                            color={
                                                theme?.global?.colors?.[
                                                    window.localStorage.getItem('branding') || 'default'
                                                ]?.secondary
                                            }
                                            truncate={true}
                                            size="small"
                                        >
                                            {dataset?.summaryTittle}
                                        </Text>
                                    </Box>
                                    <Box width={{ max: '300px' }} height={{ min: '16px' }}>
                                        <Text weight={'bold'} truncate={true} size="xsmall">
                                            {dataset?.searchResultType}
                                        </Text>
                                    </Box>
                                </Box>
                            )}
                            {size !== 'small' &&
                                !(
                                    dataset?.searchResultType === 'DataClass' ||
                                    dataset?.searchResultType === 'DataElement'
                                ) && (
                                    <ScrollButtons ariaHiddenButtons height={{ max: '30px' }}>
                                        <Box direction="row">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexFlow: 'row wrap',
                                                }}
                                            >
                                                {window?.config?.displayOrigin && dataset?.origin && (
                                                    <span style={{ marginRight: '10px' }}>
                                                        <Tag
                                                            ariaHidden
                                                            backgroundColor={
                                                                [
                                                                    'ONS Secure Research Service',
                                                                    'NISRA Data Catalogue',
                                                                    'SAIL Databank (Wales)',
                                                                    'Scottish Government',
                                                                ].includes(dataset.origin?.name)
                                                                    ? // @ts-ignore
                                                                      OriginColors[dataset.origin?.name]
                                                                    : // @ts-ignore
                                                                      colors[dataset.origin?.id].color
                                                            }
                                                            // backgroundColor={
                                                            //     // adrConfig
                                                            //     // @ts-ignore
                                                            //     OriginColors[dataset.origin?.name]
                                                            // }
                                                            // color={
                                                            //     [
                                                            //         'ONS Secure Research Service',
                                                            //         'NISRA Data Catalogue',
                                                            //         'Scottish Government',
                                                            //     ].includes(dataset.origin?.name)
                                                            //         ? 'white'
                                                            //         : 'black'
                                                            // }
                                                            color={
                                                                [
                                                                    'ONS Secure Research Service',
                                                                    'NISRA Data Catalogue',
                                                                    'SAIL Databank (Wales)',
                                                                    'Scottish Government',
                                                                ].includes(dataset.origin?.name)
                                                                    ? 'white'
                                                                    : //@ts-ignore
                                                                    colors[dataset.origin?.id].isDark
                                                                    ? 'white'
                                                                    : 'black'
                                                            }
                                                            dataTip={dataset.origin?.url}
                                                        >
                                                            {dataset.origin?.name}
                                                        </Tag>
                                                    </span>
                                                )}
                                                {dataset?.keywords && (
                                                    <>
                                                        <div
                                                            style={{
                                                                fontSize: '13px',
                                                                lineHeight: '31px',
                                                                fontWeight: 'bolder',
                                                            }}
                                                        >
                                                            keywords:
                                                        </div>
                                                        <MemoizationOfTags
                                                            // @ts-ignore
                                                            dataset={dataset}
                                                            datasetIndex={datasetIndex}
                                                            onTagClick={onTagClick}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </Box>
                                    </ScrollButtons>
                                )}
                        </BrokerSearchPageListItem>
                    );
                }}
            </StyledBrokerSearchPageList>
        </React.Fragment>
    ) : (
        <Box>
            <Heading level={4} textAlign="start" color="error" margin={{ bottom: 'small' }}>
                Error getting dataSets from server:
            </Heading>
            <Text size="small" textAlign="start" color="error">
                {errorMessage}
            </Text>
        </Box>
    );
});

BrokerSearchPageList.whyDidYouRender = true;

export default BrokerSearchPageList;
