import { Box, Image, Text } from 'grommet';
import { useRef, useState } from 'react';
import analytics from '../../../analytics/analytics';
import { getAssets } from '../../../api/getAssets';
import { DownloadIcon, FileIcon } from '../../../assets/icons/MDW-icons';
import { formatBytes } from '../../../utils/javascriptUtilities';
import { StyledLink } from './BrowserAssets';
const sendDownloadEvent = (linkStem: string | undefined, id: any) => {
    analytics.sendEvent({
        category: 'Individual Dataset',
        action: `File Download`,
        label: `${linkStem}/${id}`,
    });
};

const BrowserAssetsFileRow = (props: {
    fileTreeNodeName: string;
    fullPathName: string;
    size: number;
    linkStem?: string;
    id: any;
}) => {
    const [error, setError] = useState<any>();
    const downloadRef = useRef<any>();
    const onDownload = async () => {
        sendDownloadEvent(props.linkStem, props.id);
        await getAssets(props.id, props.fileTreeNodeName).catch((error) => {
            error.message = '***** File not available, please consult your administrator ***** ';
            setError(error);
        });
    };

    return (
        <Box ref={downloadRef} onClick={onDownload}>
            {error ? (
                <div>Sorry an error occured. Please try again after refreshing the page. {error.message}</div>
            ) : (
                <Box direction="row" justify="between" pad={{ right: 'medium' }}>
                    <Box direction="row" gap="small">
                        <Image alt={'File Icon'} height="24px" width="24px" src={FileIcon} />
                        <StyledLink aria-hidden={'true'} tabIndex={-1}>
                            <Text wordBreak="break-word" data-testid="File Download Link Text" weight={400}>
                                {props.fileTreeNodeName}
                            </Text>
                        </StyledLink>
                    </Box>
                    <Box direction="row" gap="small" pad={{ left: 'large' }}>
                        <Text>{props.size === 0 ? '0 Bytes' : formatBytes(props.size)}</Text>
                        <a>
                            <Image
                                data-testid="File Download Link Icon"
                                alt={'Download File'}
                                height="24px"
                                width="24px"
                                src={DownloadIcon}
                            />
                        </a>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default BrowserAssetsFileRow;
