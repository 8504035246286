import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../../style/theme';
import { CustomScroll, Button } from '../../MDW/MDW';
import { Box, Heading } from 'grommet';
import { FormClose } from 'grommet-icons';
import { useForm } from './Form';

const FormContainer = React.memo(({ children, ...props }) => {
    const context = useForm();

    return (
        <Box
            width={(context && context.width) || props.width || 'medium'}
            height={(context && context.height) || props.height}
            background="white"
            elevation={(context && context.elevation) || props.elevation || 'small'}
            round={(context && context.round) || props.round || theme.radii.large}
        >
            <Box direction="row" justify="between">
                <Heading margin={(context && context.headingMargin) || props.headingMargin || 'medium'} level="4">
                    {(context && context.headingText) || props.headingText}
                </Heading>
                {((context && context.setShowFormModal) || props.setShowFormModal) && (
                    <Box pad="xsmall">
                        <Button
                            alignSelf="start"
                            plain
                            icon={<FormClose />}
                            onClick={() => {
                                context ? context.setShowFormModal(false) : props.setShowFormModal(false);
                            }}
                        />
                    </Box>
                )}
            </Box>
            <CustomScroll heightRelativeToParent="100%">
                <Box fill pad={{ top: 'none', horizontal: 'medium' }}>
                    {children}
                </Box>
            </CustomScroll>
        </Box>
    );
});

FormContainer.whyDidYouRender = true;

export default FormContainer;

FormContainer.propTypes = {
    children: PropTypes.node.isRequired,
    headingText: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    elevation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    round: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    setShowFormModal: PropTypes.func,
};
