import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../../style/theme';

const StyledLabel = styled.label`
    font-weight: ${theme.fontWeights.body};
    font-size: ${theme.fontSizes[2]};
`;

const Label = ({ children, htmlFor, ...props }) => {
    return (
        <StyledLabel htmlFor={htmlFor} {...props}>
            {children}
        </StyledLabel>
    );
};

export default Label;

Label.propTypes = {
    htmlFor: PropTypes.string.isRequired,
};
