import { resultsResponseMethodGenerator, processResultMethodGenerator } from '../../../utils/globalReponseHandlers';

const formUtils = (fetchCall, preFetchMiddleWare, postFetchMiddleWare) => {
    const onSubmit = (updateUICallback) => (values, { setSubmitting }) => {
        return fetchCall(preFetchValuesMiddleWare(trimValuesWhitespace(values)))
            .then((fetchResult) => {
                setSubmitting(false);
                return updateUICallback(fetchResult);
            })
            .catch((error) => {
                setSubmitting(false);
                updateUICallback(error);
            });
    };

    const preFetchValuesMiddleWare = (values) => {
        let returnValues;
        if (preFetchMiddleWare) {
            returnValues = preFetchMiddleWare(values);
            if (!returnValues) {
                throw Error('Callback passed as preFetchMiddleWare must return values for the fetch.');
            }
            return returnValues;
        } else {
            return values;
        }
    };

    const successfulResponseMiddleWare = (result) => {
        let returnValue;
        if (postFetchMiddleWare) {
            returnValue = postFetchMiddleWare(result);
        }
        if (!!returnValue) {
            throw Error('postFetchMiddleWare callback must not return anything.');
        }
    };

    const processResultsResponse = resultsResponseMethodGenerator(successfulResponseMiddleWare);

    const processResult = processResultMethodGenerator(processResultsResponse);

    return {
        onSubmit,
        processResultsResponse,
        processResult,
    };
};

export default formUtils;

export const trimValuesWhitespace = (values) => {
    return Object.keys(values).reduce((acc, key) => ({ ...acc, [`${key}`]: values[key].trim() }), {});
};
