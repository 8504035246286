import { Box } from 'grommet';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { fetchEndPoint } from '../../../../api/fetchEndpoint';
import { featchEndPointInclude } from '../../../../api/featchEndPointInclude';
import useIsReduxStateReady from '../../../../hooks/useIsReduxStateReady/useIsReduxStateReady';
import { clearAllSelectedFilters, setFilteredDataSets, updateBrowserControls } from '../../../../redux/actions/actions';
import store from '../../../../redux/store';
import history from '../../../../utils/history';
import { isItemPopulated } from '../../../../utils/javascriptUtilities';
import { Paginate } from '../../../MDW/MDW';
import GenericPageContentHeader from '../../../templates/GenericPageTemplate/GenericPageContentHeader';
import BrokerSearchPageList from '../BrokerSearchPageList';
import BrokerSearchComponent from './BrokerSearchComponent';
import {
    addFilter,
    extractPageNumberFromReduxState,
    generateUrlQuery,
    genericClickFunction,
    handleScrollTo,
    removeFilter,
    removeHighlightTag,
    setDataSets,
    setHighlightAllTags,
} from './BrokerSearchPageContentsUtils';
import useActionWhenLocationAspectChanges, {
    locationAspectFunctions,
} from '../../../../hooks/useActionWhenLocationAspectChanges';
import analytics from '../../../../analytics/analytics';
import { useDispatch } from 'react-redux';
import { setSortbyIntegratedItem, triggerForSearchAllDatasetInput } from '../../../../redux/actions/actions';
const hideToolTip = () => {
    ReactTooltip.hide();
    ReactTooltip.rebuild();
};
const fetchEndPointOnLocationSearchChange = (location) => {
    if (history.action === 'POP') {
        clearTags();
        fetchEndPoint(location.search, setDataSets);
    }
};

const BrokerSearchPageContents = ({ errorMessage, ...props }) => {
    const listRef = React.useRef();
    var dispatch = useDispatch();

    useActionWhenLocationAspectChanges(fetchEndPointOnLocationSearchChange, locationAspectFunctions.locationSearch);

    const { data: dataSets } = useIsReduxStateReady('filteredDataSets');

    React.useEffect(() => {
        store.dispatch(setFilteredDataSets(store.getState().dataSets));
    }, []);
    const setInitialBrowserControls = React.useCallback(() => {
        dataSets &&
            dataSets.pageSize &&
            store.dispatch(
                updateBrowserControls({
                    pageSize: dataSets.pageSize,
                    pageNumber: 1,
                }),
            );
    }, [dataSets]);

    React.useEffect(() => {
        processQueryParameters();
        setInitialBrowserControls();
    }, [setInitialBrowserControls]);

    const paginationSizeChangePayloadMiddleware = (payload) => {
        payload &&
            store.dispatch(
                updateBrowserControls({
                    ...store.getState().browserControls,
                    pageSize: payload,
                }),
            );
        return !isNaN(Number(payload)) ? Number(payload) : payload;
    };
    const paginationClickPayloadMiddleware = (payload) => {
        handleScrollTo(listRef, 0);
        const value = payload.selected + 1;
        value &&
            store.dispatch(
                updateBrowserControls({
                    ...store.getState().browserControls,
                    pageNumber: value,
                }),
            );
        return value;
    };
    const onClickPaginationSizeChange = genericClickFunction(
        setDataSets,
        'pageSize',
        paginationSizeChangePayloadMiddleware,
    );
    const onClickPagination = genericClickFunction(setDataSets, 'pageNumber', paginationClickPayloadMiddleware);

    const processQueryParameters = () => {
        const parameters = queryString.parse(document.location.search);
        if (Object.keys(parameters)) {
            Object.keys(parameters).forEach((key) => {
                if (parameters[key].includes('::')) {
                    parameters[key].split('::').forEach((filter) => {
                        const ref = React.createRef();
                        if (filter)
                            addFilter({
                                label: filter.trim(),
                                ref,
                                type: key,
                            });
                    });
                } else {
                    const ref = React.createRef();
                    if (parameters[key].trim())
                        addFilter({
                            label: parameters[key].trim(),
                            ref,
                            type: key,
                        });
                }
            });
        }
    };

    const onDataSetTagClick = React.useCallback(
        (tagObject) => () => {
            hideToolTip();
            addFilter(tagObject);
            const endPoint = generateUrlQuery().query;
            return fetchEndPoint(endPoint, setDataSets);
        },
        [],
    );

    const onSearchBarSubmit = React.useCallback((searchBarValue) => {
        if (searchBarValue.hasOwnProperty('payload')) {
            store.getState().selectedFilters.forEach((selectedFilter) => {
                if (selectedFilter.type === 'searchterm' || selectedFilter.type === 'include') {
                    removeFilter(selectedFilter);
                }
            });
            const { payload, inputValue } = searchBarValue;

            if (inputValue?.toLowerCase().trim())
                addFilter({
                    label: inputValue?.toLowerCase().trim(),
                    ref: undefined,
                    type: 'searchterm',
                });

            searchBarValue.payload.split('::').forEach((value) => {
                if (value)
                    addFilter({
                        label: value,
                        ref: undefined,
                        type: 'include',
                    });
            });

            analytics.sendEvent({
                category: 'Search',
                action: 'Search',
                label: searchBarValue,
            });
            return featchEndPointInclude(inputValue, payload, setDataSets);
        } else {
            if (searchBarValue) {
                store.getState().selectedFilters.forEach((selectedFilter) => {
                    if (selectedFilter.type === 'searchterm') {
                        removeFilter(selectedFilter);
                    }
                });

                if (searchBarValue?.toLowerCase().trim())
                    addFilter({
                        label: searchBarValue?.toLowerCase().trim(),
                        ref: undefined,
                        type: 'searchterm',
                    });

                analytics.sendEvent({
                    category: 'Search',
                    action: 'Search',
                    label: searchBarValue,
                });
            }
            const endPoint = generateUrlQuery().query;
            return fetchEndPoint(endPoint, setDataSets);
        }
    }, []);
    return isItemPopulated(dataSets) ? (
        <GenericPageContentHeader
            title=""
            subTitle=""
            customComponent={
                <BrokerSearchComponent
                    listStateArray={[]}
                    onSubmit={onSearchBarSubmit}
                    resultSize={dataSets && dataSets.totalCount}
                    paginationTotal={dataSets && dataSets.pageCount}
                    pageNumber={dataSets && dataSets.currentPage}
                    onClickPaginationSizeChange={onClickPaginationSizeChange}
                    paginationSize={dataSets && dataSets.pageSize}
                    searchResults={dataSets && dataSets.searchResult}
                />
            }
        >
            <Box direction="row" gap="medium" fill>
                <Box fill>
                    <BrokerSearchPageList
                        dataSets={dataSets && dataSets.content}
                        errorMessage={errorMessage}
                        onTagClick={onDataSetTagClick}
                        listRef={listRef}
                    />
                    <Box direction="row" justify="end">
                        {dataSets && dataSets.totalPages > 0 && (
                            <Paginate
                                getReduxState={extractPageNumberFromReduxState}
                                pageCount={dataSets.totalPages}
                                pageRangeDisplayed={4}
                                onPageChange={onClickPagination}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </GenericPageContentHeader>
    ) : (
        <div></div>
    );
};

export default BrokerSearchPageContents;

BrokerSearchPageContents.propTypes = {
    errorMessage: PropTypes.string,
};

BrokerSearchPageContents.whyDidYouRender = true;

export const onSelectedFilterTagClicked = (tagObject) => () => {
    hideToolTip();
    removeFilter(tagObject);
    let tag = `${encodeURIComponent(tagObject.label)}`;
    let searchParams = window.localStorage.getItem('searchParams');
    let filteredTag = searchParams.replace(tag, '');
    window.localStorage.setItem('searchParams', filteredTag);
    const endPoint = generateUrlQuery().query;
    return fetchEndPoint(endPoint, setDataSets);
};

export const onClearAllClicked = () => {
    clearTags();
    store.dispatch(setSortbyIntegratedItem({}));
    store.dispatch(triggerForSearchAllDatasetInput('clear'));
    window.localStorage.setItem('initalAdvancedSearchChecks', true);
    const endPoint = generateUrlQuery().query;
    window.localStorage.removeItem('searchParams');
    return fetchEndPoint(endPoint, setDataSets);
};

export const onCheckBoxClicked = (checkBoxObject) => () => {
    hideToolTip();
    checkBoxObject.ref.current.checked ? addFilter(checkBoxObject) : removeFilter(checkBoxObject);
    const endPoint = generateUrlQuery().query;
    return fetchEndPoint(endPoint, setDataSets);
};
const clearTags = () => {
    hideToolTip();
    store.getState().selectedFilters.forEach((tagObject) => {
        setHighlightAllTags(tagObject, removeHighlightTag);
        removeFilter(tagObject);
    });
    store.dispatch(clearAllSelectedFilters());
};
