import { Box, Text, Heading } from 'grommet';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CheckBoxGroup } from '../../../MDW/MDW';
import { accordionPanelidPrefix } from './BrokerSearchPageContentsUtils';
import { StyledAccordion, StyledAccordionBox, StyledAccordionPanel } from './StyledComponents';
import theme from '../../../../style/theme';
const SearchMenuComponent = React.memo(({ menuItems, ...props }) => {
    const [activePanels, setActivePanels] = useState([]);

    const onActive = (e) => {
        setActivePanels(e);
    };

    let adrConfig = window.config.branding === 'adr';
    return (
        <StyledAccordion {...{ onActive }}>
            {menuItems &&
                menuItems.map((menuItem, index) => {
                    const optionLength = menuItem.options && menuItem.options.length;
                    const options = menuItem.options && menuItem.options;
                    const panelIsActive = activePanels.includes(index);
                    console.log('STYLED ACCORDION PANEL', StyledAccordionPanel);
                    return (
                        <StyledAccordionPanel
                            {...(optionLength === 0 && {
                                id: `${accordionPanelidPrefix}-${index}`,
                                className: accordionPanelidPrefix,
                            })}
                            data-testid="search-menu-component"
                            // color={'black'}
                            noExpansion={optionLength === 0 ? true : false}
                            noArrows={props.noArrows}
                            label={
                                <Box>
                                    <Heading level="2" title={menuItem.description}>
                                        {menuItem.description}
                                    </Heading>
                                </Box>
                            }
                            key={`menu-item-${menuItem.description}`}
                            id={`${menuItem.description}-Panel`}
                            {...(panelIsActive
                                ? {
                                      'aria-controls': `${menuItem.description}-Section`,
                                  }
                                : {})}
                        >
                            {optionLength > 0 && panelIsActive && (
                                <StyledAccordionBox
                                    pad={{
                                        vertical: 'small',
                                        left: 'small',
                                        right: 'xsmall',
                                    }}
                                    // background={adrConfig ? 'skyBlue' : 'internalsBlue'}
                                    background={
                                        theme.global.colors[window.localStorage.getItem('branding')].primaryLight
                                    }
                                >
                                    <Text size="small">
                                        <Box
                                            fill
                                            pad="none"
                                            height={{ max: '250px' }}
                                            id={`${menuItem.description}-Section`}
                                            aria-labelledby={`${menuItem.description}-Panel`}
                                        >
                                            <CheckBoxGroup
                                                options={options}
                                                onChange={props.onCheckBoxClicked}
                                                // onScroll={props.onCheckBoxScroll}
                                            />
                                        </Box>
                                    </Text>
                                </StyledAccordionBox>
                            )}
                        </StyledAccordionPanel>
                    );
                })}
        </StyledAccordion>
    );
});

SearchMenuComponent.whyDidYouRender = true;

SearchMenuComponent.propTypes = {
    menuItems: PropTypes.array.isRequired,
    onCheckBoxClicked: PropTypes.func,
    onCheckBoxScroll: PropTypes.func,
    isControlled: PropTypes.bool,
    noArrows: PropTypes.bool,
    processClassListItemClicked: PropTypes.func,
};

export default SearchMenuComponent;
