import { Box, BoxProps, ResponsiveValue } from 'grommet';
import { PadType, RoundType } from 'grommet/utils';
import { DebouncedFunc } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import theme, { ThemeObjectType } from '../../../style/theme';

export const StyledListRowLine = styled(Box)`
    box-shadow: 0 8px 0 0.5px
        ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.dividerLine};
`;

export type HighlightRowOnFocusType = (theme: ThemeObjectType) => string;
interface StyledBoxInterface {
    highlightRowOnFocus?: HighlightRowOnFocusType;
}

const StyledBox = styled(Box)`
    ${(props: StyledBoxInterface) => props.highlightRowOnFocus && props.highlightRowOnFocus(theme)}
`;
interface UseListRowProps {
    onScroll?: () => void | DebouncedFunc<() => void>;
    size?: ResponsiveValue;
    renderProp?: any;
    setRowHeightCallback: (index: number, ref: any) => void;
    plain?: boolean;
    rowPadding?: PadType;
    rowStyle?: 'alternate-gaps' | 'blocks' | 'lines';
    rowBorderRadius?: RoundType;
    rowBorder?: object | undefined;
    listLength: number;
    highlightRowOnFocus?: HighlightRowOnFocusType;
    percentageMargin?: number;
}

const useListRow =
    ({
        renderProp,
        size,
        onScroll,
        setRowHeightCallback,
        plain = false,
        rowPadding,
        rowStyle,
        rowBorderRadius,
        rowBorder,
        listLength,
        highlightRowOnFocus,
        percentageMargin,
    }: UseListRowProps) =>
    ({ style, isScrolling, index }: any) => {
        onScroll && isScrolling === false && onScroll();

        return (
            <RowComponent
                plain={plain}
                style={style}
                index={index}
                size={size}
                renderProp={renderProp}
                setRowHeightCallback={setRowHeightCallback}
                rowPadding={rowPadding}
                rowStyle={rowStyle}
                rowBorderRadius={rowBorderRadius}
                rowBorder={rowBorder}
                listLength={listLength}
                highlightRowOnFocus={highlightRowOnFocus}
                percentageMargin={percentageMargin}
            />
        );
    };

export default useListRow;

interface RowComponentProps extends BoxProps {
    style?: any;
    index: number;
    size?: ResponsiveValue;
    renderProp?: any;
    setRowHeightCallback: (index: number, ref: any) => void;
    plain?: boolean;
    rowPadding?: PadType;
    rowStyle?: 'alternate-gaps' | 'blocks' | 'lines';
    rowBorderRadius?: RoundType;
    rowBorder?: object;
    listLength: number;
    highlightRowOnFocus?: HighlightRowOnFocusType;
    percentageMargin?: number;
}

const RowComponent = ({
    style,
    index,
    size,
    renderProp,
    setRowHeightCallback,
    plain,
    rowPadding,
    rowStyle,
    rowBorderRadius,
    rowBorder,
    listLength,
    highlightRowOnFocus,
    percentageMargin,
}: RowComponentProps) => {
    const ref = React.useRef() as React.RefObject<HTMLDivElement>;

    React.useEffect(() => {
        setRowHeightCallback(index, ref);
    }, [ref, index, setRowHeightCallback]);

    return (
        <div style={style}>
            <StyledBox
                ref={ref}
                key={`list-${index}`}
                width={percentageMargin ? `${100 - percentageMargin}%` : '100%'}
                direction="row"
                justify="between"
                flex={false}
                highlightRowOnFocus={highlightRowOnFocus}
                pad={
                    rowPadding
                        ? rowPadding
                        : plain
                        ? '0px'
                        : rowStyle === 'alternate-gaps' && index % 2 !== 0
                        ? '3px'
                        : size === 'small'
                        ? 'medium'
                        : 'small'
                }
                background={
                    plain
                        ? 'transparent'
                        : rowStyle === 'alternate-gaps'
                        ? index % 2 === 0
                            ? 'white'
                            : 'transparent'
                        : rowStyle === 'lines'
                        ? 'white'
                        : !rowBorder && index % 2 !== 0
                        ? 'lightestBlue'
                        : 'white'
                }
                {...(rowBorderRadius && { round: rowBorderRadius })}
                border={rowBorder}
            >
                <Box fill>
                    {renderProp({ index })}
                    {index !== listLength - 1 && rowStyle === 'lines' && (
                        <StyledListRowLine margin={{ top: '16px' }} width="100%" />
                    )}
                </Box>
            </StyledBox>
        </div>
    );
};
