import { Accordion, AccordionBox, AccordionPanel } from '../../../MDW/MDW';
import styled from 'styled-components';
import theme from '../../../../style/theme';
import Scrollbar from 'react-scrollbars-custom';

export const StyledAccordionPanel = styled(AccordionPanel)`
    height: 50px;
    h2 {
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
        line-height: 20px;
        color: ${theme.global.colors[window.localStorage.getItem('branding')].primaryText};
        ${'' /* color: ${theme.global[window.config?.branding].secondarySearch}; */};
        font-size: 12px;
    }
    ${(props) =>
        props.noExpansion &&
        `
        & > div:nth-of-type(2) {display: none;}        
        `}
    ${(props) =>
        props.noArrows &&
        `
        & > div:nth-of-type(2) {display: none;}
       
        `}

    text-transform: capitalize;
`;

export const StyledAccordion = styled(Accordion)`
    & > div:nth-last-of-type(1) > [aria-expanded='true'] ~ div > div > div {
        border-radius: ${theme.radii.large};
    }
    & > div > button:focus > div > div > svg {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness} ${theme.a11yBoxShadow.colour};
    }
    & > div > div {
        border: none;
    }
`;

export const StyledAccordionBox = styled(AccordionBox)``;

export const TagBoxContents = styled(StyledAccordionBox)`
     {
        ${'' /* background-color: ${theme.global.colors.skyBlue}; */}
    }
    ${(props) => props.isHidden === true && `{display: none;}`}
`;

export const StyledScrollbar = styled(Scrollbar)`
    & > div ~ div {
        transform: translateX(7px);
    }
    & > div ~ div > div {
        background: red;
    }
    &[style] > div ~ div > div {
        background: ${theme.global.colors[window.localStorage.getItem('branding')].secondary} !important;
        ${'' /* background: ${theme.global.colors.secondary} !important; */}
    }
`;
