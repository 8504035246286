import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, Tooltip } from '@material-ui/core';
import DOMPurify from 'dompurify';
import { CustomScroll } from '../../../MDW/MDW';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        fontSize: '14px',
    },
    customWidth: {
        maxWidth: 500,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
    list: {
        overflowY: 'auto',
        margin: 0,
        listStyle: 'none',
        width: '100%',
        padding: '2px',
        fontSize: '12px',
        letterSpacing: 'none',
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#d9364b',
            // outline: '1px solid slategrey',
            borderRadius: '6px',
        },
    },
    showMoreLess: {
        '&:hover': {
            cursor: 'pointer',
            color: 'grey',
        },
    },
}));
const IndividualDatasetPageHeaderDescription = ({ abstract, desc, bg, textColor, setExpanded, isExpanded }) => {
    const classes = useStyles();
    const [isReadMore, setIsReadMore] = React.useState(true);
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });
    let purifiedAbstract = DOMPurify.sanitize(desc, { USE_PROFILES: { html: true } });
    return (
        <div
            style={{ zIndex: '999', background: bg, color: textColor, width: '98%', boxShadow: 'none', margin: '10px' }}
        >
            <div className={classes.list}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: purifiedAbstract.slice(0, isReadMore ? 255 : purifiedAbstract.length),
                    }}
                ></div>
                &nbsp;
                <strong className={classes.showMoreLess} onClick={(e) => setIsReadMore(!isReadMore)}>
                    {isReadMore ? '...Read More' : 'Show Less'}
                </strong>
            </div>
        </div>
    );
};

export default IndividualDatasetPageHeaderDescription;
