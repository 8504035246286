import {
    RECEIVE_AUTHENTICATION,
    DELETE_AUTHENTICATION,
    RESET_APP,
    RECEIVE_PROFILE,
    RECEIVE_DATASETS,
    RECEIVE_FILTER_MENU_ITEMS,
    ADD_SELECTED_FILTER,
    REMOVE_SELECTED_FILTER,
    ADD_TO_ALL_FILTERS,
    REMOVE_FROM_ALL_FILTERS,
    CLEAR_ALL_SELECTED_FILTERS,
    RECEIVE_DATASET,
    UPDATE_BROWSER_CONTROLS,
    UPDATE_CURRENT_CLASS,
    SET_FILTERED_DATASETS,
    SET_S3_DATA,
    SET_BROWSER_ASSETS_DATA,
    SHOW_TREEVIEW_DATA_IN_DATASET,
    SORT_BY_SEARCH_ITEM,
    TRIGGER_FOR_SEARCH_ALL_DATASET_INPUT,
} from '../constants/constants';

export const receiveProfile = (profile) => ({
    type: RECEIVE_PROFILE,
    profile,
});

export const receiveDataSets = (dataSets) => ({
    type: RECEIVE_DATASETS,
    dataSets,
});

export const setFilteredDataSets = (filteredDataSets) => ({
    type: SET_FILTERED_DATASETS,
    filteredDataSets,
});

export const addSelectedFilter = (selectedFilter) => ({
    type: ADD_SELECTED_FILTER,
    selectedFilter,
});

export const removeSelectedFilter = (selectedFilter) => ({
    type: REMOVE_SELECTED_FILTER,
    selectedFilter,
});

export const clearAllSelectedFilters = () => ({
    type: CLEAR_ALL_SELECTED_FILTERS,
});

export const addToAllFilters = (filter) => ({
    type: ADD_TO_ALL_FILTERS,
    filter,
});

export const removeFromAllFilters = (filter) => ({
    type: REMOVE_FROM_ALL_FILTERS,
    filter,
});

export const receiveFilterMenuItems = (filterMenuItems) => ({
    type: RECEIVE_FILTER_MENU_ITEMS,
    filterMenuItems,
});

export const receiveDataSet = (dataSet) => ({
    type: RECEIVE_DATASET,
    dataSet,
});

export const updateBrowserControls = (browserControls) => ({
    type: UPDATE_BROWSER_CONTROLS,
    browserControls,
});

export const updateCurrentClass = (currentClass) => ({
    type: UPDATE_CURRENT_CLASS,
    currentClass,
});

export const receiveAuthentication = (isAuthenticated) => ({
    type: RECEIVE_AUTHENTICATION,
    isAuthenticated,
});

export const deleteAuthentication = () => ({
    type: DELETE_AUTHENTICATION,
});
export const setS3Data = (S3Data) => ({
    type: SET_S3_DATA,
    S3Data,
});
export const setBrowserAssetsData = (browserAssetsData) => ({
    type: SET_BROWSER_ASSETS_DATA,
    browserAssetsData,
});

export const showTreeviewDataInDataSet = (treeviewDataInDataSet) => {
    return {
        type: SHOW_TREEVIEW_DATA_IN_DATASET,
        treeviewDataInDataSet,
    };
};
export const setSortbyIntegratedItem = (sortbyIntegratedItem) => {
    return {
        type: SORT_BY_SEARCH_ITEM,
        sortbyIntegratedItem,
    };
};
export const triggerForSearchAllDatasetInput = (triggerForSearchAllDatasetInput) => {
    return {
        type: TRIGGER_FOR_SEARCH_ALL_DATASET_INPUT,
        triggerForSearchAllDatasetInput,
    };
};

export const resetApp = () => ({
    type: RESET_APP,
});
