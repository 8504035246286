import { Box, Text } from 'grommet';
import React from 'react';
import theme from '../../../../../../style/theme';
import { ToolTip } from '../../../../../MDW/MDW';
import { ElementPopUpContentBlock, StyledGridBox, StyledGridText } from '../DataElementPopup/DataElementPopupUtils';
import DOMPurify from 'dompurify';

const DataElementPopUpMetadataTab = ({ popUpItemRefs, ...props }) => {
    const { elementMetadata } = props;
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });
    return (
        <>
            <Box
                pad={{
                    bottom: 'none',
                }}
            >
                <ElementPopUpContentBlock title={''} isAtBottom={elementMetadata}>
                    {elementMetadata?.map(({ key, value }, index) => {
                        return (
                            <Box
                                key={key}
                                pad={{ top: 'small', left: 'medium' }}
                                data-testid={`metadata-row-id-${index}`}
                            >
                                <Box direction="row" pad={{ bottom: 'xsmall' }}>
                                    <StyledGridBox width="20%" data-tip={key}>
                                        <StyledGridText
                                            size="small"
                                            weight={theme.fontWeights.semibold}
                                            color={`${theme.global.colors.textLightGrey}`}
                                        >
                                            {`${key}: `}
                                        </StyledGridText>
                                    </StyledGridBox>
                                    <StyledGridBox width="77%" pad={{ left: '3%' }}>
                                        <Text wordBreak={'break-word'} size="small" weight={theme.fontWeights.semibold}>
                                            {/* {value} */}
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(value, { USE_PROFILES: { html: true } }),
                                                }}
                                            ></div>
                                        </Text>
                                    </StyledGridBox>
                                </Box>
                            </Box>
                        );
                    })}
                    <ToolTip />
                </ElementPopUpContentBlock>
            </Box>
        </>
    );
};

export default DataElementPopUpMetadataTab;
