import { Box, Heading, Markdown, Text } from 'grommet';
import { string } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../../../../style/theme';
import { Linkify } from '../../../../MDW/MDW';
import { camelCaseConverter, descriptiveMetadataCamelCaseConversionExceptionsMap } from './DescriptiveMetadataUtils';
import copyIcon from '../../../../../assets/images/copyIcon.svg';
import { max_vids_fetched } from '../../IndividualDatasetPageContents/IndividualDatasetPageContents';
import { LinkIcon } from '../../../../../assets/icons/MDW-icons';
export const StyledElementModalHeading = styled(Heading)`
    overflow-wrap: break-word;
`;

export const StyledElementModalSubHeading = styled(Text)`
    overflow-wrap: break-word;
`;

export const StyledSubTitleText = styled(Text)`
    text-transform: capitalize;
    ${(props) =>
        props.underline &&
        css`
            text-decoration: underline;
        `}
`;

export const StyledMetadataBox = styled(Box)`
    position: relative;
    display: table;
    width: 100%;
`;

const StyledDescriptionText = styled(Text)`
    white-space: pre-line;
    vertical-align: text-top;
    ${(props) => props.lineHeight && `line-height: ${props.lineHeight}`}
`;

const StyledMarkdown = styled(Markdown)`
    ${(props) => `font-size: ${theme.markdown[props.size]}`}
`;

const StyledLinkify = (props) => {
    return typeof props.value !== 'object' ? (
        <StyledDescriptionText size={props.large ? 'small' : 'xsmall'}>
            <Linkify>{props.value}</Linkify>
        </StyledDescriptionText>
    ) : null;
};

export const DescriptiveGenericSectionKeyValue = (props) => {
    const [copyIndigator, setIndigator] = React.useState(false);
    const title = props.convertTitleFromCamelCase
        ? camelCaseConverter(props.title, descriptiveMetadataCamelCaseConversionExceptionsMap)
        : props.title;

    const handleCopy = (containerid) => {
        // navigator.clipboard.writeText(value.replace(/<[^>]+>/g, ''));
        setIndigator(true);
        if (document.selection) {
            var range = document.body.createTextRange();
            range.moveToElementText(document.getElementById(containerid));
            range.select().createTextRange();
            document.execCommand('copy');
        } else if (window.getSelection) {
            var range = document.createRange();
            range.selectNode(document.getElementById(containerid));
            window.getSelection().removeAllRanges(range);
            window.getSelection().addRange(range);
            document.execCommand('copy');
        }
        setTimeout(() => {
            setIndigator(false);
        }, 1000);
    };

    const youtubeKeyword1 = 'youtube';
    const youtubeKeyword2 = 'youtu';

    const nonYoutubeUrlArray = React.useMemo(
        () =>
            Array.isArray(props.value) &&
            props.value.filter(
                (value) =>
                    !value || !value.includes || (!value.includes(youtubeKeyword1) && !value.includes(youtubeKeyword2)),
            ),
        [props.value],
    );
    const youtubeUrlArray = React.useMemo(
        () =>
            Array.isArray(props.value) &&
            props.value.filter(
                (value) =>
                    value && value.includes && (value.includes(youtubeKeyword1) || value.includes(youtubeKeyword2)),
            ),
        [props.value],
    );

    return (
        <Box
            direction={props.direction || 'row'}
            {...(props.value && {
                margin: { bottom: props.direction === 'column' ? 'medium' : 'xsmall' },
            })}
        >
            {props.value && (
                <React.Fragment>
                    <Box
                        basis={'18%'}
                        margin={{
                            bottom: '5px',
                        }}
                    >
                        <StyledSubTitleText size={props.large ? 'small' : 'xsmall'} weight={'bold'}>
                            {`${title}: `}
                        </StyledSubTitleText>
                    </Box>
                    <Box basis={'82%'}>
                        {Array.isArray(props.value) && props.showArrayAsList ? (
                            props.value.map((value, k) => (
                                <StyledLinkify key={`${value}-${k}`} large={props.large} value={value} />
                            ))
                        ) : Array.isArray(props.value) ? ( // but NOT props.showArrayAsList
                            props.title === 'accessService' && props.value.length > 1 ? (
                                <StyledDescriptionText size={props.large ? 'small' : 'xsmall'}>
                                    <ul>
                                        {props.value.map((value, k) => (
                                            <li>
                                                <StyledLinkify large={props.large} value={value} />
                                            </li>
                                        ))}
                                    </ul>
                                </StyledDescriptionText>
                            ) : (
                                <Box>
                                    <StyledLinkify large={props.large} value={nonYoutubeUrlArray.join(', ')} />
                                    <YoutubeItems youtubeUrlArray={youtubeUrlArray} />
                                </Box>
                            )
                        ) : // props.value is not an array but a string
                        typeof props.value === 'string' && hasMarkdownTag(props.value) ? (
                            <StyledMarkdown size={props.large ? 'small' : 'xsmall'}>
                                {removeMarkdownTags(props.value)}
                            </StyledMarkdown>
                        ) : typeof props.value === 'string' && isHtml(props.value) ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '96%',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    id="containerid"
                                    style={{ fontSize: '12px', lineHeight: '18px', margin: '0px', width: '100%' }}
                                    dangerouslySetInnerHTML={{ __html: props.value }}
                                ></div>
                                {props.title == 'citation' && (
                                    <div
                                        style={{
                                            width: '30px',
                                            height: '30px',
                                            marginTop: '-4px',
                                            cursor: 'pointer',
                                            borderRadius: '100%',
                                            border: copyIndigator ? '2px solid red' : '',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => handleCopy('containerid')}
                                    >
                                        <img src={copyIcon} alt="copyIcon" />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <StyledLinkify large={props.large} value={props.value} />
                            </div>
                        )}
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
};

const YoutubeItems = (props) => {
    console.log(props);
    const [youtubeItems, setYoutubeItems] = React.useState([]);
    const getYoutubeItem = (youtubeLink) =>
        fetch(`https://youtube.com/oembed?url=${youtubeLink}&format=json`, {
            method: 'get',
        })
            .then((resp) => resp.json())
            .then((data) => {
                if (data) {
                    setYoutubeItems((items) => [...items, { ...data, youtubeLink }]);
                } else {
                    console.log('no data');
                }
            })
            .catch((err) => {
                console.log('err', err);
            });
    React.useEffect(() => {
        for (let i = 0; i < props?.youtubeUrlArray.length; i++) {
            !window.localStorage.getItem(max_vids_fetched) && getYoutubeItem(props?.youtubeUrlArray[i]);
            if (i === props?.youtubeUrlArray.length - 1) {
                window.localStorage.setItem(max_vids_fetched, true);
            }
        }
        return () => {
            window.localStorage.removeItem(max_vids_fetched);
        };
    }, [props?.youtubeUrlArray]);

    React.useEffect(() => {
        setYoutubeItems((items) => items);
    }, [youtubeItems]);
    return (
        <Box direction="row" wrap margin={{ top: 'medium' }}>
            {youtubeItems?.map((item, l) => {
                console.log(item);
                return (
                    <Box key={`youvid-${l}`} width={'300px'} margin={{ vertical: 'small', right: 'medium' }}>
                        <div
                            style={{
                                marginBottom: '10px',
                            }}
                        >
                            <a
                                style={{
                                    fontWeight: 'bolder',
                                    color: 'black',
                                    fontSize: '15px',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                }}
                                href={item.youtubeLink}
                                target="_blank"
                            >
                                {item.title}
                                <div style={{ width: '15px' }}>
                                    <img src={LinkIcon} alt="link" />
                                </div>
                            </a>
                        </div>
                        <iframe
                            width="300"
                            src={`https://www.youtube.com/embed/${
                                item?.thumbnail_url?.split('/')?.reverse()?.[1]
                            }?feature=oembed`}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                            title={item.title}
                        ></iframe>
                    </Box>
                );
            })}
        </Box>
    );
};

const hasMarkdownTag = (string) => {
    return string.includes('<md>');
};
const hasParagraphTag = (string) => {
    return string.includes('</p>');
};

const isHtml = (string) => {
    return /<\/?[a-z][\s\S]*>/i.test(string);
};

const removeMarkdownTags = (string) => {
    return string.replace('<md>', '').replace('</md>', '');
};

export const ArrayListDetail = (props) => (
    <Box
        direction={props.direction ? props.direction : 'row'}
        margin={{ bottom: 'small' }}
        {...(props.items && { margin: { bottom: 'small' } })}
        key={props.key}
    >
        {props.items && (
            <React.Fragment>
                <Box
                    basis={'18%'}
                    margin={{
                        bottom: 'xsmall',
                    }}
                >
                    <StyledSubTitleText size={'xsmall'} weight={'bold'}>
                        {props.title}
                    </StyledSubTitleText>
                </Box>
                <Box basis={'82%'}>
                    {props.items &&
                        props.items.map((item, index) => (
                            <StyledDescriptionText size={'xsmall'} key={`descriptive-item: ${index}`}>
                                <Linkify>{item}</Linkify>
                            </StyledDescriptionText>
                        ))}
                </Box>
            </React.Fragment>
        )}
    </Box>
);
