import DOMPurify from 'dompurify';
import { Box } from 'grommet';
import React, { useMemo, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import theme from '../../../../../../style/theme';
import DataElementPopUpDescriptionTab from '../DataElementPopUpTabContents/DataElementPopUpDescriptionTab';
import DataElementPopUpMetadataTab from '../DataElementPopUpTabContents/DataElementPopUpMetadataTab';
import DataElementPopUpProfilingTab from '../DataElementPopUpTabContents/DataElementPopUpProfilingTab';
import DataElementPopUpRelationshipTag from '../DataElementPopUpTabContents/DataElementPopUpRelationshipTag';
import DataElementpopUpDescriptionNewTab from '../DataElementPopUpTabContents/DataElementpopUpDescriptionNewTab';
import DataElementpopUpContact from '../DataElementPopUpTabContents/DataElementPopupContact';
import { ElementPopUpTitleBlock, truncateLength } from './DataElementPopupUtils';
import { getDataElementDetail } from '../../../../../../api/getDataElementDetail';
import { Link, useLocation, useHistory } from 'react-router-dom';

export const isGivenRefsAllEmpty = (refIndexArray, popUpItemRefs) => {
    const isAllEmpty = refIndexArray.reduce((acc, cv) => {
        return popUpItemRefs[cv].current !== null ? false : acc;
    }, true);
    return isAllEmpty;
};

const DataElementPopupContents = ({ popUpItemRefs, isHeader, publisher, ...props }) => {
    const location = useLocation();
    const history = useHistory();
    const [rel, setRel] = useState();
    const { element } = props;
    const enums = rel?.dataType?.enumerations;
    const processedProfilingData = useMemo(() => htmlTableToJSObject(rel?.profilingData), [rel?.profilingData]);
    const [isDataTypeBlockAtBottom, setIsDataTypeBlockAtBottom] = React.useState(false);
    const [isRuleBlockAtBottom, setIsRuleBlockAtBottom] = React.useState(false);
    const [isMeasurementUnitBlockAtBottom, setIsMeasurementUnitBlockAtBottom] = React.useState(false);
    const [isReferenceBlockAtBottom, setisReferenceBlockAtBottom] = React.useState(false);
    const popUpTabNames = [
        ...(rel?.description || element ? ['Details'] : []),
        ...(rel?.elementMetadata ? ['Metadata'] : []),
        ...(rel?.profilingData ? ['Profiling'] : []),
        ...(rel?.relationships ? ['Relationships'] : []),
        // ...(rel?.contact ? ['Contact'] : []),
    ];
    let currentLocation = location.pathname + location.search;
    let originId = new URLSearchParams(location.search).get('origin');

    const [currentTab, setCurrentTab] = React.useState('Details');
    const displayDescription = rel?.description?.slice(0, truncateLength);
    let currentLocationElement = currentLocation.split('&');
    let elementRoute =
        currentLocationElement.length >= 2 ? currentLocationElement.slice(0, 3).join('&') : currentLocation;
    elementRoute += `&dataelement=${element.id}`;

    React.useEffect(() => {
        if (isHeader) {
            setRel({
                contact: {
                    name: publisher?.name,
                    identifier: publisher?.identifier,
                    description: publisher?.description,
                    contactPoint: publisher?.contactPoint,
                    logo: publisher?.logo,
                },
            });
        } else {
            getDataElementDetail(element.id, originId).then((data) => {
                setRel(data);
                history.push(elementRoute);
            });
        }
    }, [element.id]);
    React.useEffect(() => {
        if (rel?.dataType) {
            setIsDataTypeBlockAtBottom(isGivenRefsAllEmpty([1, 2, 3, 4], popUpItemRefs));
            rel?.dataType.rule && setIsRuleBlockAtBottom(isGivenRefsAllEmpty([2, 3, 4], popUpItemRefs));
            rel?.dataType.measurementUnit &&
                setIsMeasurementUnitBlockAtBottom(isGivenRefsAllEmpty([3, 4], popUpItemRefs));
            rel?.dataType.reference && setisReferenceBlockAtBottom(isGivenRefsAllEmpty([4], popUpItemRefs));
        }
    }, [rel?.dataType]);
    return (
        <Box
            margin={{ top: '96px', bottom: 'large' }}
            width={{
                min: 'large',
            }}
            background="transparent"
            elevation={'none'}
            round={theme.radii.xlarge}
        >
            <ElementPopUpTitleBlock
                pingState={props.pingState}
                ping={props.ping}
                containerRef={popUpItemRefs[0]}
                element={rel}
                dataType={rel?.dataType}
                enums={enums}
                metadata={rel?.elementMetadata}
                profilingData={rel?.profilingData}
                setIsModalShown={props.setIsModalShown}
                PopUpElementTabNames={popUpTabNames}
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
            ></ElementPopUpTitleBlock>
            <Scrollbars
                style={{
                    height: props.height && !isNaN(props.height) ? (props.height < 601 ? props.height : 600) : 400,
                    width: '100%',
                    borderBottomRightRadius: theme.radii.xlarge,
                    borderBottomLeftRadius: theme.radii.xlarge,
                }}
                hideTracksWhenNotNeeded
                renderTrackHorizontal={(props) => (
                    <div {...props} style={{ display: 'none' }} className="track-horizontal" />
                )}
                renderThumbVertical={(props) => (
                    <div
                        {...props}
                        style={{ backgroundColor: theme.global.colors.secondary }}
                        className={`thumb-vertical-${window.localStorage.getItem('branding')}`}
                    />
                )}
                renderTrackVertical={(props) => (
                    <div
                        {...props}
                        style={{
                            bottom: '10px',
                            top: '10px',
                            position: 'absolute',
                            width: '6px',
                            right: '5px',
                            borderRadius: '3px',
                            backgroundColor: theme.global.colors.lightGrey,
                        }}
                    />
                )}
            >
                <div style={{ height: '100%', background: 'white' }}>
                    {currentTab === 'Details' && rel && (
                        <DataElementpopUpDescriptionNewTab description={rel?.description} dataType={element.dataType} />
                    )}
                    {/* {currentTab === 'Description' && (
                    <DataElementPopUpDescriptionTab
                        {...{
                            popUpItemRefs,
                            dataType,
                            rel,
                            enums,
                            isDataTypeBlockAtBottom,
                            isRuleBlockAtBottom,
                            isMeasurementUnitBlockAtBottom,
                            isReferenceBlockAtBottom,
                            displayDescription,
                        }}
                    />
                )} */}
                    {currentTab === 'Metadata' && rel?.elementMetadata && (
                        <DataElementPopUpMetadataTab
                            {...{
                                popUpItemRefs,
                            }}
                            elementMetadata={rel?.elementMetadata}
                        />
                    )}
                    {isHeader && rel?.contact && (
                        <DataElementpopUpContact
                            {...{
                                popUpItemRefs,
                            }}
                            contact={rel?.contact}
                        />
                    )}
                    {currentTab === 'Profiling' && rel?.profilingData && (
                        <DataElementPopUpProfilingTab
                            {...{
                                popUpItemRefs,
                            }}
                            profilingData={processedProfilingData}
                        />
                    )}
                    {currentTab === 'Relationships' && rel?.relationships && (
                        <DataElementPopUpRelationshipTag
                            {...{
                                popUpItemRefs,
                            }}
                            relationships={rel?.relationships}
                        />
                    )}
                </div>
            </Scrollbars>
        </Box>
    );
};

export default DataElementPopupContents;

export const htmlTableToJSObject = (profilingData) => {
    var parser = new DOMParser();
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });
    var doc = parser.parseFromString(DOMPurify.sanitize(profilingData), 'text/html');

    var tableHeaders = [].map.call(doc.querySelectorAll('tr'), (tr) => {
        return [].slice.call(tr.querySelectorAll('th')).reduce((a, b, i) => {
            a[i] = b.textContent;
            return a;
        }, []);
    });
    var tableData = [].map.call(doc.querySelectorAll('tr'), (tr) => {
        return [].slice.call(tr.querySelectorAll('td')).reduce((a, b, i) => {
            a[i] = b.textContent;
            return a;
        }, []);
    });

    var data = tableHeaders.map((key, index) => {
        return { key, value: tableData[index] };
    });
    return data;
};
