import { Box } from 'grommet';
import styled from 'styled-components';

export const StyledContentScrollBox = styled(Box)`
    box-sizing: content-box;
    overflow-x: unset;
    overflow-y: unset;
    overflow: hidden;
`;

export const checkOverflow = (el) => {
    var curOverflow = el.style.overflow;

    if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

    var isOverflowing =
        (el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight) &&
        (el.scrollHeight - el.clientHeight > 2 || el.scrollWidth - el.clientWidth > 2);

    el.style.overflow = curOverflow;

    return isOverflowing;
};

export const buttonScroll = (direction, id) => () => {
    const step = 1;
    const element = document.getElementById(id);
    const scrollFunctions = {
        down: () => {
            element.scrollTop += step;
        },
        up: () => {
            element.scrollTop -= step;
        },
        left: () => {
            element.scrollLeft -= step;
        },
        right: () => {
            element.scrollLeft += step;
        },
    };
    if (!scrollFunctions[direction]) throw Error('argument must be a string either: "up", "down", "left", or "right"');

    element && scrollFunctions[direction]();
};
