import { Box, ResponsiveContext, Text } from 'grommet';
import React from 'react';
import useIsReduxStateReady from '../../../hooks/useIsReduxStateReady/useIsReduxStateReady';
import { isTesting } from '../../../utils/testingUtils';
import { Loader, SideBarContent, ToolTip } from '../../MDW/MDW';
import {
    onCheckBoxClicked,
    onClearAllClicked,
    onSelectedFilterTagClicked,
} from '../../pages/BrokerSearchPage/BrokerSearchPageContents/BrokerSearchPageContents';
import SearchMenuComponent from '../../pages/BrokerSearchPage/BrokerSearchPageContents/SearchMenuComponent';
import { StyledScrollbar } from '../../pages/BrokerSearchPage/BrokerSearchPageContents/StyledComponents';
import TagBox from '../../pages/BrokerSearchPage/BrokerSearchPageContents/TagBox';
import { getClassElements } from '../../../api/getClassElements';
import { classItemIdPrefix } from '../../pages/IndividualDatasetPage/IndividualDatasetPageContents/IndividualDatasetPageContents';
import store from '../../../redux/store';
import { updateCurrentClass } from '../../../redux/actions/actions';
import { DataClassList } from '../../pages/BrokerSearchPage/BrokerSearchPageContents/SearchMenuComponentUtils';
import TreeList from './TreeList';
import ReactTooltip from 'react-tooltip';
import { isItemPopulated } from '../../../utils/javascriptUtilities';
import styled from 'styled-components';
import {
    sidebarTitles,
    useSideBarAndDataDownloadContext,
} from '../../templates/GenericPageTemplate/GenericPageTemplate';
import usePrevious from '../../../hooks/usePreviousState';
import { useState, useEffect, useRef } from 'react';
// tree data and imports
import { UncontrolledTreeEnvironment, Tree, StaticTreeDataProvider } from 'react-complex-tree';
import 'react-complex-tree/lib/style.css';
import { showTreeviewDataInDataSet } from '../../../redux/actions/actions';
import theme from '../../../style/theme';

const hideToolTip = () => {
    ReactTooltip.hide();
};

const LoaderWrapper = ({ className, ...props }) => {
    return (
        <Box className={className}>
            <Loader {...props} />
        </Box>
    );
};
const StyledDownloadButtonForExcel = styled.div`
    width: 65%;
    margin-right: 43px;
    border: 1px solid #d9364b;
    border-radius: 12px;
    background-color: ${theme.global.colors[window.localStorage.getItem('branding') || 'default']?.primary};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    & a {
        margin: 9px;
        text-decoration: none;
        color: white;
    }

    &:hover {
        background-color: green;
    }
`;
const StyledAppSidebar = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 190px;
    max-width: 500px;
    display: flex;
    background: ${theme.global.colors[window.localStorage.getItem('branding') || 'default']?.navbarBackgroundColor};
    border-right: 1px solid rgba(22, 33, 66, 0.8);
    flex-direction: row;
    box-shadow: -8px 2px 22px -7px rgba(0, 0, 0, 0.25);
    z-index: 2;
    //border-left: 1px solid rgba(22, 33, 66, 0.8);
`;
const StyledAppSideBarContent = styled.div`
    flex: 1;
`;
const StyledAppSidebarResizer = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 6px;
    justify-self: flex-end;
    cursor: col-resize;
    resize: horizontal;
    &:hover {
        width: 3px;
        background: #c1c3c5b4;
    }
`;
const StyledAppSidebarContainer = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    max-height: 100%;
`;

const StyledDownloadButtonWrapper = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const DownloadButtonForExcel = () => {
    const { data } = store.getState().dataSet;
    return (
        <StyledDownloadButtonWrapper>
            {data && (
                <StyledDownloadButtonForExcel>
                    <a
                        href={data?.location + data?.name + '?response-content-disposition=attachment'}
                        target="_blank"
                        download={data?.name}
                    >
                        Download
                    </a>
                </StyledDownloadButtonForExcel>
            )}
        </StyledDownloadButtonWrapper>
    );
};
const StyledSidebarLoader = styled(LoaderWrapper)`
    .spinner-box {
        width: unset;
        height: unset;
    }
    .pulse-bubble {
        background-color: #bfcbed73;
    }
`;

/**
 * The value of the "sidebarTitle" is actually used internally in the code for some logic,
 * so if we want to display something different to the user this is the best way to do it
 * @param sidebarTitle
 * @returns {string|*}
 */
const titleToActuallyShownTitle = (sidebarTitle, showSidebarGlossary) => {
    if (sidebarTitle === sidebarTitles.browserSearch) {
        return '';
    } else if (showSidebarGlossary) {
        return 'Terms';
    } else if (sidebarTitle === sidebarTitles.structuralMetadata) {
        return 'Data Classes';
    } else {
        return sidebarTitle;
    }
};

const SideBar = React.memo((props) => {
    const size = React.useContext(ResponsiveContext);
    const styleRef = React.useRef({ width: '100%', height: '100%' });
    const actuallyShownTitle = titleToActuallyShownTitle(props.title, props.showSidebarGlossary);
    // resizable
    const sidebarRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(90);

    const startResizing = React.useCallback((mouseDownEvent) => {
        setIsResizing(true);
    }, []);
    const stopResizing = React.useCallback(() => {
        setIsResizing(false);
    }, []);

    const resize = React.useCallback(
        (mouseMoveEvent) => {
            if (isResizing) {
                setSidebarWidth(mouseMoveEvent.clientX - sidebarRef.current.getBoundingClientRect().left);
            }
        },
        [isResizing],
    );

    React.useEffect(() => {
        window.addEventListener('mousemove', resize);
        window.addEventListener('mouseup', stopResizing);
        return () => {
            window.removeEventListener('mousemove', resize);
            window.removeEventListener('mouseup', stopResizing);
        };
    }, [resize, stopResizing]);
    // resizable
    return props.showSidebar ? (
        <StyledAppSidebarContainer>
            <StyledAppSidebar
                ref={sidebarRef}
                style={{
                    width: sidebarWidth,
                    // background: theme.global.colors[window.localStorage.getItem('branding') || 'default'].primary,
                }}
                onMouseDown={(e) => e.preventDefault()}
            >
                <StyledAppSideBarContent>
                    <Box fill justify="between">
                        <Box fill>
                            <SidebarTitle actuallyShownTitle={actuallyShownTitle} styleRef={sidebarRef} size={size} />
                            {size !== 'small' && (
                                <StyledScrollbar style={sidebarRef?.current}>
                                    {props.title === sidebarTitles.browserSearch ? (
                                        <BrowserSearchSidebar />
                                    ) : (
                                        // <div></div>
                                        <StructuralMetadataSidebar
                                            showSidebar={props.showSidebar}
                                            openFirstClass={props.openFirstClass}
                                        />
                                    )}
                                </StyledScrollbar>
                            )}
                        </Box>
                    </Box>
                </StyledAppSideBarContent>
                <StyledAppSidebarResizer onMouseDown={startResizing} />
            </StyledAppSidebar>
        </StyledAppSidebarContainer>
    ) : (
        props.showSidebarGlossary && (
            <StyledAppSidebarContainer>
                <StyledAppSidebar
                    ref={sidebarRef}
                    style={{ width: sidebarWidth, background: '#223052' }}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <StyledAppSideBarContent>
                        <Box fill justify="between">
                            <Box fill>
                                <SidebarTitle
                                    actuallyShownTitle={actuallyShownTitle}
                                    styleRef={sidebarRef}
                                    size={size}
                                />
                                {size !== 'small' && (
                                    <StyledScrollbar style={sidebarRef?.current}>
                                        <StructuralMetadataSidebar
                                            showSidebar={props.showSidebar}
                                            openFirstClass={props.openFirstClass}
                                        />
                                    </StyledScrollbar>
                                )}
                            </Box>
                        </Box>
                    </StyledAppSideBarContent>
                    <StyledAppSidebarResizer onMouseDown={startResizing} />
                </StyledAppSidebar>
            </StyledAppSidebarContainer>
        )
    );
});

const SidebarTitle = ({ actuallyShownTitle, styleRef, size }) => {
    let adrConfig = window.config?.adr;
    return actuallyShownTitle ? (
        <Box
            width={styleRef?.current?.width}
            height="auto"
            margin={{
                top: 'medium',
                bottom: 'large',
            }}
            pad={{
                left: size === 'small' ? 'large' : 'medium',
            }}
        >
            <Text size="large" weight={500} color={adrConfig ? 'black' : ''}>
                {actuallyShownTitle}
            </Text>
        </Box>
    ) : (
        <Box
            width={styleRef?.current?.width}
            height="auto"
            margin={{
                top: 'medium',
            }}
            pad={{
                left: size === 'small' ? 'large' : 'medium',
            }}
        />
    );
};

const BrowserSearchSidebar = () => {
    const { data: filterMenuItems } = useIsReduxStateReady('filterMenuItems');
    const { data: dataSets } = useIsReduxStateReady('dataSets');
    let secondPart = [];
    let firstPart = [];
    filterMenuItems.filter((item) => {
        if (['themes', 'partner'].includes(item.name)) {
            firstPart.push(item);
        } else {
            secondPart.push(item);
        }
    });
    let sortedFiltermenuItems = [...firstPart, ...secondPart];
    return (
        <Box pad={{ right: '2px' }} gap="small">
            <TagBox onFilterTagClick={onSelectedFilterTagClicked} onClearAllClicked={onClearAllClicked} />
            {sortedFiltermenuItems && isItemPopulated(sortedFiltermenuItems) ? (
                <React.Fragment>
                    <SearchMenuComponent
                        menuItems={sortedFiltermenuItems}
                        onCheckBoxClicked={onCheckBoxClicked}
                        onCheckBoxScroll={hideToolTip}
                    />
                    {!isTesting && <ToolTip />}
                </React.Fragment>
            ) : dataSets && dataSets.totalCount === 0 && !dataSets.filters ? (
                <Box pad={{ left: 'medium' }}>
                    <Text size="small" color={window.localStorage.getItem('branding').primaryText}>
                        No menu items available
                    </Text>
                </Box>
            ) : (
                <Box fill pad={{ top: '30%' }}>
                    <StyledSidebarLoader />
                </Box>
            )}
        </Box>
    );
};

const StructuralMetadataSidebar = (props) => {
    const { data: dataSet } = useIsReduxStateReady('dataSet');
    const options = dataSet && dataSet.structuralMetadata;
    const listRef = React.useRef();
    const listItemContainerRef = React.useRef();
    const { setShowDataDownloadBrowser } = useSideBarAndDataDownloadContext();
    // const processClassListItemClicked = (listItemRef, listItemContainerRef, id) => (e) => {
    //     if (listItemRef && listItemRef.current && listItemContainerRef && listItemContainerRef.current) {
    //         listItemRef.current.parentElement.focus();
    //         ToolTip.rebuild();
    //         console.log('a');
    //         return getClassElements(id.replace(classItemIdPrefix, ''))
    //             .then((response) => response.json())
    //             .then((response) => {
    //                 console.log('a');
    //                 if (response?.dataElements) {
    //                     setShowDataDownloadBrowser(false);
    //                     store.dispatch(updateCurrentClass(response));
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error(error);
    //             });
    //     }
    // };
    // TODO: https://develop.metadatadev.co.uk/browser/dataset?id=312
    const previousShowSidebar = usePrevious(props.showSidebar);
    const sidebarPreviouslyNotShownButNowShown = !previousShowSidebar && props.showSidebar;
    const { data: currentClass } = useIsReduxStateReady('currentClass');
    let nodes = options && options[0];
    store.dispatch(showTreeviewDataInDataSet({ nodes, showTreeView: true }));
    let sortedOptions = options?.sort(function (a, b) {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    return sortedOptions && sortedOptions.length > 0
        ? sortedOptions.map((data, index) => (
              <Box pad={{ right: '2px' }}>
                  <TreeList
                      openFirstClass={props.openFirstClass}
                      firstCallOptionIndex={index}
                      id={data.id}
                      childClasses={data.childClasses}
                      name={data.name}
                      description={data?.description}
                      key={data.id}
                  />
              </Box>
          ))
        : null;
};

SideBar.whyDidYouRender = true;

export default SideBar;
