import { Button as GrommetButton, ButtonProps } from 'grommet';
import { ReactNode, useRef } from 'react';
import styled from 'styled-components';
import theme from '../../../style/theme';

interface MDWButtonProps extends ButtonProps {
    small?: string;
    medium?: string;
    large?: string;
    width?: string;
    onClick: (e: any) => any;
    delayBetweenClicks?: number;
    repeatClicksOnHold?: boolean;
    heldOnHover?: boolean;
    children?: ReactNode;
    tabIndex?: number;
}

const StyledButton = styled(GrommetButton)`
    ${(props: MDWButtonProps) =>
        !props.plain &&
        `
   font-size: ${theme.fontSizes[1]};
   color: ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.white};
   padding: 0.7rem 2.8rem;
   border: none;
   border: none;
   border-radius: ${theme.radii.small};
   background-color: ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.primary};
   &: hover { 
       background: ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.primaryHover};
       box-shadow: 0px 0px 4px 2px #00000029; 
    };
    &: focus { 
        background: ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.primaryHover};
        box-shadow: 0px 0px 4px 2px #00000029; 
    };
  `}
    ${(props: MDWButtonProps) =>
        props.secondary &&
        `
   background-color: ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.secondary};
   &: hover { 
    background: ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.secondaryHover};
    };
   &: focus { 
    background: ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.secondaryHover};
    };
  `}
   ${(props: MDWButtonProps) =>
        props.small &&
        `
   font-size: ${theme.fontSizes[0]};
   padding: 0.6rem 2.4rem;
  `}
   ${(props: MDWButtonProps) =>
        props.medium &&
        `
   font-size: ${theme.fontSizes[3]};
   padding: 0.7rem 1.4rem;
  `}
   ${(props: MDWButtonProps) =>
        props.large &&
        `
   font-size: ${theme.fontSizes[5]};
   padding: 0.8rem 1.5rem;
  `}
   ${(props: MDWButtonProps) =>
        props.width &&
        `
    width: ${props.width}
  `}
`;

const Button = (props: MDWButtonProps) => {
    const timeout = useRef<any>();

    const start = (e: any) => {
        props.onClick && props.onClick(e);
        timeout.current = setTimeout(start, props.delayBetweenClicks || 10);
    };

    const end = () => {
        clearTimeout(timeout.current);
    };

    return (
        <StyledButton
            {...(props.repeatClicksOnHold && {
                ...(props.heldOnHover
                    ? {
                          onMouseEnter: start,
                          onMouseLeave: end,
                          onTouchStart: start,
                          onTouchEnd: end,
                          onDrag: end,
                          onDragEnd: end,
                      }
                    : {
                          onMouseDown: start,
                          onMouseUp: end,
                          onTouchStart: start,
                          onTouchEnd: end,
                          onMouseEnter: start,
                          onMouseLeave: end,
                          onDrag: end,
                          onDragEnd: end,
                      }),
            })}
            className="mdw-button"
            tabIndex={props.tabIndex}
            {...props}
        />
    );
};

export default Button;
