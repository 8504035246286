import { Box, Text } from 'grommet';
import React from 'react';
import { useId } from 'react-id-generator';
import styled from 'styled-components';
import theme from '../../../style/theme';

const StyledCheckboxBox = styled(Box)`
    width: 16px;
    height: 16px;
    border: solid 2px
        ${window.config.branding === 'adr' ? theme.global!.colors!.dark : theme.global!.colors!.lightPastelBlue};
    input:checked ~ & {
        border: solid 2px ${theme.global!.colors[window.localStorage.getItem('branding') || 'default'].secondary};
    }
    justify-content: center;
    border-radius: ${theme.radii.small};
    align-items: center;
`;

const StyledLabel = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 6px;
    color: ${theme.global!.colors!.lightPastelBlue};
}
`;

const StyledInput = styled.input`
    opacity: 0;
    -moz-appearance: none;
    width: 0;
    height: 0;
    margin: 0;
    cursor: pointer;
`;
const StyledSvg = styled.svg`
    display: none;
    input:checked ~ div & {
        display: flex;
    }
    stroke: ${window.config.branding === 'adr' ? theme.global!.colors!.dark : theme.global!.colors!.lightPastelBlue};
    width: 20px;
    height: 20px;
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    stroke-width: 4px;
`;

const StyledLabelText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface CheckboxPropTypes {
    label: string;
    labelWidth?: string | any;
    inputRef?: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null;
    id?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const Checkbox = ({ label, ...props }: CheckboxPropTypes) => {
    const [checkboxId] = useId();
    return (
        <StyledLabel htmlFor={props.id || checkboxId}>
            <Box margin={{ right: '9px' }}>
                <StyledInput
                    ref={props.inputRef}
                    type="checkbox"
                    id={props.id || checkboxId}
                    onChange={props.onChange}
                />
                <StyledCheckboxBox>
                    <StyledSvg viewBox="0 0 24 24">
                        <path
                            stroke={
                                theme.global.colors[window.localStorage.getItem('branding') || 'default'].checkBoxStroke
                            }
                            fill=""
                            d="M6,11.3 L10.3,16 L18,6.2"
                        ></path>
                        {/* <path fill="none" d="M6,11.3 L10.3,16 L18,6.2"></path> */}
                    </StyledSvg>
                </StyledCheckboxBox>
            </Box>
            <Box width={props.labelWidth}>
                <StyledLabelText
                    color={theme.global.colors[window.localStorage.getItem('branding') || 'default'].primaryText}
                    size="xsmall"
                >
                    {label}
                </StyledLabelText>
            </Box>
        </StyledLabel>
    );
};

export default Checkbox;
