import React from 'react';
import { Box } from 'grommet';
import { Checkbox, List } from '../../MDW/MDW';
import { setCheckAllSelectedCheckboxes } from '../../pages/BrokerSearchPage/BrokerSearchPageContents/BrokerSearchPageContentsUtils';
import styled from 'styled-components';
// import ReactTooltip from 'react-tooltip';
import theme from '../../../style/theme';
import { MDWListProps, ListRef } from '../../organisms/List/List';
import { Tooltip, withStyles } from '@material-ui/core';

interface CheckBoxListWrapperProps extends MDWListProps {
    className?: string;
}
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        // backgroundColor:
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}))(Tooltip);

const CheckBoxListWrapper = ({ className, children, listLength, ...props }: CheckBoxListWrapperProps) => {
    return (
        <div className={className}>
            <List children={children} listLength={listLength} {...props} />
        </div>
    );
};

const StyledCheckBoxList = styled(CheckBoxListWrapper)`
    .track-vertical {
        background-color: transparent;
    }
`;

const StyledBox = styled(Box)`
    &:focus-within > label > div > div {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

interface ExtrapolatedIndex {
    index: number;
}
interface Option {
    label: string;
    ref?: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null;
}
interface CheckBoxGroupInterface {
    options?: Array<Option>;
    onScroll?: () => void;
    onChange?: (e: Option) => React.ChangeEventHandler<HTMLInputElement>;
}

const CheckBoxGroup = ({ options, ...props }: CheckBoxGroupInterface) => {
    const listRef = React.useRef() as ListRef;

    return (
        <React.Fragment>
            {options ? (
                <StyledCheckBoxList
                    // onScroll={props.onScroll}
                    listLength={options && options.length}
                    heightNumber={options.length > 9 ? 250 : options.length * 25 + 25}
                    listRef={listRef}
                    rowHeight={26}
                    plain
                >
                    {({ index }: ExtrapolatedIndex) => {
                        setCheckAllSelectedCheckboxes(options[index]);
                        return (
                            <LightTooltip title={options[index].label} style={{ fontSize: '10px' }} placement="right">
                                <StyledBox
                                    data-tip={options && options[index].label}
                                    key={options && options[index].label}
                                    direction="row"
                                    pad="2px"
                                    height={{ min: '26px' }}
                                >
                                    <Checkbox
                                        inputRef={options?.[index].ref}
                                        labelWidth={{ min: '170px' }}
                                        label={options && options[index].label}
                                        onChange={props.onChange?.(options?.[index])}
                                    />
                                </StyledBox>
                            </LightTooltip>
                        );
                    }}
                </StyledCheckBoxList>
            ) : (
                <div></div>
            )}
        </React.Fragment>
    );
};

export default CheckBoxGroup;
