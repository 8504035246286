import { xApiVersion } from './getDataSets';

export const logError = (error, level) => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var api = url.origin + '/api';

    return fetch(`${window?.config?.REACT_APP_DATASET_API || api}/logError?level=${level}`, {
        method: 'POST',
        headers: {
            'X-API-Version': xApiVersion,
            'Content-Type': 'application/json',
        },
        body: error,
    });
};
