import queryString from 'query-string';
import { xApiVersion } from './getDataSets';

export const getDataSet = (location) => () => {
    const parameters = queryString.parse(location.search);
    const id = parameters['id'];
    const originId = parameters['origin'] ? parameters['origin'] : '0';
    var url = new URL(window.location.href);
    var api = url.origin + '/api';

    return fetch(`${window?.config?.reactAppDatasetApi || api}/dataset/${id}?originId=${originId}`, {
        method: 'get',
        headers: {
            'X-API-Version': xApiVersion,
        },
    });
};
