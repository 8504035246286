export const isItemPopulated = (item) => {
    return typeof item === 'object' && Object.keys(item).length > 0;
};

export const formatBytes = (a, b = 2, k = 1024) => {
    const { log, floor, pow, max } = Math;
    let d = floor(log(a) / log(k));
    return 0 === a
        ? '0 Bytes'
        : parseFloat((a / pow(k, d)).toFixed(max(0, b))) +
              ' ' +
              ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
};

export const removeArrayDuplicates = (checkBoxNameArray) => {
    return [...new Set(checkBoxNameArray)];
};

export const seperateCamelCase = (string) => {
    return typeof string === 'string' ? string.replace(/([a-z])([A-Z])/g, '$1 $2') : string;
};

export const scrollToRefItem = (ref, itemNumber, position) => {
    const mainPageEl = ref?.current?._outerRef?.firstChild?.childNodes?.[itemNumber];
    const individualPageEl = ref?.current?.childNodes?.[itemNumber];

    if (mainPageEl) {
        mainPageEl.scrollIntoView();
    } else if (individualPageEl) {
        individualPageEl.scrollIntoView();
    }
};

export const youTubeRegExp1 = /youtu/;
export const youTubeRegExp2 = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

export const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
