import React from 'react';
import GenericPageContentWrapper from '../../templates/GenericPageTemplate/GenericPageContentWrapper';
import IndividualDatasetPageContents from './IndividualDatasetPageContents/IndividualDatasetPageContents';

const IndividualDatasetPage = React.memo((props) => {
    return (
        <GenericPageContentWrapper>
            <IndividualDatasetPageContents />
        </GenericPageContentWrapper>
    );
});

IndividualDatasetPage.whyDidYouRender = true;

export default IndividualDatasetPage;
