import React from 'react';
import { Box, Heading, Text } from 'grommet';
import { Link } from '../../MDW/MDW';
import { MDX_BROWSER_PREFIX, ADR_BROWSER_PREFIX, useTitle } from '../../../hooks/useTitle/useTitle';
import KeyboardKeyComponent from '../../molecules/KeyBoardKey/KeyboardKeyComponent';

const SomethingWentWrongPage = (props) => {
    useTitle((window.config?.branding == 'adr' ? ADR_BROWSER_PREFIX : MDX_BROWSER_PREFIX) + 'Something Went Wrong');
    const ctrl = 'Ctrl';
    const cmd = 'Cmd';
    const shift = 'Shift';
    const r = 'R';
    return (
        <Box
            direction="column"
            align="center"
            justify="center"
            background="white"
            pad={{ left: 'large', right: 'large', vertical: 'large' }}
            fill
            className="page-not-found"
            {...props}
        >
            <Heading level={1} size="large" margin={{ bottom: 'none' }} color="primary">
                Oops...
            </Heading>
            <Text size="xlarge" weight="bold" margin={{ bottom: 'small' }}>
                Something went wrong
            </Text>
            <Box width="large">
                <>
                    <Text size="medium" textAlign="center">
                        Sorry, something went wrong. Please go back to the {<a href="/">home page</a>} or contact us for
                        support. <br /> But First try hard-reload with <KeyboardKeyComponent buttons={ctrl} /> +{' '}
                        <KeyboardKeyComponent buttons={shift} /> + <KeyboardKeyComponent buttons={r} /> <br /> <hr />
                        (For MAC user`s <KeyboardKeyComponent buttons={cmd} /> +{' '}
                        <KeyboardKeyComponent buttons={shift} /> + <KeyboardKeyComponent buttons={r} />)
                    </Text>
                </>
            </Box>
        </Box>
    );
};

SomethingWentWrongPage.whyDidYouRender = true;

export default SomethingWentWrongPage;
