import React from 'react';

const useThrowErrorFromComponent = () => {
    const [error, setError] = React.useState(null);
    React.useEffect(() => {
        if (error !== null) {
            throw error;
        }
    }, [error]);

    return setError;
};

export default useThrowErrorFromComponent;
