import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getDataSet } from '../../../api/getDataSet';
import useFetchItemsToRedux from '../../../hooks/useFetchItemsToRedux/useFetchItemsToRedux';
import { receiveDataSet, updateCurrentClass } from '../../../redux/actions/actions';
import store from '../../../redux/store';
import history from '../../../utils/history';
import { Loader } from '../../MDW/MDW';
import { useSideBarAndDataDownloadContext } from '../../templates/GenericPageTemplate/GenericPageTemplate';
import IndividualDatasetPage from './IndividualDatasetPage';

const dataSetPageNotFoundErrorCode = '-IDNF1';

const IndividualDatasetPageContainer = ({ dispatchReceiveDataSet, ...props }) => {
    const [errorMessage, setErrorMessage] = React.useState('');

    let location = useLocation();

    const { setShowSidebar, setShowAssets, setShowTreeData, setShowVersionHistory, setShowSidebarGlossary } =
        useSideBarAndDataDownloadContext();

    React.useEffect(() => {
        setShowSidebar(false);
        setShowAssets(false);
        setShowTreeData(false);
        setShowSidebarGlossary(false);
        setShowVersionHistory(false);
    }, [setShowSidebar, setShowAssets, setShowVersionHistory, setShowTreeData, setShowSidebarGlossary]);

    const isNewEndpoint = () => {
        const parameters = queryString.parse(location.search);
        const id = parameters['id'];
        const originId = parameters['origin'];

        const reduxDataSet = store.getState().dataSet;

        const last = reduxDataSet?.origin?.id;
        const result =
            reduxDataSet.id && reduxDataSet.id.includes(id) && (last === originId || !last || !originId) ? false : true;
        if (result) {
            store.dispatch(updateCurrentClass({}));
        }
        return result;
    };

    const removeEmptyProperties = (data) => {
        for (const key in data) {
            if (Object.keys(data[key]).length === 0) {
                delete data[key];
            } else {
                let isAllInnerPropertiesEmpty = true;
                Object.keys(data[key]).forEach((innerKey) => {
                    const innerItem = data[key][innerKey];
                    if (
                        !(
                            innerItem === '' ||
                            (typeof innerItem === 'object' && Object.keys(innerItem).length === 0) ||
                            (Array.isArray(innerItem) && innerItem.length === 0)
                        )
                    ) {
                        isAllInnerPropertiesEmpty = false;
                    }
                });
                if (isAllInnerPropertiesEmpty) {
                    delete data[key];
                }
            }
        }
        return data;
    };

    const middleWare = useCallback((data) => {
        let hasNoId = !data.id;
        if (hasNoId) throw new Error(`We could not find a Dataset with this ID! ${dataSetPageNotFoundErrorCode}`);
        return removeEmptyProperties(data);
    }, []);

    const getDatasetForLocation = useMemo(() => getDataSet(location), [location]);

    const { isSuccessful: receivedDataset } = useFetchItemsToRedux(
        getDatasetForLocation,
        dispatchReceiveDataSet,
        setErrorMessage,
        'dataSet',
        middleWare,
        isNewEndpoint(),
    );

    React.useEffect(() => {
        if (errorMessage !== '') {
            if (errorMessage.includes(dataSetPageNotFoundErrorCode)) {
                console.error(errorMessage);
                history.replace('/PageNotFound');
            } else {
                throw new Error(errorMessage);
            }
        }
    }, [errorMessage]);

    return !receivedDataset ? <Loader /> : <IndividualDatasetPage />;
};

const mapDispatchToProps = {
    dispatchReceiveDataSet: receiveDataSet,
};

IndividualDatasetPageContainer.whyDidYouRender = true;

export default connect(null, mapDispatchToProps)(IndividualDatasetPageContainer);

export const IndividualDatasetPageContainerFromPrefix = 'dataset';

IndividualDatasetPageContainer.propTypes = {
    dispatchReceiveDataSet: PropTypes.func.isRequired,
};
