export const fetchMetaAndRelations = (id, originId = '0') => {
    var url = new URL(window.location.href);
    var api = url.origin + '/api';

    return fetch(`${window?.config?.reactAppDatasetApi || api}/dataClassDetail/${id}?origin=${originId}`, {
        method: 'GET',
        headers: {
            'X-API-Version': '2.0',
        },
    }).then((res) => res.json().catch((err) => console.log(err)));
};
