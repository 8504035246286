const html = `

<!DOCTYPE html>
<html lang="en">

<head>
	<meta charset="UTF-8">
	<meta http-equiv="X-UA-Compatible" content="IE=edge">
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
	<title>MetadataWorks</title>
</head>

<body>
	<div class="landing-page-container">
		<h2 class="landing-page-title">Test Landing Page</h2>
		<div class="form-wrapper">
			<div class="colour-tab"></div>
			<form id="search-form" class="searchbar-container" action="/browser/search">
				<button type="submit" class="search-button" form="search-form" value="submit" title="Run search">

					<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16"
						style="enable-background:new 0 0 16 16;" xml:space="preserve">
						<style type="text/css">
							.st0 {
								fill: none;
							}

							.st1 {
								fill: #353434;
							}
						</style>
						<g id="Search_blue_1_">
							<rect id="Rectangle_693_1_" x="0" y="0" class="st0" width="16" height="16" />
							<path id="Path_208_1_" class="st1"
								d="M15.9,14.5l-3.3-3.3C13.52,9.99,14.01,8.52,14,7c0.02-3.84-3.07-6.98-6.91-7
					   C7.06,0,7.03,0,7,0C3.16-0.02,0.02,3.07,0,6.91C0,6.94,0,6.97,0,7c-0.02,3.84,3.07,6.98,6.91,7c0.03,0,0.06,0,0.09,0
					   c1.52,0.01,2.99-0.48,4.2-1.4l3.3,3.3L15.9,14.5z M2,7C1.97,4.27,4.17,2.03,6.9,2C6.93,2,6.97,2,7,2c2.73-0.03,4.97,2.17,5,4.9
					   c0,0.03,0,0.07,0,0.1c0.03,2.73-2.17,4.97-4.9,5c-0.03,0-0.07,0-0.1,0c-2.73,0.03-4.97-2.17-5-4.9C2,7.07,2,7.03,2,7z" />
						</g>
					</svg>


				</button>
				<input name="search-term" autocomplete="off" placeholder="Search all datasets" class="searchbar"
					type="text" value="" title="Enter a search term to search all datasets">

			</form>
		</div>
	</div class="container">


</body>

<style>
	body {
		background-color: #141b30;
	}

	.header {
		background: white;
	}



	@media (min-width: 1200px) {
		@media (max-height: 500px) {
			.landing-page-title {
				display: none !important;
			}
		}

		@media (min-width: 1350px) {

			.colour-tab {
				display: none !important;
			}
		}
	}

	@media (max-width: 1200px) {
		@media (max-height: 450px) {
			.landing-page-title {
				display: none !important;
			}
		}

		.landing-page-title {
			font-size: 2em !important;
		}

		.searchbar {
			font-size: 1em !important;
		}

		svg {
			min-height: 2em !important;
		}

		@media (min-width: 1100px) {
			.colour-tab {
				display: none !important;
			}
		}
	}

	@media (max-width: 900px) {
		@media (max-height: 450px) {
			.landing-page-title {
				display: none !important;
			}
		}

		.landing-page-title {
			font-size: 2em !important;
		}

		.searchbar {
			font-size: 0.875em !important;
		}

		svg {
			min-height: 1.5em !important;
		}
	}

	@media (max-width: 675px) {
		.landing-page-title {
			font-size: 1.5em !important;
		}

		.searchbar {
			font-size: 0.75em !important;
		}

		svg {
			min-height: 1.5em !important;
		}
	}

	.landing-page-title {
		display: flex;
		justify-content: center;
		padding: 0.25em;
		color: #f8f8f8;
		font-weight: 500;
		font-size: 3em;
		line-height: 1.5em;
	}

	.searchbar-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding: 30px 30px 30px 30px;
		overflow: hidden;
	}

	.form-wrapper {
		display: flex;
		flex-direction: row;
	}

	form {
		flex: 1;
	}

	.colour-tab {
		background: #D9364B;
		width: 4px;
	}


	.searchbar {
		font-weight: 400;
		font-size: 2em;
		background-color: #f8f8f8;
		border-radius: 12px;
		box-sizing: border-box;
		font-family: inherit;
		appearance: none;
		color: #D9364B;
		width: 45ch;
		padding: 11px 11px 11px 11px;
		border: 1px solid #223052;
		border-left: 0px;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;

	}

	.search-button {
		overflow: hidden;
		background: #f8f8f8;
		border-radius: 4px;
		border: 1px solid #223052;
		border-right: 0px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		padding: 1em;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	svg {
		min-height: 3em;
		max-height: 3em;
		width: 3em;
	}
</style>

</html>
`;

export default html;
