import React from 'react';
import PropTypes from 'prop-types';
import { FormContainer } from '../../MDW/MDW';

const FormContents = React.memo(({ children, ...props }) => {
    return <FormContainer>{children}</FormContainer>;
});

FormContents.whyDidYouRender = true;

export default FormContents;

FormContents.propTypes = {
    children: PropTypes.node.isRequired,
};
