import React from 'react';
import PropTypes from 'prop-types';
import GenericPageContentWrapper from '../../templates/GenericPageTemplate/GenericPageContentWrapper';
import BrokerSearchPageContents from './BrokerSearchPageContents/BrokerSearchPageContents';

const BrokerSearchPage = React.memo(({ errorMessage, ...props }) => {
    return (
        <GenericPageContentWrapper>
            <BrokerSearchPageContents errorMessage={errorMessage} />
        </GenericPageContentWrapper>
    );
});

BrokerSearchPage.whyDidYouRender = true;

BrokerSearchPage.propTypes = {
    errorMessage: PropTypes.string,
};

export default BrokerSearchPage;
