import DOMPurify from 'dompurify';
import { Box } from 'grommet';
import { memo, ReactNode, useEffect } from 'react';
import Loader from '../Loader/Loader';
import clientLibraryInjector from '../../../utils/clientLibraryInjector';

interface ClientHTMLType {
    children?: ReactNode;
    HTML?: string;
    loadingIndicator?: boolean;
    a11yTitle?: string;
    height?:
        | string
        | {
              max?: string | undefined;
              min?: string | undefined;
          };
    flex?:
        | boolean
        | 'grow'
        | 'shrink'
        | {
              grow?: number | undefined;
              shrink?: number | undefined;
          };
    basis?: string;
}

const ClientHTML = memo(({ HTML, loadingIndicator, ...props }: ClientHTMLType) => {
    useEffect(() => {
        if (HTML?.includes('<span id="include" display="none" hidden>')) {
            clientLibraryInjector(HTML);
        }
    }, [HTML]);

    return (
        <>
            {HTML ? (
                <Box
                    fill="horizontal"
                    a11yTitle={props.a11yTitle}
                    height={props.height}
                    flex={props.flex}
                    basis={props.basis}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(HTML, {
                                ADD_ATTR: ['target', 'onclick', 'href'],
                                ADD_TAGS: ['link', 'script'],
                                ALLOWED_URI_REGEXP:
                                    /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|sms|cid|xmpp|javascript):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i,
                            }),
                        }}
                    />
                </Box>
            ) : (
                loadingIndicator && (
                    <Box fill="horizontal" background="#141B30" justify="center" align="center">
                        <Loader />
                    </Box>
                )
            )}
        </>
    );
});

export default ClientHTML;
