import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

const useDeepCompareDependencies = (dependencies) => {
    const ref = useRef(dependencies);
    const signalRef = useRef(0);
    if (!isEqual(dependencies, ref.current)) {
        ref.current = dependencies;
        signalRef.current++;
    }
    return [signalRef.current];
};

const useDeepEffect = (callback, deps) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(callback, useDeepCompareDependencies(deps));
};

export default useDeepEffect;
