import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const StyledLink = styled(RouterLink)`
    line-height: ${(props) => props.lineheight};
    text-decoration: ${(props) => props.textDecoration};
    color: inherit;
    ${(props) => props.disabled && `pointer-events: none;`}
    ${(props) => props.width && `width: ${props.width};`}
    ${(props) => props.textalign && `text-align: ${props.textalign};`}
    ${(props) =>
        props.truncate &&
        `
        text-overflow: ellipsis;
        overflow: hidden;
        `}
`;

const Link = ({ children, ...props }) => {
    return <StyledLink {...props}>{children}</StyledLink>;
};

export default Link;

Link.propTypes = {
    lineheight: PropTypes.string,
    textDecoration: PropTypes.string,
    textalign: PropTypes.string,
    truncate: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
