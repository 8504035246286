import { rest } from 'msw';
import dataSets from './mock_browser_data_set.json';
import mock_single_dataset from './mock_single_dataset.json';
import mock_class_elements from './mock_class_elements.json';
import mock_filter_menu_items from './mock_filter_menu_items.json';
import mock_single_element from './mock_single_element.json';
import mock_header from './mock_header';
import mock_landing from './mock_landing';
import mock_footer from './mock_footer';
import {
    activeAndThenEmergencyCheckboxResponse,
    activeSearchBarResponse,
    activeTagResponse,
    page1andSize100PaginationResult,
    page2andSize25PaginationResult,
    page5andSize25PaginationResult,
} from '../components/pages/BrokerSearchPage/__Test__/testUtils';

const isParamWithReq = (req, string, comparison) => req.url.searchParams.get(string) === comparison;
const hasParamWithReq = (req, string, comparison) => req.url.searchParams.get(string);
var url = new URL(window.location.href);
var api = url.origin + '/api';
const handlers = [
    rest.get(`${api}`, (req, res, ctx) => {
        const isParam = (string, comparison) => isParamWithReq(req, string, comparison);
        const hasParam = (string, comparison) => hasParamWithReq(req, string, comparison);
        if (req.url.searchParams.toString() === '') return res(ctx.json(dataSets));

        if (isParam('pageNumber', '1') && isParam('pageSize', '100'))
            return res(ctx.json(page1andSize100PaginationResult));

        if (isParam('keywords', 'Active')) return res(ctx.json(activeTagResponse));

        if (isParam('searchTerm', 'active')) {
            if (isParam('keywords', 'Accident and Emergency')) {
                return res(ctx.json(activeAndThenEmergencyCheckboxResponse));
            }
            return res(ctx.json(activeSearchBarResponse));
        }
        if (isParam('keywords', 'Accident and Emergency')) {
            return res(ctx.json(activeAndThenEmergencyCheckboxResponse));
        }
        if (isParam('keywords', 'active')) {
            return res(ctx.json(activeTagResponse));
        }
        if (isParam('keywords', 'sail')) {
            return res(ctx.json(dataSets));
        }
        if (hasParam('example_unknown_filter')) {
            return res(ctx.json(dataSets));
        }

        if (isParam('pageNumber', '2' || '3' || '4') && isParam('pageSize', '25'))
            return res(ctx.json(page2andSize25PaginationResult));
        if (isParam('pageNumber', '5') && isParam('pageSize', '25'))
            return res(ctx.json(page5andSize25PaginationResult));

        for (const [key, val] of req.url.searchParams.entries()) console.warn(`${key}: ${val}`);
        console.warn('Request Failed!');
    }),
    rest.get(`${window?.config?.reactAppDatasetApi || api}/dataset/:id`, (req, res, ctx) => {
        const { id } = req.params;
        mock_single_dataset['id'] = id;
        return res(ctx.json(mock_single_dataset));
    }),
    rest.get(`${window?.config?.reactAppDatasetApi || api}/structural/:id`, (req, res, ctx) => {
        const { id } = req.params;
        mock_class_elements['id'] = id;
        return res(ctx.json(mock_class_elements));
    }),
    rest.get(`${window?.config?.reactAppDatasetApi || api}/dataset/attributes`, (req, res, ctx) => {
        return res(ctx.json(mock_filter_menu_items));
    }),
    rest.get(`${window?.config?.reactAppDatasetApi || api}/dataElement/:id`, (req, res, ctx) => {
        const { id } = req.params;
        mock_single_element['id'] = id;
        return res(ctx.json(mock_single_element));
    }),
    rest.get('http://localhost/header.html', (req, res, ctx) => {
        return res(ctx.text(mock_header));
    }),
    rest.get('http://localhost/landing.html', (req, res, ctx) => {
        return res(ctx.text(mock_landing));
    }),
    rest.get('http://localhost/footer.html', (req, res, ctx) => {
        return res(ctx.text(mock_footer));
    }),
];
export { handlers };
