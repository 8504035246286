import styled from 'styled-components';
import theme from '../../../style/theme';
import { Box, Accordion, AccordionPanel, AccordionPanelProps } from 'grommet';

interface StyledAccordionPanelProps extends AccordionPanelProps {
    noExpand?: boolean;
}

export const StyledAccordion = styled(Accordion)``;
const color = theme.global.colors[window.localStorage.getItem('branding') || 'default'].arrowColor;
export const StyledAccordionPanel = styled(AccordionPanel)`
    & div {
        padding-left: 24px;
    }
    & h4 {
        font-size: ${theme.fontSizes[1]};
        font-weight: 400;
        margin-block-start: 0.8em;
        margin-block-end: 0.8em;
        color: ${color};
    }
    & svg {
        height: 18px;
        width: 18px;
        stroke: ${color};
        fill: ${color};
    }
    ${(props: StyledAccordionPanelProps) =>
        props.noExpand &&
        `
    & svg {display: none;}
    `}
`;

export const StyledAccordionBox = styled(Box)``;
