import { Box, Heading, Text } from 'grommet';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../../../../style/theme';
import { isTesting } from '../../../../../utils/testingUtils';
import { Link } from '../../../../MDW/MDW';
import DOMPurify from 'dompurify';
import { Tooltip, makeStyles } from '@material-ui/core';
import IndividualDatasetPageHeaderDescription from '../../IndividualDatasetPageContents/IndividualDatasetPageHeaderDescription';
const ListItemBox = styled(Box)`
    cursor: default;
`;

// const StyledClassDescriptionText = styled(Text)`
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// `;

const StyledLink = styled(Link)`
    &:focus {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
        outline: none;
    }
`;

const StyledHeading = styled(Heading)`
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 600;
`;

// interface ClassDetailsResponse {
//     id: number;
//     name: string;
//     description: string;
// }
const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        fontSize: '14px',
    },
    customWidth: {
        maxWidth: 500,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
}));
const DataClassHeader = ({ name, description }) => {
    const location = useLocation();
    const classes = useStyles();
    const [title, setTitle] = React.useState('');
    const state = useSelector((state) => state.treeviewDataInDataSet);
    setTimeout(() => {
        setTitle(name);
    }, 100);
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });
    let desc = DOMPurify.sanitize(description, { USE_PROFILES: { html: true } });
    return (
        <React.Fragment>
            <ListItemBox
                margin={{ bottom: '10px' }}
                width="100%"
                // id={`browser-data-item-id-${classDetails?.id}`}
                {...{ 'data-tip': description || '' }}
            >
                {name && (
                    <p style={{ fontSize: '12px', fontWeight: '500', padding: '8px 10px', margin: '0' }} weight={500}>
                        Class Name:
                    </p>
                )}
                <Box direction="row" justify="between" height={{ min: '23px' }} pad={{ left: '10px' }}>
                    <StyledLink to={{ ...location }} textDecoration="none" disabled={true} truncate="true">
                        <StyledHeading size="16px" level={1} data-testid="structural-data-summary-title">
                            {title || ''}
                        </StyledHeading>
                    </StyledLink>
                </Box>
                {/* <StyledLink to={{ ...location }} textDecoration="none" disabled={true}> */}
                {/* <Tooltip title={desc} classes={{ tooltip: classes.button }}> */}
                <Box margin={{ top: 'small' }}>
                    {desc.length > 255 ? (
                        <IndividualDatasetPageHeaderDescription
                            abstract={description}
                            desc={desc}
                            bg={'white'}
                            textColor={'black'}
                        />
                    ) : (
                        <Tooltip
                            title={description ? description : 'No Class Description'}
                            classes={{ tooltip: classes.button }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '12px',
                                    letterSpacing: 'none',
                                }}
                                dangerouslySetInnerHTML={{ __html: desc }}
                            ></div>
                        </Tooltip>
                    )}
                </Box>
                {/* </Tooltip> */}

                {/* </StyledLink> */}
            </ListItemBox>
        </React.Fragment>
    );
};

export default DataClassHeader;

// import styled from 'styled-components';
// import { Box, Text } from 'grommet';
// import DOMPurify from 'dompurify';
// import { useSelector } from 'react-redux';
// import { useEffect } from 'react';
// import { Tooltip } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
// const StyledSummaryTitleText = styled(Text)`
//     white-space: break-spaces;
// `;

// const StyledPublisherText = styled(Text)`
//     text-transform: capitalize;
// `;
// const StyledWrapperForHeader = styled.div`
//     display: flex;
//     box-sizing: border-box;
//     max-width: 100%;
//     background-color: #1b2f64;
//     color: #f8f8f8;
//     min-width: 0;
//     min-height: 100px;
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     margin: 10px;
//     padding: 0px 10px;
//     border-radius: 6px;
//     justify-content: space-between;
// `;

// const useStyles = makeStyles((theme) => ({
//     button: {
//         margin: theme.spacing(1),
//         fontSize: '14px',
//     },
//     customWidth: {
//         maxWidth: 500,
//     },
//     noMaxWidth: {
//         maxWidth: 'none',
//     },
// }));
// const DataClassHeader = (props) => {
//     const classes = useStyles();
//     let desc = DOMPurify.sanitize(props.description, { USE_PROFILES: { html: true } }) || '[No class description]';
//     // let name = (stateN?.name) || '[No class name]';
//     return (
//         <>
//             <StyledWrapperForHeader>
//                 <Box>
//                     <Box width="100%" margin={{ bottom: 'small', top: 'small' }}>
//                         <StyledSummaryTitleText
//                             size={'large'}
//                             weight={500}
//                             truncate={false}
//                             data-testid={`data-summary-title`}
//                             wordBreak="break-all"
//                         >
//                             {props.name}
//                             <CircularProgress color="white" disableShrink size={19} />
//                         </StyledSummaryTitleText>
//                     </Box>
//                     <Box width="100%" margin={{ bottom: 'small' }}>
//                         <Text size="xsmall" truncate={false} color="white">
//                             {/* <Tooltip
//                                 title={<div dangerouslySetInnerHTML={{ __html: desc }}></div>}
//                                 classes={{ tooltip: classes.button }}
//                             > */}
//                             <div
//                                 style={{ overflowY: 'scroll', height: '95px', width: '100%', padding: '10px' }}
//                                 dangerouslySetInnerHTML={{ __html: desc }}
//                             ></div>
//                             {/* </Tooltip> */}
//                         </Text>
//                     </Box>
//                 </Box>
//             </StyledWrapperForHeader>
//         </>
//     );
// };

// export default DataClassHeader;
