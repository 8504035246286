import { EventArgs } from 'react-ga';
import googleAnalyticsConsentManager from '../components/organisms/CookieConsentModal/GoogleAnalyticsConsentManager';

declare var window: {
    config: { googleAnalyticsTrackingId: string };
    gtag: (...args: any) => any;
};

const init = () => {
    // Enable debug mode on the local development environment
    //const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    if (googleAnalyticsConsentManager.queryUserAcceptedGoogleAnalytics()) {
        window.gtag('js', new Date());

        window.gtag('config', window.config.googleAnalyticsTrackingId);
    }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const sendEvent = ({ action, category, label, value }: EventArgs) => {
    googleAnalyticsConsentManager.queryUserAcceptedGoogleAnalytics() &&
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value,
        });
};

const analytics = {
    init,
    sendEvent,
};

export default analytics;
