import React from 'react';
import { Loader } from '../../../MDW/MDW';

const IndividualDatasetPageHeaderAltmetric = ({ doiName }) => {
    React.useEffect(() => {
        const script = document.createElement('script');
        const meta = document.createElement('meta');
        meta.name = 'DC.Identifier';
        meta.content = 'doi:' + doiName;
        script.src = 'https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js';
        script.async = true;
        document.body.appendChild(script);
        document.head.appendChild(meta);
        return () => {
            document.body.removeChild(script);
            document.head.removeChild(meta);
        };
    }, []);
    return (
        <div
            style={{
                margin: '10px',
                background: 'white',
                borderRadius: '32px',
                width: '64px',
                height: '64px',
            }}
            data-badge-popover="left"
            data-badge-type="donut"
            data-doi={doiName}
            // data-hide-no-mentions="true"
            className="altmetric-embed"
        ></div>
    );
};

export default IndividualDatasetPageHeaderAltmetric;
