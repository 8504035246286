import axios from 'axios';
import fileDownload from 'js-file-download';

export const getAssets = async (id, filename) => {
    var url = new URL(window.location.href);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const origin = urlParams.get('origin');
    var api = url.origin + '/api';
    var url = `${window?.config?.reactAppDatasetApi || api}/asset/${id}?originId=${origin}`;
    return axios
        .get(url, {
            responseType: 'blob',
            headers: {
                'X-API-Version': '2.0',
            },
        })
        .then((res) => {
            fileDownload(res.data, filename);
        });
};
