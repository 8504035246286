const html = `

<header class="nhsuk-header nhsuk-header--nhsx" role="banner">
	<div class="nhsuk-width-container"> <div class="nhsuk-header__container">
		<div class="nhsuk-header__logo">
				<a class="nhsuk-header__link" aria-label="Test Header" href="#" data-testid="header__logo"></a>
				<svg class="nhsuk-logo nhsuk-logo--nhsx" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 753.3 250">
					<path class="st0" d="M0 0h753.3v250H0z" fill="#003087"></path>
					<path class="st0"
						d="M138.8 225.4L96.7 86.1h-.6l-28 139.3H17.4l43-201.2h67.2l41.2 139.5h.6l28.3-139.5h50.7l-42.7 201.2h-66.9zm205.2 0l17.9-86.2h-63.7l-17.9 86.2h-53.9l41.8-201.2h53.9l-15.8 77H370l15.9-77h53.9L398 225.4h-54zM581.6 69.7c-10.4-4.9-24.5-9.2-44.4-9.2-21.4 0-38.6 3.2-38.6 19.3 0 28.5 78.1 17.9 78.1 79 0 55.7-51.6 70.1-98.3 70.1-20.8 0-44.7-4.9-62.3-10.4l12.7-41c10.7 6.9 32 11.5 49.6 11.5 16.7 0 43-3.2 43-23.9 0-32.3-78.1-20.2-78.1-77 0-51.9 45.6-67.5 89.7-67.5 24.8 0 48.2 2.6 61.7 8.9l-13.1 40.2z"
						fill="#003087"></path>
					<path
						d="M0 0v250h617.7V0H0zm138.8 225.4L96.7 86.1h-.6l-28 139.3H17.4l43-201.2h67.2l41.2 139.5h.6l28.3-139.5h50.7l-42.7 201.2h-66.9zm205.2 0l17.9-86.2h-63.7l-17.9 86.2h-53.9l41.8-201.2h53.9l-15.8 77H370l15.9-77h53.9L398 225.4h-54zM581.6 69.7c-10.4-4.9-24.5-9.2-44.4-9.2-21.4 0-38.6 3.2-38.6 19.3 0 28.5 78.1 17.9 78.1 79 0 55.7-51.6 70.1-98.3 70.1-20.8 0-44.7-4.9-62.3-10.4l12.7-41c10.7 6.9 32 11.5 49.6 11.5 16.7 0 43-3.2 43-23.9 0-32.3-78.1-20.2-78.1-77 0-51.9 45.6-67.5 89.7-67.5 24.8 0 48.2 2.6 61.7 8.9l-13.1 40.2zM687 24.2l14.6 34.6h.3l27.7-34.6h23.8l-41.7 48 23.3 52.6h-22.1l-15.4-40.4h-.3l-31.4 40.4h-25.1l46.6-54.6-22.1-46H687z"
						fill="#fff"></path>
				</svg>
			</a>
		</div>



		<div class="nhsuk-header__content" id="content-header">
<div style='display:flex; color: white; flex-direction: row; gap:5ch; font-size: 1rem;'>
<div>Login</div>
<div>Help</div>
</div>



		</div>


	</div>


	<nav class="nhsuk-header__navigation nhsuk-header__navigation--nhsx" id="header-navigation" role="navigation"
		aria-label="Primary navigation">
		<ul class="nhsuk-header__navigation-list mobile-nav">
			<li class="nhsuk-header__navigation-item nhsuk-header__navigation-item--for-mobile">
				<a class="nhsuk-header__navigation-link nhsuk-header__navigation-link--nhsx"
					href="#">
					Home
				</a>
			</li>

			<li class="nhsuk-header__navigation-item">
				<a class="nhsuk-header__navigation-link nhsuk-header__navigation-link--nhsx"
					href="#">
					About
				</a>
			</li>


			<li class="nhsuk-header__navigation-item">
				<a class="nhsuk-header__navigation-link nhsuk-header__navigation-link--nhsx"
					href="#">
					Datasets
				</a>
			</li>


			<li class="nhsuk-header__navigation-item">
				<a class="nhsuk-header__navigation-link nhsuk-header__navigation-link--nhsx"
					href="#">
					Data Standards
				</a>
			</li>

			<li class="nhsuk-header__navigation-item">
				<a class="nhsuk-header__navigation-link nhsuk-header__navigation-link--nhsx"
					href="#">
					Reference Data
				</a>
			</li>

			<li class="nhsuk-header__navigation-item">
				<a class="nhsuk-header__navigation-link nhsuk-header__navigation-link--nhsx"
					href="#">
					News
				</a>
			</li>





		</ul>
	</div>
	</nav>


</header>
`;

export default html;
