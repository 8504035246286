import { ThemeType } from 'grommet';

export interface ThemeObjectType extends Omit<ThemeType, any> {
    a11yBoxShadow: a11yBoxShadowType;
    fontSizes: string[];
    fontWeights: FontWeights;
    radii: RadiiType;
    global: any;
    markdown: {
        small: string;
        xsmall: string;
    };
}
// @ts-ignore
interface Global {
    colors: GlobalColors;
    font: any;
    focus: any;
}
interface GlobalColors {
    selected: string;
    focus: { dark: string; light: string };
    focusColor: string;
    primary: { default: string; adr: string }; //
    secondary: { default: string; adr: string }; //
    primaryLight: { default: string; adr: string }; //
    // #1b2f64 default
    primaryDark: { default: string; adr: string };
    neuralDark: { default: string; adr: string };
    neuralLight: { default: string; adr: string };
    neuralMid: { default: string; adr: string };
    backgroundBlue: string; //
    faintWhite: string; //
    alternativeBlue: string; //
    internalsBlue: string; //
    skyBlue: string;
    richAlternativeBlue: string; //
    pastelBlue: string; //
    lightPastelBlue: string; //
    secondaryText: string; //
    dividerLine: string; //
    primaryDividerLine: string; //
    darkGrey: string;
    grey: string;
    lightBlue: string;
    midGrey: string; //
    lightestBlue: string; //
    lightGrey: string; //
    white: string;
    green: string;
    darkGreen: string;
    orange: string;
    disabled: string;
    lighterGrey: string;
    primaryHover: string;
    secondaryHover: string;
    secondaryBody: string;
    error: string;
    textDarkGrey: string;
    textLightGrey: string; //
    black: string;
    dark: string;
}
interface FontWeights {
    thin?: number;
    body?: number;
    medium?: number;
    heading?: number;
    semibold?: number;
    bold?: number;
}

interface RadiiType {
    xlarge?: string;
    large?: string;
    small?: string;
}

interface a11yBoxShadowType {
    thickness: MeasurementString;
    thickerThickness: MeasurementString;
    colour?: `#${string}` | any;
    alternateColour: `#${string}` | any;
}
type MeasurementType = 'px';
type MeasurementString = `${number}${MeasurementType}`;

const theme: ThemeObjectType = {
    a11yBoxShadow: {
        thickness: '2px',
        thickerThickness: '4px',
        // colour: window.config.branding == 'adr' ? '#6fcdbd' : '#D9364B',
        colour: '#6fcdbd',
        alternateColour: 'red',
    },
    fontSizes: ['10px', '12px', '13px', '14px', '15px', '16px', '17px', '20px', '24px', '32px', '48px', '64px', '96px'],
    fontWeights: {
        thin: 300,
        body: 400,
        medium: 500,
        heading: 500,
        semibold: 600,
        bold: 600,
    },
    // @ts-ignore
    heading: {
        weight: 500,
        level: {
            3: {
                small: {
                    size: '24px',
                    height: '28px',
                    maxWidth: '528px',
                },
            },
        },
    },
    global: {
        colors: {
            default: {
                selected: 'primary',
                focus: { dark: 'focusColor', light: 'focusColor' },
                focusColor: '#4a79c3',
                primary: '#223052', //
                secondary: '#D9364B',
                primaryLight: '#1C2844',
                neuralDark: '#000000',
                neuralLight: '#ffffff',
                neuralMid: '#141b30',
                primaryText: '#ffffff',
                primaryButtonText: '#ffffff',
                navbarBackgroundColor: '#223052',
                mainBackgroundColor: '#141B30',
                focusBorderColor: '#D9364B',
                backgroundBlue: '#141B30', //
                faintWhite: '#e1e1e117', //
                alternativeBlue: '#2F4773', //
                internalsBlue: '#1C2844', //
                skyBlue: '#6fcdbdb',
                richAlternativeBlue: '#1B2F64', //
                pastelBlue: '#4D576D', //
                lightPastelBlue: '#c5ccde', //
                secondaryText: '#6E6E6E', //
                dividerLine: '#00000033', //
                primaryDividerLine: '#162142cc', //
                darkGrey: '#4B4B4B',
                grey: '#A2A2A2',
                lightBlue: '#D3D7DD',
                midGrey: '#D7DBDC', //
                lightestBlue: '#eef1f7', //
                lightGrey: '#E3E5EB', //
                white: '#FFFFFF',
                green: '#609F5D',
                darkGreen: '#246363',
                orange: '#E4AE49',
                disabled: '#EAEAEA',
                lighterGrey: '#F6F6F6',
                primaryHover: '#172843',
                secondaryHover: '#214074',
                secondaryBody: '#8B8B8B',
                error: '#C74E2E',
                textDarkGrey: '#444444',
                textLightGrey: '#787676', //
                black: '#353535',
                dark: '#000000',
                arrowColor: '#ffffff',
                dataSetHeaderBackground: '#1b2f64',
                contactButtonBgColor: '#D9364B',
                accessDataButtonBgColor: '#4F814B',
                dynamicTagBgColor: '#d7dbdc',
                selectedTagBgColor: '#D9364B',
                checkBoxStroke: '#ffffff',
                navbarHelpItemBackgroundColor: '#e05f71',
            },
            adr: {
                primary: '#6bbbae',
                secondary: '#f07314',
                primaryLight: '#6fcdbd',
                primaryDark: '#53998d',
                neuralDark: '#1c1c1c',
                primaryText: '#000',
                primaryButtonText: '#000',
                // neuralLight: '#edf2f5',<- brand color
                // wireframe color
                neuralLight: '#ffffff',
                // neuralMid: '#dee3e6', <- brand color
                // wireframe color
                mainBackgroundColor: '#ffffff',
                navbarBackgroundColor: '#ffffff',
                focusBorderColor: '#6fcdbd',
                arrowColor: '#000000',
                dataSetHeaderBackground: '#EEF2F5',
                contactButtonBgColor: '#E17A34',
                accessDataButtonBgColor: '#6fcdbd',
                checkBoxStroke: '#000000',
                selectedTagBgColor: '#f07314',
                neuralMid: '#dee3e6',
                navbarHelpItemBackgroundColor: '#e17a34',
            },
            dsc2: {
                primary: '#66cebe',
                secondary: '#00594C',
                primaryLight: '#ccefe9',
                primaryDark: '#53998d',
                neuralDark: '#1c1c1c',
                primaryText: '#000',
                primaryButtonText: '#fff',
                neuralLight: '#ffffff',
                mainBackgroundColor: '#ffffff',
                navbarBackgroundColor: '#ffffff',
                focusBorderColor: '#ccefe9',
                arrowColor: '#000000',
                dataSetHeaderBackground: '#EEF2F5',
                contactButtonBgColor: '#00594C',
                accessDataButtonBgColor: '#ccefe9',
                checkBoxStroke: '#000000',
                selectedTagBgColor: '#f07314',
                neuralMid: '#dee3e6',
                navbarHelpItemBackgroundColor: '#66cebe',
                faintWhite: '#ccefe9',
            },
        },
        // @ts-ignore
        adr: {
            secondarySearch: 'black', //
        },
        font: {
            family: 'Lato',
        },
        focus: {
            shadow: {
                size: '0px',
            },
        },
    },
    accordion: {
        icons: {
            color: 'white',
        },
    },
    radii: {
        xlarge: '12px',
        large: '6px',
        small: '4px',
    },
    markdown: {
        small: '14px',
        xsmall: '12px',
    },
    select: {
        options: {
            text: { size: 'small' },
        },
        control: {
            open: `border: 1px solid black`,
        },
        icons: {
            color: 'primary',
        },
    },
    tab: {
        border: {
            size: '3px',
            color: 'transparent',
            active: {
                color: 'primary',
            },
        },
    },
    button: {
        // @ts-ignore
        disabled: {
            opacity: 0.5,
        },
    },
};

export default theme;
