import history from './history';
import store from '../redux/store';
import { receiveAuthentication, resetApp, receiveProfile } from '../redux/actions/actions';

const advancedUserRoleArray = ['ADMIN', 'SUPERUSER'];
export const tokenStoragePropertyNameString = 'mdw-Bq*z$J£S.UdY';
export const timerStoragePropertyNameString = 'tme-Bq*z$J£S.UdY';
export const xsrfStoragePropertyNameString = 'XSRF-TOKEN';

export const isAdvancedUser = () => {
    let value = false;
    store.getState().profile.organisationRoles &&
        store.getState().profile.organisationRoles.forEach((organisationRole) => {
            advancedUserRoleArray.forEach((role) => {
                if (organisationRole.role.name === role) {
                    value = true;
                }
            });
        });
    return value;
};

// export const handleLogout = () => {
//   deleteSession();
//   history.push(`/login`);
// };

export const handleSession = async () => {};

export const handleAuthentication = (profile) => {
    store.dispatch(receiveAuthentication(true));
    store.dispatch(receiveProfile(profile));
    addAuthInLocalIndication(tokenStoragePropertyNameString);
};

export const getCookie = (name) => {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
        let [k, v] = el.split('=');
        cookie[k.trim()] = v;
    });
    return cookie[name];
};

export const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

// export const handleLocalStorageChange = () => {
//   !window.localStorage.getItem(xsrfStoragePropertyNameString) && handleLogout();
// };

export const deleteSession = () => {
    store.dispatch(resetApp());
    removeAuthInLocalIndication(xsrfStoragePropertyNameString);
};

const addAuthInLocalIndication = (token) => {
    window.localStorage.setItem(xsrfStoragePropertyNameString, token);
};

const removeAuthInLocalIndication = (name) => {
    window.localStorage.removeItem(name);
};
