import matchMediaPolyfill from 'mq-polyfill';

export const initialStatsValue = (json) => `Showing results:  1 to 25  of  ${json.totalCount}`;
export const updatedStatsValue = `Showing results:  1 to 1  of  1`;
export const searchUpdatedStatsValue = `Showing results:  1 to 2  of  2`;
export const finalComboStatsValue = `Showing results:  1 to 2  of  2`;
export const secondPageStatsValue = (json) => `Showing results:  26 to 50  of  ${json.totalCount}`;
export const finalPageStatsValue = (json) => `Showing results:  101 to ${json.totalCount}  of  ${json.totalCount}`;

export const findAllChildrenText = async (text, screen) => {
    return await screen.findByText((content, node) => {
        const hasText = (node) => node.textContent.search(text) !== -1;
        const nodeHasText = hasText(node);
        const childrenDontHaveText = Array.from(node.children).every((child) => !hasText(child));

        return nodeHasText && childrenDontHaveText;
    });
};
export const getAllChildrenText = (text, screen) => {
    return screen.getByText((content, node) => {
        const hasText = (node) => node.textContent.search(text) !== -1;
        const nodeHasText = hasText(node);
        const childrenDontHaveText = Array.from(node.children).every((child) => !hasText(child));

        return nodeHasText && childrenDontHaveText;
    });
};

export const querySmallestNodeWithTextContent = (text, screen) => {
    return screen.queryByText((content, node) => {
        const hasText = (node) => node.textContent.search(text) !== -1;
        const nodeHasText = hasText(node);
        const childrenDontHaveText = Array.from(node.children).every((child) => !hasText(child));

        return nodeHasText && childrenDontHaveText;
    });
};

export const enabledWindowResizing = () => {
    matchMediaPolyfill(window);
    window.resizeTo = function resizeTo(width, height) {
        Object.assign(this, {
            innerWidth: width,
            innerHeight: height,
            outerWidth: width,
            outerHeight: height,
        }).dispatchEvent(new this.Event('resize'));
    };
};

export const createClientWidths = (scrollContent) => {
    scrollContent = document.querySelectorAll("div[data-testid='scroll-button-content']")[1];
    Object.defineProperty(scrollContent, 'scrollHeight', {
        configurable: true,
        value: 73,
    });
    Object.defineProperty(scrollContent, 'clientHeight', {
        configurable: true,
        value: 50,
    });
    Object.defineProperty(scrollContent, 'clientWidth', {
        configurable: true,
        value: 660,
    });
    Object.defineProperty(scrollContent, 'scrollWidth', {
        configurable: true,
        value: 660,
    });
    return scrollContent;
};

export const activeTagResponse = {
    content: [
        {
            id: '424732',
            summary: {
                title: 'Active Adult Survey',
                publisher: {
                    name: 'SAIL',
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                },
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                keywords: ['Active', 'SAIL'],
                doiName: 'In Progress',
                abstract: 'Large scale survey of the adult population in Wales using CAPI. Replaced by NSWD.',
            },
        },
    ],
    filters: [
        {
            name: 'name',
            description: 'Publisher',
            options: ['SAIL'],
        },
        {
            name: 'dataUseLimitation',
            description: 'Data Use Limitation',
            options: ['GENERAL RESEARCH USE', 'RESEARCH SPECIFIC RESTRICTIONS'],
        },
        {
            name: 'dataUseRequirements',
            description: 'Data Use Requirements',
            options: ['PROJECT SPECIFIC RESTRICTIONS'],
        },
        {
            name: 'keywords',
            description: 'Keywords',
            options: ['Active', 'SAIL'],
        },
        {
            name: 'physicalSampleAvailability',
            description: 'Physical Sample Availability',
            options: ['NOT AVAILABLE'],
        },
        {
            name: 'spatial',
            description: 'Geographic Coverage',
            options: ['Wales'],
        },
    ],
    totalCount: 1,
    totalPages: 1,
    pageSize: 25,
    pageCount: 1,
    currentPage: 1,
};

export const activeSearchBarResponse = {
    content: [
        {
            id: '424732',
            summary: {
                title: 'Active Adult Survey',
                publisher: {
                    name: 'SAIL',
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                },
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                keywords: ['Active', 'SAIL'],
                doiName: 'In Progress',
                abstract: 'Large scale survey of the adult population in Wales using CAPI. Replaced by NSWD.',
            },
        },
        {
            id: '260208',
            summary: {
                title: 'Emergency Department Data Set',
                publisher: {
                    name: 'SAIL',
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                },
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                keywords: ['Emergency', 'A&E', 'SAIL'],
                doiName: 'In Progress',
                abstract: 'Attendance and clinical information for all Accident and Emergency attendances.',
            },
        },
    ],
    filters: [
        {
            name: 'name',
            description: 'Publisher',
            options: ['SAIL'],
        },
        {
            name: 'dataUseLimitation',
            description: 'Data Use Limitation',
            options: ['GENERAL RESEARCH USE', 'RESEARCH SPECIFIC RESTRICTIONS'],
        },
        {
            name: 'dataUseRequirements',
            description: 'Data Use Requirements',
            options: ['PROJECT SPECIFIC RESTRICTIONS', 'TIME LIMIT ON USE', 'USER SPECIFIC RESTRICTION'],
        },
        {
            name: 'keywords',
            description: 'Keywords',
            options: ['A&E', 'Active', 'Emergency', 'SAIL'],
        },
        {
            name: 'physicalSampleAvailability',
            description: 'Physical Sample Availability',
            options: ['NOT AVAILABLE'],
        },
        {
            name: 'spatial',
            description: 'Geographic Coverage',
            options: ['Wales'],
        },
    ],
    totalCount: 2,
    totalPages: 1,
    pageSize: 25,
    pageCount: 2,
    currentPage: 1,
};

export const activeAndThenEmergencyCheckboxResponse = {
    content: [
        {
            id: '260208',
            summary: {
                title: 'Emergency Department Data Set',
                publisher: {
                    name: 'SAIL',
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                },
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                keywords: ['Emergency', 'A&E', 'SAIL'],
                doiName: 'In Progress',
                abstract: 'Attendance and clinical information for all Accident and Emergency attendances.',
            },
        },
    ],
    filters: [
        {
            name: 'name',
            description: 'Publisher',
            options: ['SAIL'],
        },
        {
            name: 'dataUseLimitation',
            description: 'Data Use Limitation',
            options: ['GENERAL RESEARCH USE'],
        },
        {
            name: 'dataUseRequirements',
            description: 'Data Use Requirements',
            options: ['PROJECT SPECIFIC RESTRICTIONS', 'TIME LIMIT ON USE', 'USER SPECIFIC RESTRICTION'],
        },
        {
            name: 'keywords',
            description: 'Keywords',
            options: ['A&E', 'Emergency', 'SAIL'],
        },
        {
            name: 'physicalSampleAvailability',
            description: 'Physical Sample Availability',
            options: ['NOT AVAILABLE'],
        },
        {
            name: 'spatial',
            description: 'Geographic Coverage',
            options: ['Wales'],
        },
    ],
    totalCount: 1,
    totalPages: 1,
    pageSize: 25,
    pageCount: 1,
    currentPage: 1,
};

export const activeSearchThenEmergencyCheckThenSailTagResponse = {
    content: [
        {
            id: '424732',
            summary: {
                title: 'Active Adult Survey',
                publisher: {
                    name: 'SAIL',
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                },
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                keywords: ['Active', 'SAIL'],
                doiName: 'In Progress',
                abstract: 'Large scale survey of the adult population in Wales using CAPI. Replaced by NSWD.',
            },
        },
        {
            id: '260208',
            summary: {
                title: 'Emergency Department Data Set',
                publisher: {
                    name: 'SAIL',
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                },
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                keywords: ['Emergency', 'A&E', 'SAIL'],
                doiName: 'In Progress',
                abstract: 'Attendance and clinical information for all Accident and Emergency attendances.',
            },
        },
    ],
    filters: [
        {
            name: 'name',
            description: 'Publisher',
            options: ['SAIL'],
        },
        {
            name: 'dataUseLimitation',
            description: 'Data Use Limitation',
            options: ['GENERAL RESEARCH USE', 'RESEARCH SPECIFIC RESTRICTIONS'],
        },
        {
            name: 'dataUseRequirements',
            description: 'Data Use Requirements',
            options: ['PROJECT SPECIFIC RESTRICTIONS', 'TIME LIMIT ON USE', 'USER SPECIFIC RESTRICTION'],
        },
        {
            name: 'keywords',
            description: 'Keywords',
            options: ['A&E', 'Active', 'Emergency', 'SAIL'],
        },
        {
            name: 'physicalSampleAvailability',
            description: 'Physical Sample Availability',
            options: ['NOT AVAILABLE'],
        },
        {
            name: 'spatial',
            description: 'Geographic Coverage',
            options: ['Wales'],
        },
    ],
    totalCount: 2,
    totalPages: 1,
    pageSize: 25,
    pageCount: 2,
    currentPage: 1,
};

export const page5andSize25PaginationResult = {
    content: [
        {
            id: '258807',
            summary: {
                abstract:
                    'Information on the total time waited from referral by a GP or other medical practitioner to hospital treatment in the NHS.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Referral to treatment', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Referral to Treatment Times',
            },
        },
        {
            id: '360993',
            summary: {
                abstract:
                    'The Scottish Intensive Care Society Audit Group (SICSAG) has maintained a national database of patients admitted to adult general Intensive Care Units (ICU) in Scotland since 1995. Each line relates to a day of stay in critical care.',
                contactPoint: 'phs.edris@nhs.net',
                keywords: ['SICSAG', 'NCS', 'Scotland', 'Intensive Care'],
                publisher: {
                    contactPoint: 'phs.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'PUBLIC HEALTH SCOTLAND',
                },
                title: 'SICSAG Daily (Scottish Intensive Care Audit Group)',
            },
        },
        {
            id: '361045',
            summary: {
                abstract:
                    'The Scottish Intensive Care Society Audit Group (SICSAG) has maintained a national database of patients admitted to adult general Intensive Care Units (ICU) in Scotland since 1995. Each line relates to an ‘episode of care’ in critical care',
                contactPoint: 'phs.edris@nhs.net',
                keywords: ['SICSAG', 'NCS', 'Scotland', 'Intensive Care'],
                publisher: {
                    contactPoint: 'phs.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'PUBLIC HEALTH SCOTLAND',
                },
                title: 'SICSAG Episodes (Scottish Intensive Care Audit Group)',
            },
        },
        {
            id: '382378',
            summary: {
                abstract:
                    'The School Workforce Annual Census (SWAC) is an electronic collection of individual level data on the school workforce in local authority maintained settings in Wales. The first collection was introduced in 2019 and collects information at Nov, yearly.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['SAIL', 'School Workforce', 'Education', 'Teacher', 'School'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'School Workforce Annual Census (SWAC)',
            },
        },
        {
            id: '312795',
            summary: {
                abstract:
                    "The Scottish Birth Record (SBR) records baby's neonatal care in Scotland, from antenatal through to post delivery, including readmissions and transfers in one electronic record. SBR is based on individuals and events rather than episodes and is completed f",
                contactPoint: 'phs.edris@phs.scot',
                keywords: ['NHS Scotland', 'Neonatal Care', 'Births'],
                publisher: {
                    contactPoint: 'phs.edris@phs.scot',
                    memberOf: 'ALLIANCE',
                    name: 'PUBLIC HEALTH SCOTLAND',
                },
                title: 'Scottish Birth Record (SBR)',
            },
        },
        {
            id: '419288',
            summary: {
                abstract:
                    'The Secondary Uses Service (SUS +) is a collection of health care data required by hospitals and used for planning health care, supporting payments, commissioning policy development and research.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'DIGITRIALS',
                    'SUS',
                    'NCS',
                    'SUS+',
                    'PbR',
                    'NATIONAL TARIFF',
                    'CDS',
                    'COMMISSIONING DATA SETS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Secondary Uses Services Payment By Results',
            },
        },
        {
            id: '263213',
            summary: {
                abstract:
                    'The Substance Misuse Data Set captures data relating to all individuals (clients), both young persons and adults, presenting for substance misuse treatment in Wales.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Misuse', 'Substance', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Substance Misuse Dataset',
            },
        },
        {
            id: '338954',
            summary: {
                abstract: 'This is a test.',
                contactPoint: 'test@metadataworks.co.uk',
                doiName: 'test.123.test.123.test',
                keywords: ['test', 'QA'],
                publisher: {
                    contactPoint: 'qa@metadataworks.co.uk',
                    memberOf: 'HUB',
                    name: 'TEST',
                },
                title: 'Test Dataset 1',
            },
        },
        {
            id: '402473',
            summary: {
                abstract: 'This is a test dataset.',
                contactPoint: 'test@metadataworks.co.uk',
                doiName: 'test.123.test.123.test',
                keywords: ['test', 'QA'],
                publisher: {
                    contactPoint: 'qa@metadataworks.co.uk',
                    memberOf: 'OTHER',
                    name: 'TEST',
                },
                title: 'Test Dataset 7',
            },
        },
        {
            id: '408973',
            summary: {
                abstract:
                    'Hospital patients admitted during the COVID pandemic with a focus on COPD. Granular condition,  multi-morbidity. Serial physiology, blood biomarkers, treatments, interventions, ITU spells, outcome, pre/post admission healthcare use. Deeply phenotyped.',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'COPD',
                    'chronic bronchitis',
                    'emphysema',
                    'nhs',
                    'acute',
                    'exacerbation',
                    'COVID',
                    'pneumonia',
                    'pneumonitis',
                    'NEWS2',
                    'deterioration',
                    'alert ',
                    'SEWS',
                    'nebuliser',
                    'prednisolone',
                    'steroids',
                    'corticosteroids',
                    'oxygen',
                    'non-invasive ventilation',
                    'patient',
                    'acute hospitals',
                    'inpatient',
                    'ethnicity',
                    'multi-morbidity ',
                    'blood ',
                    'biomarkers',
                    'physiology',
                    'demographics',
                    'treatments',
                    'therapies',
                    'interventions',
                    'outcomes',
                    'death',
                    'longitudinal',
                    'vital signs',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'The impact of COVID on hospitalised patients with COPD; a dataset in OMOP',
            },
        },
        {
            id: '394495',
            summary: {
                abstract:
                    'A deeply phenotyped dataset of hospitalised COVID-19 patients in Birmingham; including granular ethnicity and multi-morbidity data confirmed in primary care; physiology, blood biomarkers, treatments, interventions, ITU admissions and outcomes.',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'COVID',
                    'Ethnicity',
                    'Covid-19',
                    'Co-Morbidity',
                    'Co-Morbidities',
                    'Coronavirus',
                    'UHB',
                    'Birmingham',
                    'long term medical conditions',
                    'physiology',
                    'age',
                    'sex',
                    'vital signs',
                    'biomarkers',
                    'd-dimer',
                    'troponin',
                    'CRP',
                    'symptoms',
                    'severity',
                    'chest radiographs',
                    'pulmonary infiltrates',
                    'consolidation',
                    'computor tomography',
                    'outcomes',
                    'intensive care',
                    'ventilated',
                    'do not resusictate orders',
                    'primary care records',
                    'secondary care records',
                    'high flow nasal oxygen',
                    'treatments',
                    'antibiotics',
                    'secondary infections',
                    'outcomes',
                    'death',
                    'discharge',
                    'length of stay',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'The impact of ethnicity and multi-morbidity on C19 hospitalised outcomes',
            },
        },
        {
            id: '409365',
            summary: {
                abstract:
                    'Society of Acute Medicine Audit data. Hospital patients. Granular care pathways against national guidelines. Severity, demographics, multi-morbidity, completion of review, interventions and treatments, outcomes. Patient flow through hospital. UHBFT',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'NHS',
                    'acute',
                    'AMU',
                    'NEWS2',
                    'deterioration',
                    'alert',
                    'SEWS',
                    'patient',
                    'acute hospitals',
                    'inpatient',
                    'ethnicity',
                    'multi-morbidity',
                    'blood',
                    'biomarkers',
                    'pregnancy',
                    'care escalation',
                    'physiology',
                    'demographics',
                    'treatments',
                    'therapies',
                    'interventions',
                    'outcomes',
                    'death',
                    'clinical speciality',
                    'medical review',
                    'observations',
                    'place of review',
                    'frailty',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'UHB 2019 Summer Society of Acute Medicine Benchmarking Audit',
            },
        },
        {
            id: '409366',
            summary: {
                abstract:
                    'Society of Acute Medicine Audit data. Hospital patients admitted during the COVID pandemic. Granular care pathways for reconfigured services. Severity, demographics, multi-morbidity, interventions and treatments, outcomes.  Flow through hospital. UHBFT.',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'NHS',
                    'Acute',
                    'AMU',
                    'NEWS2',
                    'deterioration',
                    'alert',
                    'SEWS',
                    'COVID',
                    'Patient',
                    'acute hospitals',
                    'inpatient',
                    'ethnicity',
                    'multi-morbidity',
                    'blood',
                    'biomarkers',
                    'pregnancy',
                    'care escalation',
                    'physiology',
                    'demographics',
                    'treatments',
                    'therapies',
                    'interventions',
                    'outcomes',
                    'death ',
                    'clinical speciality',
                    'medical review',
                    'observations',
                    'place of review',
                    'frailty',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'UHB 2020 Winter Society of Acute Medicine Benchmarking Audit',
            },
        },
        {
            id: '344264',
            summary: {
                abstract:
                    'The UK Cystic Fibrosis Registry is a national, secure, centralized database sponsored and managed by the Cystic Fibrosis Trust, with UK National Health Service (NHS) research ethics approval and consent from each person for whom data are collected.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['SAIL', 'Cystic Fibrosis', 'genotype'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'UK Cystic Fibrosis Registry (CYFI)',
            },
        },
        {
            id: '409169',
            summary: {
                abstract:
                    'Hospital patients admitted during the COVID pandemic requiring/at risk of requiring ventilatory support.  Granular condition,  multi-morbidity, interventions and treatments.  Serial physiology, blood biomarkers, ITU spells, outcome. Deeply phenotyped.',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'NHS',
                    'acute',
                    'exacerbation',
                    'COVID',
                    'pneumonia',
                    'pneumonitis',
                    'NEWS2',
                    'deterioration',
                    'alert',
                    'SEWS',
                    'oxygen',
                    'non-invasive ventilation',
                    ' high flow oxygen',
                    'CPAP',
                    'airway pressure',
                    'inspiratory pressure',
                    'tidal volume',
                    'patient',
                    'acute hospitals',
                    'inpatient',
                    'ethnicity',
                    'multi-morbidity',
                    'blood',
                    'biomarkers',
                    'physiology',
                    'demographics',
                    'treatments',
                    'therapies',
                    'interventions',
                    'outcomes',
                    'death',
                    'longitudinal',
                    'vital signs',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'Ventilatory strategies and outcomes for patients with COVID: a dataset in OMOP',
            },
        },
        {
            id: '266974',
            summary: {
                abstract:
                    'The Welsh Ambulance Services NHS Trust data covers data pertaining to more than 250,000 emergency calls a year, over 50,000 urgent calls and transport over 1.3 million non-emergency patients to over 200 treatment centres throughout England and Wales.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Emergency', 'Ambulance', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Welsh Ambulance Services NHS Trust',
            },
        },
        {
            id: '260075',
            summary: {
                abstract:
                    'The Welsh Cancer Intelligence & Surveillance Unit (WCISU) is the National Cancer Registry for Wales and its primary role is to record, store and report on all incidence of cancer for the resident population of Wales wherever they are treated.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['CANCER', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Welsh Cancer Intelligence and Surveillance Unit (WCISU)',
            },
        },
        {
            id: '259783',
            summary: {
                abstract:
                    'Register of all individuals registered with a Welsh GP, includes individuals anonymised address and practice history.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Welsh', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Welsh Demographic Service (WDS)',
            },
        },
        {
            id: '399068',
            summary: {
                abstract:
                    "The Welsh Dispensing Dataset covers prescriptions that are prescribed in Wales by GP's (general medical practitioners) and non medical prescribers that have prescribed on behalf of the GP practice, that are then dispensed in the community within Wales.",
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Prescriptions', 'Dispensing', 'Prescribing', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Welsh Dispensing Dataset',
            },
        },
    ],
    currentPage: 5,
    filters: [
        {
            description: 'Publisher',
            name: 'name',
            options: [
                'DATA-CAN',
                'DISCOVER NOW',
                'GENOMICS ENGLAND',
                'HEALTH DATA RESEARCH UK',
                'ISARIC 4C',
                'NHS DIGITAL',
                'NHS DIGITRIALS',
                'Office for National Statistics',
                'PIONEER',
                'PIONEER - HDR-UK Health data Hub in Acute Care',
                'PUBLIC HEALTH SCOTLAND',
                'SAIL',
                'SCOTLAND',
                'TEST',
                'University of Nottingham',
            ],
        },
        {
            description: 'Data Use Limitation',
            name: 'dataUseLimitation',
            options: [
                'GENERAL RESEARCH USE',
                'GENETIC STUDIES ONLY',
                'NO GENERAL METHODS RESEARCH',
                'NO RESTRICTION',
                'RESEARCH SPECIFIC RESTRICTIONS',
                'RESEARCH USE ONLY',
            ],
        },
        {
            description: 'Data Use Requirements',
            name: 'dataUseRequirements',
            options: [
                '',
                'COLLABORATION REQUIRED',
                'ETHICS APPROVAL REQUIRED',
                'GEOGRAPHICAL RESTRICTIONS',
                'INSTITUTION SPECIFIC RESTRICTIONS',
                'NOT FOR PROFIT USE',
                'PROJECT SPECIFIC RESTRICTIONS',
                'PUBLICATION MORATORIUM',
                'PUBLICATION REQUIRED',
                'RETURN TO DATABASE OR RESOURCE',
                'TIME LIMIT ON USE',
                'USER SPECIFIC RESTRICTION',
            ],
        },
        {
            description: 'Keywords',
            name: 'keywords',
            options: [
                'A&E',
                'A&E Department',
                'A&E Waiting Time',
                'Accident',
                'Accident and Emergency',
                'ACS',
                'Active',
                'Acuity',
                'Acuity Score',
                'Acute',
                'Acute hospitals',
                'Admission',
                'Admitted',
                'Admitted Patient Care',
                'Adult Social Care',
                'Age',
                'Airway pressure',
                'Alert',
                'Ambulance',
                'AMU',
                'Antibiotics',
                'Antigen',
                'APC',
                'Appointment',
                'Arrival Method',
                'Assuring Transformation',
                'AT',
                'Attendance',
                'Attendance Type',
                'Audit',
                'AVPU',
                'Babies',
                'Baby',
                'Bacterial',
                'Biomarkers',
                'Birmingham',
                'Birth',
                'Births',
                'Blood',
                'Blood biomarkers',
                'Blood-poisoning',
                'Bowel',
                'Breast',
                'Breathing',
                'Brecon',
                'Business',
                'CAFE',
                'CAFW',
                'CAMHS',
                'Cancellation',
                'CANCER',
                'CARE',
                'Care escalation',
                'Careers Wales',
                'CARW',
                'CAT',
                'CDDS',
                'CDS',
                'Cellulitis',
                'Census',
                'Central nervous system',
                'Cervical',
                'Chest radiographs',
                'Child and Adolescent Mental Health Services',
                'Child Health',
                'Childbirth',
                'Children',
                'Chronic bronchitis',
                'Circulation',
                'Clinical speciality',
                'CMC',
                'CO-CIN',
                'CO-CONNECT',
                'Co-Morbidities',
                'Co-Morbidity',
                'COMMISSIONING DATA SETS',
                'Community',
                'Computer tomography',
                'Computerized axial tomography',
                'Computor tomography',
                'Cone beam computed tomography',
                'Congenital Anomaly',
                'Consolidation',
                'Contacts',
                'COPD',
                'CORONAVIRUS',
                'Coronovirus',
                'Corticosteroids',
                'Covid',
                'COVID-19',
                'COVID-19 Hospitalisation in England Surveillance System',
                'COVID19',
                'CPAP',
                'Critical Care',
                'Critical Care Period',
                'Critical Care Records',
                'CRP',
                'Cystic Fibrosis',
                'D-dimer',
                'Daily',
                'Data',
                'DATA-CAN',
                'Dataset',
                'Day Case',
                'Day cases',
                'Death',
                'Deaths',
                'Deceased',
                'Demographic',
                'Demographics',
                'Demography',
                'Deterioration',
                'Diabetes',
                'Diagnosis',
                'Diagnostic',
                'Diagnostic Image',
                'Diagnostic ultrasonography',
                'Did Not Attend',
                'DIGITRIALS',
                'Disabilities',
                'Discharge',
                'DISPENSING',
                'DNA',
                'Do not resuscitate orders',
                'Do not resusictate orders',
                'DSRD',
                'ECDS',
                'Economic activity',
                'Education',
                'Elective',
                'Emergency',
                'Emphysema',
                'Employment',
                'Endocarditis',
                'England',
                'England Deaths',
                'English Deaths',
                'Episode',
                'Episodes',
                'Ethnicity',
                'Exacerbation',
                'Exercise',
                'External Cause',
                'FAE',
                'FCE',
                'Finished Admission Episode',
                'Finished Consultant Episode',
                'Fluoroscopy',
                'Frailty',
                'Fungal',
                'GDPPR',
                'General Practice',
                'Genome',
                'Genomics',
                'Genotype',
                'GP',
                'GP DATA',
                'GP Registrations',
                'Graduates',
                'GRO',
                'HCD',
                'HDR UK',
                'HDU',
                'Health',
                'Healthcare workers',
                'Heart',
                'Hepatobiliary',
                'HES',
                'High Cost Drugs',
                'High Dependency',
                'High Dependency Unit',
                'High flow nasal oxygen',
                'High flow oxygen',
                'Higher Education',
                'Hospital',
                'Hospital Activity',
                'Hospital episode statistics',
                'HOSPITALISATION',
                'Household',
                'ICCD',
                'ICNARC',
                'ICU',
                'Immune system',
                'Indicators',
                'Infection',
                'Initial Assessment',
                'Inpatient',
                'Inpatients',
                'Inspiratory pressure',
                'Insulin',
                'Integrated Care Record',
                'Intensive Care',
                'Intensive Care Unit',
                'Interventions',
                'IP',
                'ISARIC 4C',
                'Key Worker',
                'Kidney',
                'LABORATORY',
                'LD',
                'Learning Disabilities',
                'Length of stay',
                'Level 2 Critical Care Bed',
                'Level 3 Critical Care Bed',
                'Life-threatening',
                'LINKABLE',
                'Linked',
                'Liver',
                'Long term medical conditions',
                'Longitudinal',
                'Magnetic resonance imaging',
                'Main Speciality',
                'Major Injuries',
                'Maternity',
                'Medical photography',
                'Medical review',
                'MEDICINES',
                'Mental Health',
                'MH',
                'MHLDDS',
                'MHMDS',
                'Microbiology',
                'Minor Injuries',
                'Misuse',
                'MOBILE APP',
                'MOF',
                'Mortality',
                'Mother',
                'MRI',
                'Multi-morbidity',
                'NATIONAL CONNECTIVITY STUDY',
                'NATIONAL CORE STUDY',
                'National Interim Clinical Imaging Procedure Code Set',
                'National Survey',
                'NATIONAL TARIFF',
                'NCS',
                'Nebuliser',
                'Neonatal Care',
                'NEWS2',
                'NHAIS',
                'NHS',
                'NHS Central Register',
                'NHS Scotland',
                'NHS Scotland Outpatients',
                'NHSCR',
                'NICIP',
                'NM',
                'Non-invasive ventilation',
                'NORTHERN IRELAND',
                'Nuclear medicine',
                'Observations',
                'Obstetrics',
                'ONS',
                'OP',
                'Operations',
                'Ordinary',
                'Organ failure',
                'Organ Support',
                'Osteomyelitus',
                'OUTCOME',
                'Outcomes',
                'Outpatient',
                'Outpatient Care',
                'Outpatients',
                'Oxygen',
                'PACS',
                'Paediatric',
                'Papers',
                'Pathology',
                'Patient',
                'Patient Level Data',
                'PbR',
                'PCP',
                'PDS',
                'Pediatric Care',
                'PEDW',
                'PET scan',
                'Physiology',
                'Picture archiving and communication system',
                'PILLAR 1',
                'PILLAR 2',
                'PILLAR 3',
                'Place of review',
                'Plain radiography',
                'Planned',
                'PLD',
                'Pneumonia',
                'Pneumonitis',
                'Population',
                'Positron emission tomography',
                'Postponed',
                'Prednisolone',
                'Pregnancy',
                'Preprints',
                'Prescribing',
                'Prescriptions',
                'Primary care',
                'Primary Care Events',
                'Primary care prescription',
                'Primary Care Prescriptions',
                'Primary care records',
                'Procedure',
                'Procedures',
                'Public Health England',
                'Pulmonary infiltrates',
                'PUPIL',
                'QA',
                'Radiology',
                'Radiology information system',
                'Rare disease',
                'Re-attendance',
                'Referral',
                'Referral to treatment',
                'Registered Deaths',
                'Religion',
                'Resident Population',
                'Respiratory tract',
                'RIS',
                'Risk',
                'Risk Assessments',
                'SAIL',
                'Scan',
                'School',
                'School Workforce',
                'Scotland',
                'Screening',
                'Secondary care records',
                'Secondary infections',
                'Sepsis',
                'Septicaemia',
                'Sequencing',
                'Serial readings',
                'Serology',
                'Severity',
                'SEWS',
                'Sex',
                'SGSS',
                'Shielded',
                'SICSAG',
                'Single photon emission computerized tomography',
                'SITREP',
                'SNOMED',
                'SNOMED CT',
                'SPECT',
                'Spell',
                'Spine',
                'Steroids',
                'Students',
                'Substance',
                'SUS',
                'SUS+',
                'SYMPTOM',
                'Symptoms',
                'Systematized Nomenclature of Medicine Clinical Terms',
                'Teacher',
                'Test',
                'Testing',
                'Therapies',
                'Therapy',
                'Threshold',
                'Tidal volume',
                'Time in A&E',
                'TRACKER',
                'TREATMENT',
                'Treatment Speciality',
                'Treatments',
                'Troponin',
                'Type 1',
                'Type 2',
                'UEC',
                'UHB',
                'Ultrasound',
                'Unplanned Investigation',
                'URGENT AND EMERGENCY CARE',
                'Urgent Care',
                'Urinary tract',
                'US',
                'Ventilated',
                'Viral',
                'Vital signs',
                'Vital-signs',
                'Waiting List',
                'Waiting Times',
                'Wales',
                'Wales Deaths',
                'Wave 1',
                'Wave 2',
                'Welsh',
                'Welsh Deaths',
                'X-Ray',
            ],
        },
        {
            description: 'Physical Sample Availability',
            name: 'physicalSampleAvailability',
            options: [
                'AVAILABILITY TO BE CONFIRMED',
                'BONE MARROW',
                'DNA',
                'FAECES',
                'NOT AVAILABLE',
                'PLASMA',
                'SALIVA',
                'SERUM',
                'TISSUE',
                'URINE',
                'WHOLE BLOOD',
            ],
        },
        {
            description: 'Geographic Coverage',
            name: 'spatial',
            options: [
                'England',
                'England & Wales',
                'England and Wales',
                'England, UK',
                'England, Wales and Isle of Man',
                'England,UK',
                'GB',
                'Great Britain',
                'Https://www.geonames.org/2635167/united-kingdom-of-great-britain-and-northern-ireland.html',
                'Https://www.geonames.org/2638360/scotland.html',
                'NHS Brent CCG; NHS Central London CCG; NHS Ealing CCG; NHS Hammersmith & Fulham CCG; NHS Harrow CCG; NHS Hillingdon CCG; NHS Hounslow CCG; NHS West London CCG',
                'NHS Brent CCG; NHS Central London CCG; NHS Ealing CCG; NHS Hammersmith &Fulham CCG; NHS Harrow CCG; NHS Hillingdon CCG; NHS Hounslow CCG; NHS West London CCG',
                'Nottinghamshire',
                'Scotland',
                'The General / Acute Inpatient and Day Case data covers all residents in Scotland that receive care in hospital and general acute specialities.',
                'The Outpatient Attendance dataset covers all people offered a new or follow up outpatient appointment at a Scottish NHS hospital.',
                'UK',
                'United Kingdom',
                'Wales',
                'Wales, where patient GP Practice has agreed data sharing with SAIL',
                'West Midlands',
                'West Midlands Region, UK',
            ],
        },
    ],
    pageCount: 19,
    pageSize: 25,
    totalCount: 119,
    totalPages: 5,
};
export const page2andSize25PaginationResult = {
    content: [
        {
            id: '337250',
            summary: {
                abstract:
                    'Data forming the Covid-19 Second Generation Surveillance Systems data set relate to demographic and diagnostic information from Pillar 1 swab testing in PHE labs and NHS hospitals and Pillar 2 Swab testing in the community.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'DIGITRIALS',
                    'SGSS',
                    'NCS',
                    'PILLAR 1',
                    'PILLAR 2',
                    'PILLAR 3',
                    'ENGLAND',
                    'NATIONAL CORE STUDY',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Covid-19 Second Generation Surveillance System',
            },
        },
        {
            id: '420121',
            summary: {
                abstract:
                    'The Covid-19 UK Non-hospital Antibody Testing Results (Pillar 3) dataset, also referred to as iElisa, documents individuals that have undergone a finger prick test for antibodies from having had Covid-19.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'DIGITRIALS',
                    'PILLAR 3',
                    'SCOTLAND',
                    'ENGLAND',
                    'NORTHERN IRELAND',
                    'WALES',
                    'NCS ',
                    'NATIONAL CORE STUDY',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Covid-19 UK Non-hospital Antibody Testing Results',
            },
        },
        {
            id: '337819',
            summary: {
                abstract:
                    'COVID-19 UK Non-hospital Antigen Testing Results (Pillar 2) data is required by NHS Digital to support COVID-19 requests for linkage, analysis and dissemination.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'DIGITRIALS',
                    'Pillar 2',
                    'NCS',
                    'Key Worker',
                    'Scotland',
                    'England',
                    'Wales',
                    'Northern Ireland',
                    'National Core Study',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Covid-19 UK Non-hospital Antigen Testing Results',
            },
        },
        {
            id: '259178',
            summary: {
                abstract:
                    'Contains a subset of mandatory items for the generation of Critical Care Healthcare Resource Groups (HRGs).',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Critical Care', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Critical Care Dataset',
            },
        },
        {
            id: '267326',
            summary: {
                abstract:
                    'Daily Situation Report dataset.\r\n\r\nDaily situation report for healthcare equipment, staff, activity, capacity and usage.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Covid-19', 'DSRD', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Daily Situation Report Data',
            },
        },
        {
            id: '347745',
            summary: {
                abstract:
                    'Monthly death registration data, collected as part of civil registration, provide information on deaths that occur in and are then registered in England and Wales.',
                contactPoint: 'research.support@ons.gov.uk',
                keywords: ['COVID-19', 'mortality', 'health', 'NCS'],
                publisher: {
                    contactPoint: 'research.support@ons.gov.uk',
                    memberOf: 'OTHER',
                    name: 'Office for National Statistics',
                },
                title: 'Death registration data - Provisional Monthly Extracts',
            },
        },
        {
            id: '382048',
            summary: {
                abstract:
                    'Hospitalised sepsis patients (2000-2020) by cause. Deeply phenotyped, longitudinal.  Granular ethnicity and multi-morbidity.  Serial acuity, physiology, blood parameters, treatments, interventions, ITU spells, outcomes, pre and post sepsis healthcare.',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'Sepsis',
                    'Infection',
                    'NHS',
                    'Acute',
                    'immune system',
                    'life-threatening',
                    'septicaemia',
                    'blood-poisoning',
                    'MOF',
                    'organ failure',
                    'respiratory tract',
                    'pneumonia',
                    'urinary tract',
                    'hepatobiliary',
                    'central nervous system',
                    'cellulitis',
                    'osteomyelitus',
                    'endocarditis',
                    'viral',
                    'bacterial',
                    'fungal',
                    'blood biomarkers',
                    'microbiology',
                    'treatments',
                    'antibiotics',
                    'physiology',
                    'serial readings',
                    'outcomes',
                    'intensive care',
                    'death',
                    'discharge',
                    'length of stay',
                    'multi-morbidity',
                    'COVID-19',
                    'wave 1',
                    'wave 2',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER - HDR-UK Health data Hub in Acute Care',
                },
                title: 'Deeply phenotyped sepsis patients within hospital: onset, treatments & outcomes',
            },
        },
        {
            id: '360811',
            summary: {
                abstract:
                    'Hospital COVID patients (6th Jan 2020 onwards). Granular severity, ethnicity, co-morbidity.  Serial physiology, blood biomarkers, images, treatments, ITU, outcome, pre/post admission healthcare use. Deeply phenotyped, longitudinal. Waves 1/ 2',
                contactPoint: 'PioneerDIH@UHB.NHS.UK',
                keywords: [
                    'COVID',
                    'Covid-19',
                    'Acuity Score',
                    'NEWS2',
                    'SEWS',
                    'AVPU',
                    'Coronovirus',
                    'UHB',
                    'Birmingham',
                    'age',
                    'sex',
                    'ethnicity ',
                    'physiology',
                    'vital signs',
                    'biomarkers',
                    'd-dimer',
                    'troponin',
                    'CRP',
                    'sepsis',
                    'symptoms',
                    'chest radiographs',
                    'computer tomography',
                    'outcomes',
                    'intensive care',
                    'ventilated',
                    'do not resuscitate orders',
                    'primary care records',
                    'secondary care records',
                    'high flow nasal oxygen',
                    'treatments',
                    'antibiotics',
                    'secondary infections',
                    'outcomes',
                    'death',
                    'discharge',
                    'length of stay',
                ],
                publisher: {
                    contactPoint: 'PioneerDIH@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'Deeply-phenotyped hospital COVID patients: severity, acuity, therapies, outcomes',
            },
        },
        {
            id: '420897',
            summary: {
                abstract:
                    'The Diagnostic Imaging Data Set (DID) collects data about diagnostic imaging tests carried out on NHS patients, extracted from local radiology information systems.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Scan',
                    'Radiology',
                    'Diagnostic Image',
                    'X-Ray',
                    'Plain radiography',
                    'CAT',
                    'Computerized axial tomography',
                    'Fluoroscopy',
                    'MRI',
                    'Magnetic resonance imaging',
                    'NM',
                    'Nuclear medicine',
                    'US',
                    'Diagnostic ultrasonography',
                    'Ultrasound',
                    'Medical photography',
                    'PET scan',
                    'Positron emission tomography',
                    'SPECT',
                    'Single photon emission computerized tomography',
                    'Cone beam computed tomography',
                    'SNOMED',
                    'SNOMED CT',
                    'Systematized Nomenclature of Medicine Clinical Terms',
                    'NICIP',
                    'National Interim Clinical Imaging Procedure Code Set',
                    'RIS',
                    'radiology information system ',
                    'PACS',
                    'picture archiving and communication system',
                    'DIGITRIALS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Diagnostic Imaging Dataset',
            },
        },
        {
            id: '258855',
            summary: {
                abstract: 'Waiting times for diagnostic scans and waiting times for access to therapy services.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Diagnostic', 'Therapy', 'Waiting Times', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Diagnostic and Therapy Services Waiting Times',
            },
        },
        {
            id: '337903',
            summary: {
                abstract:
                    'Pillar 1 & 2 COVID antigen testing data.  Electronic Communication of Surveillance in Scotland (ECOSS): Holds all positive microbiology laboratory specimen results and a subset of antimicrobial susceptibility/resistance data in Scotland.',
                contactPoint: 'phs.edris@nhs.net',
                keywords: ['COVID', 'Testing', 'Scotland', 'antigen'],
                publisher: {
                    contactPoint: 'phs.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'SCOTLAND',
                },
                title: 'ECOSS (-/+VE tested patients only)',
            },
        },
        {
            id: '264389',
            summary: {
                abstract: 'Schools and Pupil data for Wales.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['PUPIL', 'SAIL', 'LINKABLE', 'SCHOOL', 'EDUCATION'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Education - Wales',
            },
        },
        {
            id: '342260',
            summary: {
                abstract:
                    'The Emergency Care Data Set (ECDS) is the national data set for urgent and emergency care. It replaced Accident & Emergency Commissioning Data Set (CDS type 010) and was implemented through: ECDS (CDS 6.2.2 Type 011).',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'DIGITRIALS',
                    'A&E',
                    'ACCIDENT AND EMERGENCY',
                    'ECDS',
                    'NCS',
                    'NATIONAL CORE STUDY',
                    'URGENT AND EMERGENCY CARE',
                    'UEC',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Emergency Care Data Set (ECDS)',
            },
        },
        {
            id: '260208',
            summary: {
                abstract: 'Attendance and clinical information for all Accident and Emergency attendances.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Emergency', 'A&E', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Emergency Department Data Set',
            },
        },
        {
            id: '264053',
            summary: {
                abstract: 'Daily version of Emergency Department Dataset.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Emergency', 'A&E', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Emergency Department Dataset Daily',
            },
        },
        {
            id: '180228',
            summary: {
                abstract:
                    'Contains tables with data related to genomic data and the outputs from the GEL interpretation pipeline data for participants from both cancer and rare disease programmes. These tables do not directly include primary + secondary sources of clinical data.',
                contactPoint: 'gecip-help@genomicsengland.co.uk',
                doiName: 'Not Available',
                keywords: ['sequencing', 'genomics', 'data', 'DNA', 'DATA-CAN'],
                publisher: {
                    contactPoint: 'gecip-help@genomicsengland.co.uk',
                    memberOf: 'ALLIANCE',
                    name: 'GENOMICS ENGLAND',
                },
                title: 'GENOMICS ENGLAND 100K BIOINFORMATICS DATA',
            },
        },
        {
            id: '240982',
            summary: {
                abstract:
                    'Cancer data are presented for either the patient level cancer diagnosis or “disease type” or the tumour specific sample details of participants in the Cancer arm of the 100,000 Genomes Project.',
                contactPoint: 'gecip-help@genomicsengland.co.uk',
                doiName: 'Not Available',
                keywords: ['cancer', 'genomics', 'genome', 'England', 'DNA', 'sequencing', 'data', 'DATA-CAN'],
                publisher: {
                    contactPoint: 'gecip-help@genomicsengland.co.uk',
                    memberOf: 'ALLIANCE',
                    name: 'GENOMICS ENGLAND',
                },
                title: 'GENOMICS ENGLAND 100K CANCER & COMMON',
            },
        },
        {
            id: '241001',
            summary: {
                abstract:
                    'NHS national data sets collect information from care records, systems and organisations on specific areas of health and care.',
                contactPoint: 'gecip-help@genomicsengland.co.uk',
                doiName: 'Not Available',
                keywords: ['genome', 'genomics', 'data', 'Hospital episode statistics', 'DNA', 'DATA-CAN'],
                publisher: {
                    contactPoint: 'gecip-help@genomicsengland.co.uk',
                    memberOf: 'ALLIANCE',
                    name: 'GENOMICS ENGLAND',
                },
                title: 'GENOMICS ENGLAND 100K NHSD LINKED DATA',
            },
        },
        {
            id: '242598',
            summary: {
                abstract:
                    'This dataset brings together data from more than 500 local and regional datasets to build a picture of an individual’s treatment from diagnosis. Available for patients diagnosed with Cancer (ICD10 C00-97, D00-48) from 1 January 1995 -31 December 2017.',
                contactPoint: 'gecip-help@genomicsengland.co.uk',
                doiName: 'Not Available',
                keywords: ['genome', 'genomics', 'data', 'DNA', 'Public Health England', 'DATA-CAN'],
                publisher: {
                    contactPoint: 'gecip-help@genomicsengland.co.uk',
                    memberOf: 'ALLIANCE',
                    name: 'GENOMICS ENGLAND',
                },
                title: 'GENOMICS ENGLAND 100K PHE LINKED DATA',
            },
        },
        {
            id: '242481',
            summary: {
                abstract: 'Data views that bring together data from several LabKey tables for convenient access',
                contactPoint: 'gecip-help@genomicsengland.co.uk',
                doiName: 'Not Available',
                keywords: ['cancer', 'rare disease', 'genome', 'genomics', 'DNA', 'data', 'DATA-CAN'],
                publisher: {
                    contactPoint: 'gecip-help@genomicsengland.co.uk',
                    memberOf: 'ALLIANCE',
                    name: 'GENOMICS ENGLAND',
                },
                title: 'GENOMICS ENGLAND 100K QUICK VIEW',
            },
        },
        {
            id: '240105',
            summary: {
                abstract:
                    'Rare Disease (RD) data are presented at the level of RD families, RD pedigrees, and participants. Participants are consenting individuals who have had their genome sequenced. Pedigree members are extended members of the proband’s family.',
                contactPoint: 'gecip-help@genomicsengland.co.uk',
                doiName: 'Not Available',
                keywords: ['rare disease', 'genome', 'genomics', 'data', 'DNA', 'DATA-CAN'],
                publisher: {
                    contactPoint: 'gecip-help@genomicsengland.co.uk',
                    memberOf: 'ALLIANCE',
                    name: 'GENOMICS ENGLAND',
                },
                title: 'GENOMICS ENGLAND 100K RARE DISEASE & COMMON',
            },
        },
        {
            id: '339238',
            summary: {
                abstract:
                    'Attendance and clinical information for all general practice interactions: includes patients symptoms, investigations, diagnoses, prescribed medication and referrals to tertiary care.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'Not Available',
                keywords: ['Primary care', 'GP', 'SAIL', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'GP dataset - Welsh Primary Care',
            },
        },
        {
            id: '394343',
            summary: {
                abstract:
                    'NHS Digital’s fortnightly collection of GP data will provide data to support vital planning and research into coronavirus (COVID-19).',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: ['DIGITRIALS', 'GDPPR', 'NCS', 'GP DATA'],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'GPES Data for Pandemic Planning and Research (COVID-19)',
            },
        },
        {
            id: '338206',
            summary: {
                abstract:
                    'The General / Acute and Inpatient Day Case dataset (SMR01) collects episode level data on hospital inpatient and day case discharges from acute specialities from hospitals in Scotland.',
                contactPoint: 'nss.edris@nhs.net',
                keywords: ['Inpatients ', 'NCS', 'Day cases', 'Scotland'],
                publisher: {
                    contactPoint: 'nss.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'SCOTLAND',
                },
                title: 'General Acute Inpatient and Day Case - Scottish Morbidity Record (SMR01)',
            },
        },
        {
            id: '263839',
            summary: {
                abstract:
                    'Publications that mention HDR-UK (or any variant thereof) in Acknowledgements or Author Affiliations',
                contactPoint: 'susheel.varma@hdruk.ac.uk',
                doiName: 'https://doi.org/10.5281/zenodo.326615',
                keywords: ['Preprints', 'Papers', 'HDR UK'],
                publisher: {
                    contactPoint: 'susheel.varma@hdruk.ac.uk',
                    memberOf: 'OTHER',
                    name: 'HEALTH DATA RESEARCH UK',
                },
                title: 'HDR UK Papers & Preprints',
            },
        },
    ],
    currentPage: 2,
    filters: [
        {
            description: 'Publisher',
            name: 'name',
            options: [
                'DATA-CAN',
                'DISCOVER NOW',
                'GENOMICS ENGLAND',
                'HEALTH DATA RESEARCH UK',
                'ISARIC 4C',
                'NHS DIGITAL',
                'NHS DIGITRIALS',
                'NHSX',
                'Office for National Statistics',
                'PIONEER',
                'PIONEER - HDR-UK Health data Hub in Acute Care',
                'PUBLIC HEALTH SCOTLAND',
                'SAIL',
                'SCOTLAND',
                'TEST',
                'University of Nottingham',
            ],
        },
        {
            description: 'Data Use Limitation',
            name: 'dataUseLimitation',
            options: [
                'GENERAL RESEARCH USE',
                'GENETIC STUDIES ONLY',
                'NO GENERAL METHODS RESEARCH',
                'NO RESTRICTION',
                'RESEARCH SPECIFIC RESTRICTIONS',
                'RESEARCH USE ONLY',
            ],
        },
        {
            description: 'Data Use Requirements',
            name: 'dataUseRequirements',
            options: [
                '',
                'COLLABORATION REQUIRED',
                'ETHICS APPROVAL REQUIRED',
                'GEOGRAPHICAL RESTRICTIONS',
                'INSTITUTION SPECIFIC RESTRICTIONS',
                'NOT FOR PROFIT USE',
                'PROJECT SPECIFIC RESTRICTIONS',
                'PUBLICATION MORATORIUM',
                'PUBLICATION REQUIRED',
                'RETURN TO DATABASE OR RESOURCE',
                'TIME LIMIT ON USE',
                'USER SPECIFIC RESTRICTION',
            ],
        },
        {
            description: 'Keywords',
            name: 'keywords',
            options: [
                'A&E',
                'A&E Department',
                'A&E Waiting Time',
                'Accident',
                'Accident and Emergency',
                'ACS',
                'Active',
                'Acuity',
                'Acuity Score',
                'Acute',
                'Acute hospitals',
                'Admission',
                'Admitted',
                'Admitted Patient Care',
                'Adult Social Care',
                'Age',
                'Airway pressure',
                'Alert',
                'Ambulance',
                'AMU',
                'Antibiotics',
                'Antigen',
                'APC',
                'Appointment',
                'Arrival Method',
                'Assuring Transformation',
                'AT',
                'Attendance',
                'Attendance Type',
                'Audit',
                'AVPU',
                'Babies',
                'Baby',
                'Bacterial',
                'Biomarkers',
                'Birmingham',
                'Birth',
                'Births',
                'Blood',
                'Blood biomarkers',
                'Blood-poisoning',
                'Bowel',
                'Breast',
                'Breathing',
                'Brecon',
                'Business',
                'CAFE',
                'CAFW',
                'CAMHS',
                'Cancellation',
                'CANCER',
                'CARE',
                'Care escalation',
                'Careers Wales',
                'CARW',
                'CAT',
                'CDDS',
                'CDS',
                'Cellulitis',
                'Census',
                'Central nervous system',
                'Cervical',
                'Chest CT scan',
                'Chest MRI',
                'Chest radiographs',
                'Chest x-ray',
                'Child and Adolescent Mental Health Services',
                'Child Health',
                'Childbirth',
                'Children',
                'Chronic bronchitis',
                'Circulation',
                'Clinical speciality',
                'CMC',
                'CO-CIN',
                'CO-CONNECT',
                'Co-Morbidities',
                'Co-Morbidity',
                'COMMISSIONING DATA SETS',
                'Community',
                'Computer tomography',
                'Computerized axial tomography',
                'Computor tomography',
                'Cone beam computed tomography',
                'Congenital Anomaly',
                'Consolidation',
                'Contacts',
                'COPD',
                'CORONAVIRUS',
                'Coronovirus',
                'Corticosteroids',
                'Covid',
                'COVID-19',
                'COVID-19 Hospitalisation in England Surveillance System',
                'COVID19',
                'CPAP',
                'Critical Care',
                'Critical Care Period',
                'Critical Care Records',
                'CRP',
                'Cystic Fibrosis',
                'D-dimer',
                'Daily',
                'Data',
                'DATA-CAN',
                'Dataset',
                'Day Case',
                'Day cases',
                'Daycases',
                'Death',
                'Deaths',
                'Deceased',
                'Demographic',
                'Demographics',
                'Demography',
                'Deterioration',
                'Diabetes',
                'Diagnosis',
                'Diagnostic',
                'Diagnostic Image',
                'Diagnostic ultrasonography',
                'Did Not Attend',
                'DIGITRIALS',
                'Disabilities',
                'Discharge',
                'DISPENSING',
                'DNA',
                'Do not resuscitate orders',
                'Do not resusictate orders',
                'DSRD',
                'ECDS',
                'Economic activity',
                'Education',
                'Elective',
                'Emergency',
                'Emphysema',
                'Employment',
                'Endocarditis',
                'England',
                'England Deaths',
                'English Deaths',
                'Episode',
                'Episodes',
                'Ethnicity',
                'Exacerbation',
                'Exercise',
                'External Cause',
                'FAE',
                'FCE',
                'Finished Admission Episode',
                'Finished Consultant Episode',
                'Fluoroscopy',
                'Frailty',
                'Fungal',
                'GDPPR',
                'General Practice',
                'Genome',
                'Genomics',
                'Genotype',
                'GP',
                'GP DATA',
                'GP Registrations',
                'Graduates',
                'GRO',
                'HCD',
                'HDR UK',
                'HDU',
                'Health',
                'Healthcare workers',
                'Heart',
                'Hepatobiliary',
                'HES',
                'High Cost Drugs',
                'High Dependency',
                'High Dependency Unit',
                'High flow nasal oxygen',
                'High flow oxygen',
                'Higher Education',
                'Hospital',
                'Hospital Activity',
                'Hospital episode statistics',
                'HOSPITALISATION',
                'Household',
                'ICCD',
                'ICNARC',
                'ICU',
                'Imaging',
                'Immune system',
                'Indicators',
                'Infection',
                'Initial Assessment',
                'Inpatient',
                'Inpatients',
                'Inspiratory pressure',
                'Insulin',
                'Integrated Care Record',
                'Intensive Care',
                'Intensive Care Unit',
                'Interventions',
                'IP',
                'ISARIC 4C',
                'Key Worker',
                'Kidney',
                'LABORATORY',
                'LD',
                'Learning Disabilities',
                'Length of stay',
                'Level 2 Critical Care Bed',
                'Level 3 Critical Care Bed',
                'Life-threatening',
                'LINKABLE',
                'Linked',
                'Liver',
                'Long term medical conditions',
                'Longitudinal',
                'Magnetic resonance imaging',
                'Main Speciality',
                'Major Injuries',
                'Maternity',
                'Medical photography',
                'Medical review',
                'MEDICINES',
                'Mental Health',
                'MH',
                'MHLDDS',
                'MHMDS',
                'Microbiology',
                'Minor Injuries',
                'Misuse',
                'MOBILE APP',
                'MOF',
                'Mortality',
                'Mother',
                'MRI',
                'Multi-morbidity',
                'NATIONAL CONNECTIVITY STUDY',
                'NATIONAL CORE STUDY',
                'National Interim Clinical Imaging Procedure Code Set',
                'National Survey',
                'NATIONAL TARIFF',
                'NCCID',
                'NCS',
                'Nebuliser',
                'Neonatal Care',
                'NEWS2',
                'NHAIS',
                'NHS',
                'NHS Central Register',
                'NHS Scotland',
                'NHS Scotland Outpatients',
                'NHSCR',
                'NICIP',
                'NM',
                'Non-invasive ventilation',
                'NORTHERN IRELAND',
                'Nuclear medicine',
                'Observations',
                'Obstetrics',
                'ONS',
                'OP',
                'Operations',
                'Ordinary',
                'Organ failure',
                'Organ Support',
                'Osteomyelitus',
                'Out of Hours',
                'OUTCOME',
                'Outcomes',
                'Outpatient',
                'Outpatient Care',
                'Outpatients',
                'Oxygen',
                'PACS',
                'Paediatric',
                'Papers',
                'Pathology',
                'Patient',
                'Patient Level Data',
                'PbR',
                'PCP',
                'PDS',
                'Pediatric Care',
                'PEDW',
                'Perscriptions',
                'PET scan',
                'Pharmaceuticals',
                'Physiology',
                'Picture archiving and communication system',
                'PILLAR 1',
                'PILLAR 2',
                'PILLAR 3',
                'Place of review',
                'Plain radiography',
                'Planned',
                'PLD',
                'Pneumonia',
                'Pneumonitis',
                'Population',
                'Positron emission tomography',
                'Postponed',
                'Prednisolone',
                'Pregnancy',
                'Preprints',
                'Prescribing',
                'Prescriptions',
                'Primary care',
                'Primary Care Events',
                'Primary care prescription',
                'Primary Care Prescriptions',
                'Primary care records',
                'Procedure',
                'Procedures',
                'Psychiatric Inpatients',
                'Public Health England',
                'Pulmonary infiltrates',
                'PUPIL',
                'QA',
                'Radiology',
                'Radiology information system',
                'Rare disease',
                'Re-attendance',
                'Referral',
                'Referral to treatment',
                'Registered Deaths',
                'Religion',
                'Resident Population',
                'Respiratory tract',
                'RIS',
                'Risk',
                'Risk Assessments',
                'SAIL',
                'Scan',
                'School',
                'School Workforce',
                'Scotland',
                'Screening',
                'Secondary care records',
                'Secondary infections',
                'Sepsis',
                'Septicaemia',
                'Sequencing',
                'Serial readings',
                'Serology',
                'Severity',
                'SEWS',
                'Sex',
                'SGSS',
                'Shielded',
                'SICSAG',
                'Single photon emission computerized tomography',
                'SITREP',
                'SNOMED',
                'SNOMED CT',
                'SPECT',
                'Spell',
                'Spine',
                'Steroids',
                'Students',
                'Substance',
                'SUS',
                'SUS+',
                'SYMPTOM',
                'Symptoms',
                'Systematized Nomenclature of Medicine Clinical Terms',
                'Teacher',
                'Test',
                'Testing',
                'Therapies',
                'Therapy',
                'Threshold',
                'Tidal volume',
                'Time in A&E',
                'TRACKER',
                'TREATMENT',
                'Treatment Speciality',
                'Treatments',
                'Troponin',
                'Type 1',
                'Type 2',
                'UEC',
                'UHB',
                'Ultrasound',
                'Unplanned Investigation',
                'Unscheduled Care',
                'URGENT AND EMERGENCY CARE',
                'Urgent Care',
                'Urinary tract',
                'US',
                'Ventilated',
                'Viral',
                'Vital signs',
                'Vital-signs',
                'Waiting List',
                'Waiting Times',
                'Wales',
                'Wales Deaths',
                'Wave 1',
                'Wave 2',
                'Welsh',
                'Welsh Deaths',
                'X-Ray',
            ],
        },
        {
            description: 'Physical Sample Availability',
            name: 'physicalSampleAvailability',
            options: [
                'AVAILABILITY TO BE CONFIRMED',
                'BONE MARROW',
                'DNA',
                'FAECES',
                'NOT AVAILABLE',
                'PLASMA',
                'SALIVA',
                'SERUM',
                'TISSUE',
                'URINE',
                'WHOLE BLOOD',
            ],
        },
        {
            description: 'Geographic Coverage',
            name: 'spatial',
            options: [
                'England',
                'England & Wales',
                'England and Wales',
                'England, UK',
                'England, Wales',
                'England, Wales and Isle of Man',
                'England,UK',
                'GB',
                'Great Britain',
                'Https://www.geonames.org/2635167/united-kingdom-of-great-britain-and-northern-ireland.html',
                'Https://www.geonames.org/2638360/scotland.html',
                'NHS Brent CCG; NHS Central London CCG; NHS Ealing CCG; NHS Hammersmith & Fulham CCG; NHS Harrow CCG; NHS Hillingdon CCG; NHS Hounslow CCG; NHS West London CCG',
                'NHS Brent CCG; NHS Central London CCG; NHS Ealing CCG; NHS Hammersmith &Fulham CCG; NHS Harrow CCG; NHS Hillingdon CCG; NHS Hounslow CCG; NHS West London CCG',
                'Nottinghamshire',
                'Scotland',
                'The General / Acute Inpatient and Day Case data covers all residents in Scotland that receive care in hospital and general acute specialities.',
                'The Outpatient Attendance dataset covers all people offered a new or follow up outpatient appointment at a Scottish NHS hospital.',
                'UK',
                'United Kingdom',
                'Wales',
                'Wales, where patient GP Practice has agreed data sharing with SAIL',
                'West Midlands',
                'West Midlands Region, UK',
            ],
        },
    ],
    pageCount: 25,
    pageSize: 25,
    totalCount: 124,
    totalPages: 5,
};
export const page2andSize50PaginationResult = {
    content: [
        {
            id: '267397',
            summary: {
                abstract:
                    'This dataset provides limited information about which NHS workers completed Risk Assessments in the course of normal work.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Risk', 'Risk Assessments', 'SAIL', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Healthcare Workers Risk Assessment',
            },
        },
        {
            id: '267305',
            summary: {
                abstract:
                    'The Higher Education Statistics Agency Student Data covers information on students, staff, graduates, finances, estates, and other populations from all aspects of the UK higher eduation sector.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Higher Education', 'Graduates', 'Students', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Higher Education Statistics Agency (HESA) Student Data',
            },
        },
        {
            id: '358693',
            summary: {
                abstract:
                    'Record-level patient data set of patients attending Accident and Emergency Departments (including minor injury units and walk-in centres) in England. A record represents one attendance.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Episodes',
                    'Hospital',
                    'Diagnosis',
                    'Emergency',
                    'Accident',
                    'Operations',
                    'A&E',
                    'A&E Department',
                    'Major Injuries',
                    'Minor Injuries',
                    'Urgent Care',
                    'Arrival Method',
                    'Ambulance',
                    'A&E Waiting Time',
                    'Initial Assessment',
                    'Time in A&E',
                    'Re-attendance',
                    'Unplanned Investigation',
                    'Treatment',
                    'Diagnosis',
                    'DIGITRIALS',
                    'HES',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Hospital Episode Statistics Accident and Emergency',
            },
        },
        {
            id: '421952',
            summary: {
                abstract:
                    'Record-level patient data set of patients admitted for treatment at NHS hospitals in England, including delivery and birth data. A record represents one episode.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Episodes',
                    'Hospital',
                    'Diagnosis',
                    'Inpatients',
                    'Emergency',
                    'Operations',
                    'Admission',
                    'Finished Consultant Episode',
                    'Finished Admission Episode',
                    'FCE',
                    'FAE',
                    'Episode',
                    'Day Case',
                    'Ordinary',
                    'Elective',
                    'Emergency',
                    'Waiting List',
                    'Planned',
                    'Main Speciality',
                    'Treatment Speciality',
                    'External Cause',
                    'Hospital Activity',
                    'HES',
                    'APC',
                    'DIGITRIALS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Hospital Episode Statistics Admitted Patient Care',
            },
        },
        {
            id: '359249',
            summary: {
                abstract:
                    'Record-level patient data set of patients admitted for treatment and receiving Critical Care (intensive care or high dependency care) at NHS hospitals in England. A record represents one episode of Critical Care.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Episodes',
                    'Hospital',
                    'Critical Care',
                    'Diagnosis',
                    'Emergency',
                    'Operations',
                    'Critical Care Period',
                    'Critical Care Records',
                    'Intensive Care Unit',
                    'ICU',
                    'High Dependency Unit ',
                    'HDU',
                    'Level 2 Critical Care Bed',
                    'Level 3 Critical Care Bed',
                    'Neonatal Care',
                    'Babies',
                    'Pediatric Care',
                    'Children',
                    'Organ Support',
                    'Heart',
                    'Kidney',
                    'Liver',
                    'Breathing',
                    'Circulation',
                    'HES',
                    'DIGITRIALS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Hospital Episode Statistics Critical Care',
            },
        },
        {
            id: '359375',
            summary: {
                abstract:
                    'Record-level patient data set of patients attending outpatient clinics at NHS hospitals in England. A record represents one appointment.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Episodes',
                    'Hospital',
                    'Diagnosis',
                    'Outpatients',
                    'Emergency',
                    'Operations',
                    'Appointment',
                    'Attendance',
                    'Did Not Attend',
                    'DNA',
                    'Cancellation',
                    'Attendance Type',
                    'Diagnosis',
                    'Procedure',
                    'Main Speciality',
                    'Treatment Speciality',
                    'HES',
                    'DIGITRIALS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'HUB',
                    name: 'NHS DIGITRIALS',
                },
                title: 'Hospital Episode Statistics Outpatients',
            },
        },
        {
            id: '382739',
            summary: {
                abstract: 'Intensive care case mix and activity',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Intensive Care', 'ICNARC'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Intensive Care National Audit and Research Centre',
            },
        },
        {
            id: '383437',
            summary: {
                abstract: 'Intensive care case mix and activity - National emergency response purposes version.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Intensive Care', 'SAIL', 'Audit', 'ICNARC', 'ICCD'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Intensive Care National Audit and Research Centre - Covid19',
            },
        },
        {
            id: '361228',
            summary: {
                abstract:
                    'The primary purpose of the Labour Force Survey (LFS) is "providing good quality point in time and change estimates for various labour market outputs and related topics” (National Statistics Quality Review (NSQR) of Labour Force Survey 2014).',
                contactPoint: 'social.surveys@ons.gov.uk',
                keywords: ['education', 'economic activity', 'employment', 'NCS'],
                publisher: {
                    contactPoint: 'social.surveys@ons.gov.uk',
                    memberOf: 'NCS',
                    name: 'OFFICE FOR NATIONAL STATISTICS',
                },
                title: 'Labour Force Survey (Household)',
            },
        },
        {
            id: '361202',
            summary: {
                abstract:
                    'The primary purpose of the Labour Force Survey (LFS) is "providing good quality point in time and change estimates for various labour market outputs and related topics” (National Statistics Quality Review (NSQR) of Labour Force Survey 2014).',
                contactPoint: 'social.surveys@ons.gov.uk',
                keywords: ['employment', 'education', 'economic activity', 'NCS'],
                publisher: {
                    contactPoint: 'social.surveys@ons.gov.uk',
                    memberOf: 'NCS',
                    name: 'OFFICE FOR NATIONAL STATISTICS',
                },
                title: 'Labour Force Survey (Longitudinal)',
            },
        },
        {
            id: '361229',
            summary: {
                abstract:
                    'The primary purpose of the Labour Force Survey (LFS) is "providing good quality point in time and change estimates for various labour market outputs and related topics” (National Statistics Quality Review (NSQR) of Labour Force Survey 2014).',
                contactPoint: 'social.surveys@ons.gov.uk',
                keywords: ['employment', 'education', 'economic activity', 'NCS'],
                publisher: {
                    contactPoint: 'social.surveys@ons.gov.uk',
                    memberOf: 'NCS',
                    name: 'OFFICE FOR NATIONAL STATISTICS',
                },
                title: 'Labour Force Survey (Person)',
            },
        },
        {
            id: '347748',
            summary: {
                abstract: 'Bespoke linked dataset that contains death registrations linked to the 2011 Census.',
                contactPoint: 'research.support@ons.gov.uk',
                keywords: ['religion', 'COVID-19', 'disabilities', 'mortality', 'ethnicity', 'NCS'],
                publisher: {
                    contactPoint: 'research.support@ons.gov.uk',
                    memberOf: 'OTHER',
                    name: 'Office for National Statistics',
                },
                title: 'Linked Census and death occurrences',
            },
        },
        {
            id: '263807',
            summary: {
                abstract:
                    'Information about looked after children. Includes demographic and episode level information.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Children', 'Welsh', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Looked After Children Wales',
            },
        },
        {
            id: '394170',
            summary: {
                abstract:
                    'Patient-level data set that captures information about activity carried out by Maternity Services relating to mother and baby(s), from the first booking appointment until mother and baby(s) are discharged from the services.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: ['Childbirth', 'Mother', 'Maternity', 'Births', 'Baby', 'DIGITRIALS'],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'MSDS (Maternity Services Data Set)',
            },
        },
        {
            id: '260345',
            summary: {
                abstract:
                    'The Maternity Indicators Data Set captures data relating to the woman at initial assessment and to mother and baby (or babies) for all births. This relates to initial assessment and birth activity undertaken in Wales only.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Maternity', 'Indicators', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Maternity Indicators Dataset',
            },
        },
        {
            id: '312054',
            summary: {
                abstract:
                    'The Maternity Inpatient and Day Case dataset (SMR02) collects episode level data every time a mother goes in for an obstetric event (this can be an antenatal, delivery or postnatal episode).',
                contactPoint: 'nss.edris@nhs.net',
                keywords: ['NHS Scotland', 'Obstetrics', 'Maternity'],
                publisher: {
                    contactPoint: 'nss.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'SCOTLAND',
                },
                title: 'Maternity Inpatient and Day Case - Scottish Morbidity Record (SMR02)',
            },
        },
        {
            id: '360709',
            summary: {
                abstract:
                    'The Medicines Dispensed in Primary Care (NHSBSA) data comprises prescriptions for medicines that are dispensed or supplied by community pharmacists, appliance contractors and dispensing doctors in England.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'DIGITRIALS',
                    'NCS',
                    'DIGITRIALS',
                    'PRESCRIBING',
                    'MEDICINES',
                    'DISPENSING',
                    'NCS',
                    'NATIONAL CONNECTIVITY STUDY',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Medicines dispensed in Primary Care (NHSBSA data)',
            },
        },
        {
            id: '312192',
            summary: {
                abstract:
                    'The Mental Health Inpatient and Day Case dataset (SMR04) collects episode level data on patients who are receiving care at psychiatric hospitals at the point of both admission and discharge.',
                contactPoint: 'phs.edris@phs.scot',
                keywords: ['NHS Scotland', 'Daycases', 'Psychiatric Inpatients', 'Mental Health', 'NCS'],
                publisher: {
                    contactPoint: 'phs.edris@phs.scot',
                    memberOf: 'ALLIANCE',
                    name: 'PUBLIC HEALTH SCOTLAND',
                },
                title: 'Mental Health Inpatient and Day Case - Scottish Morbidity Record (SMR04)',
            },
        },
        {
            id: '422677',
            summary: {
                abstract:
                    'The MHSDS includes patient level clinical and administrative data for patients, who are in contact with NHS funded mental health services in England. This data set defines the data items and values extracted from local electronic patient record systems.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Mental Health',
                    'Learning Disabilities',
                    'Child and Adolescent Mental Health Services',
                    'CAMHS',
                    'MH',
                    'LD',
                    'Assuring Transformation',
                    'AT',
                    'MHMDS',
                    'MHLDDS',
                    'DIGITRIALS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Mental Health Services Data Set',
            },
        },
        {
            id: '360246',
            summary: {
                abstract:
                    'This dataset contains a subset of information relating to GP contacts in Scotland and the reason for the contact.',
                contactPoint: 'phs.edris@nhs.net',
                keywords: ['GP', 'Contacts'],
                publisher: {
                    contactPoint: 'phs.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'PUBLIC HEALTH SCOTLAND',
                },
                title: 'NHS Scotland General Practice (GP) Contact Data - Subset',
            },
        },
        {
            id: '248121',
            summary: {
                abstract:
                    'The Child Health System in Wales; includes birth registration and monitoring of child health examinations and immunisations.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Child Health', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'National Community Child Health Database (NCCHD)',
            },
        },
        {
            id: '421591',
            summary: {
                abstract:
                    'Audit collects Information about general diabetes care.\r\nData submitted by health care services, relevant to service they provide i.e. Secondary Care Bodies = Type 1, GP practices = Type 2. Includes demographics and diabetes relevant biometric information.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: ['Diabetes', 'Type 2', 'Insulin', 'Type 1', 'Audit', 'DIGITRIALS'],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'National Diabetes Audit',
            },
        },
        {
            id: '247828',
            summary: {
                abstract:
                    'The National Exercise Referral Scheme (NERS) is a Public Health Wales (PHW) funded scheme which has been in development since 2007. The Scheme targets clients aged 16 and over who have, or are at risk of developing, a chronic disease.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['exercise', 'referral', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'National Exercise Referral Scheme',
            },
        },
        {
            id: '338287',
            summary: {
                abstract: 'All Registrations to the National Records of Scotland of deaths',
                contactPoint: 'nss.edris@nhs.net',
                keywords: ['Scotland ', 'Deaths', 'NCS'],
                publisher: {
                    contactPoint: 'nss.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'SCOTLAND',
                },
                title: 'National Records of Scotland (NRS) - Deaths Data',
            },
        },
        {
            id: '253703',
            summary: {
                abstract:
                    'National Survey for Wales questionnaire responses following consent to link. NSWD superceded Welsh Health Survey in 2015.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['National Survey', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'National Survey for Wales',
            },
        },
        {
            id: '257777',
            summary: {
                abstract:
                    'The NWL A&E linked table is taken from the Secondary Users Service database which contains records of care administered in an A&E setting within NWL. Some of the data items included are dates, referral sources, diagnosis and treatments.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['A&E', 'Accident and Emergency'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Accident and Emergency Data (NWL A&E)',
            },
        },
        {
            id: '257998',
            summary: {
                abstract:
                    'The NWL APC linked table is taken from the Secondary Users Service database which contains records of care administered in an APC setting within NWL. Some of the data items included are admission and discharge dates, diagnosis(ICD10) and procedures(OPCS4)',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['IP', 'APC', 'Spell', 'Inpatient', 'Episode'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Admitted Patient Care Data (NWL APC)',
            },
        },
        {
            id: '258485',
            summary: {
                abstract:
                    'The NWL ASC linked table holds care package data relating to patients registered with a NWL GP. A locally agreed data set between NWL boroughs and commissioners has defined data items such as start and end dates, allocated teams and service descriptions.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['ACS', 'Adult Social Care'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Adult Social Care Data (NWL ASC)',
            },
        },
        {
            id: '247481',
            summary: {
                abstract:
                    'The NWL COVID19 PLD SITREP linked table is a direct daily feed from NWL providers. The table provides the patient level data related to COVID admissions in hospital since the outbreak of the pandemic, includes bed status/ventilation status etc.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['SITREP'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London COVID-19 Patient Level Situation Report (NWL COVID19 PLD SITREP)',
            },
        },
        {
            id: '258588',
            summary: {
                abstract:
                    'The NWL COM linked table holds activity data from Community Providers which contains records of care administered in a Community setting within NWL. Some of the data items included are breastfeeding and nutrition, scored assessments and screening activity',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['Community'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Community Data (NWL COM)',
            },
        },
        {
            id: '247440',
            summary: {
                abstract:
                    'The NWL CMC linked table is an innovative NHS service that builds medical care around the wishes of each patient. Patients registered with a NWL General Practice can create plans with their GP and share with healthcare professionals treating them.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['CMC'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Coordinate My Care (NWL CMC)',
            },
        },
        {
            id: '258362',
            summary: {
                abstract:
                    'The NWL HCD linked table are a number of high cost drugs that are excluded from the Payment by Results (PbR) tariff. They are typically specialist and are delivered to patients within NWL. The data includes details such as drug name, quantity and dosage.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['High Cost Drugs', 'HCD'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London High Cost Drugs Data (NWL HCD)',
            },
        },
        {
            id: '239826',
            summary: {
                abstract:
                    'The NWL ICR links all the tables held within DISCOVER-NOW via a de-identified patient key. This can be used for pathway analysis and population health analysis across care settings as Discover-NOW has data for primary, secondary and social care.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: [
                    'Primary Care Events',
                    'Dataset',
                    'Adult Social Care',
                    'High Cost Drugs',
                    'Primary Care Prescriptions',
                    'Mental Health',
                    'Community',
                    'Patient Level Data',
                    'Integrated Care Record',
                    'Outpatient Care',
                    'Accident and Emergency',
                    'Linked',
                    'Admitted Patient Care',
                ],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Integrated Care Record (NWL ICR)',
            },
        },
        {
            id: '258529',
            summary: {
                abstract:
                    'The NWL MH linked table holds activity data from Mental health providers and contains the type and location of care received, episodes of care, diagnoses and questionnaire scores for individuals who have accessed adult mental health services within NWL.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['MH', 'Mental Health'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Mental Health Data (NWL MH)',
            },
        },
        {
            id: '247550',
            summary: {
                abstract:
                    'The NWL OP linked table is taken from the Secondary Users Service database which contains records of care administered in an OP setting within NWL. Some of the data items included are appointment dates, HRG codes, specialties and referral sources.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['OP', 'Outpatient'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Outpatient Care Data (NWL OP)',
            },
        },
        {
            id: '247519',
            summary: {
                abstract:
                    'The NWL Pathology linked table is a direct feed from The Doctors Laboratory and North West London Pathology for patients registered within NWL. Some of the data items included are test dates and times, test codes and names and test results.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['Pathology'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Pathology (NWL PATH)',
            },
        },
        {
            id: '258394',
            summary: {
                abstract:
                    'The NWL Patient Index linked tables holds demographics for patients registered to a practices within NWL such as patient age, gender, first language and ethnicity. Lower Super Output Area and Index of Multiple Deprivation Rank is also included.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['Patient Level Data', 'PLD'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Patient Index (NWL PI)',
            },
        },
        {
            id: '258432',
            summary: {
                abstract:
                    'The NWL Primary Care Events holds coded interactions between NWL registered patients and GP using EMIS or S1. This includes all clinical and non-clinical events the GPs have recorded for the patient, the data has now got SNOMED codes incorporated.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['General Practice', 'GP'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Primary Care Events Data (NWL PCE)',
            },
        },
        {
            id: '258460',
            summary: {
                abstract:
                    'The NWL Primary Care Prescriptions covers all General Practices in NWL using EMIS or S1. This provides details of supply of prescriptions, issue date, primary care organisation that issued as well as whether it is a repeat or one-off prescription.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['Primary care prescription', 'PCP'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Primary Care Prescriptions Data (NWL PCP)',
            },
        },
        {
            id: '247458',
            summary: {
                abstract:
                    'The NWL POP table holds the NWL registered patients and key demographic information about them i.e. age, gender, ethinicity etc.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['NHAIS'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London population data (NWL POP)',
            },
        },
        {
            id: '359687',
            summary: {
                abstract:
                    'Limited OMOP dataset of Hospital COVID patients at the Queen Elizabeth Hospital, Birmingham from 6.01.2020-20.08.2020.  Granular severity, ethnicity, co-morbidity.  Serial acuity, physiology, blood biomarkers, treatments, ITU, outcome. Deeply phenotyped,',
                contactPoint: 'PioneerDIH@UHB.NHS.UK',
                keywords: [
                    'COVID',
                    'Covid-19',
                    'Acuity score',
                    'Coronovirus',
                    'NEWS2',
                    'SEWS',
                    'AVPU',
                    'age',
                    'sex',
                    'ethnicity ',
                    'physiology',
                    'vital signs',
                    'biomarkers',
                    'd-dimer',
                    'troponin',
                    'CRP',
                    'sepsis',
                    'symptoms',
                    'outcomes',
                    'intensive care',
                    'ventilated',
                    'do not resuscitate orders',
                    'secondary care records',
                    'high flow nasal oxygen',
                    'treatments',
                    'antibiotics',
                    'secondary infections',
                    'outcomes',
                    'discharge',
                    'death',
                    'length of stay',
                ],
                publisher: {
                    contactPoint: 'PioneerDIH@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'OMOP dataset: Hospital COVID patients: severity, acuity, therapies, outcomes',
            },
        },
        {
            id: '347133',
            summary: {
                abstract:
                    'A census in the UK is a count of all people and households. The latest census in the UK was held on 27 March 2011.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['SAIL', 'Census', 'Population', 'Resident Population', 'ONS', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'ONS 2011 Census Wales (CENW)',
            },
        },
        {
            id: '311961',
            summary: {
                abstract:
                    'The Outpatients (SMR00) dataset collects episode level data from patients on new and follow up appointments at outpatient clinics in all specialities (except A&E and Genito-Urinary Medicine).',
                contactPoint: 'nss.edris@nhs.net',
                keywords: ['NHS Scotland Outpatients'],
                publisher: {
                    contactPoint: 'nss.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'SCOTLAND',
                },
                title: 'Outpatient Appointments and Attendances - Scottish Morbidity Record (SMR00)',
            },
        },
        {
            id: '339464',
            summary: {
                abstract: 'Attendance information for all hospital outpatient appointments.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Outpatient', 'Dataset', 'SAIL', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Outpatient Dataset',
            },
        },
        {
            id: '259064',
            summary: {
                abstract: 'Data on Outpatient referrals from primary care.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Referral', 'Outpatient', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Outpatient Referral',
            },
        },
        {
            id: '402026',
            summary: {
                abstract:
                    'The PANTHER study aims to deepen the understanding of susceptibility to, immunity from and transmission of Sars-Cov2 (the virus that causes Covid-19) in an at risk population.',
                contactPoint: 'Benjamin.Ollivere@nottingham.ac.uk',
                keywords: ['COVID', 'Serology', 'Healthcare workers', 'CO-CONNECT'],
                publisher: {
                    contactPoint: 'Benjamin.Ollivere@nottingham.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'University of Nottingham',
                },
                title: 'PANTHER study',
            },
        },
        {
            id: '259426',
            summary: {
                abstract:
                    'Pathology Test Results and all Radiology reports for Wales. Data coverage differs by geography:\r\n2012 for Swansea (ABMU)\r\n\r\n2017 for Newport (AB)\r\n\r\n2012 for North Wales (BC)\r\n\r\n2007 for Cwm Taf\r\n\r\n2015/16 for Cardiff (CV)\r\n\r\n2014 for West Wales (HD)',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Pathology', 'SAIL', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Pathology Data from WRRS',
            },
        },
        {
            id: '394670',
            summary: {
                abstract:
                    'The database contains all inpatient and day case activity undertaken in NHS Wales plus data on Welsh residents treated in English Trusts.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['PEDW', 'SAIL', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Patient Episode Dataset for Wales',
            },
        },
        {
            id: '359624',
            summary: {
                abstract:
                    'The PDS helps healthcare professionals to identify patients and match them to their health records. It also allows them to contact and communicate with patients.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'NHS Central Register',
                    'Spine',
                    'Demographic',
                    'GP Registrations',
                    'PDS',
                    'NHSCR',
                    'DIGITRIALS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Personal Demographic Service',
            },
        },
        {
            id: '259363',
            summary: {
                abstract: 'Reason for cancelled admitted procedures.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Admitted', 'Procedures', 'Postponed', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Postponed Admitted Procedures',
            },
        },
    ],
    currentPage: 2,
    filters: [
        {
            description: 'Publisher',
            name: 'name',
            options: [
                'DATA-CAN',
                'DISCOVER NOW',
                'GENOMICS ENGLAND',
                'HEALTH DATA RESEARCH UK',
                'ISARIC 4C',
                'NHS DIGITAL',
                'NHS DIGITRIALS',
                'NHSX',
                'Office for National Statistics',
                'PIONEER',
                'PIONEER - HDR-UK Health data Hub in Acute Care',
                'PUBLIC HEALTH SCOTLAND',
                'SAIL',
                'SCOTLAND',
                'TEST',
                'University of Nottingham',
            ],
        },
        {
            description: 'Data Use Limitation',
            name: 'dataUseLimitation',
            options: [
                'GENERAL RESEARCH USE',
                'GENETIC STUDIES ONLY',
                'NO GENERAL METHODS RESEARCH',
                'NO RESTRICTION',
                'RESEARCH SPECIFIC RESTRICTIONS',
                'RESEARCH USE ONLY',
            ],
        },
        {
            description: 'Data Use Requirements',
            name: 'dataUseRequirements',
            options: [
                '',
                'COLLABORATION REQUIRED',
                'ETHICS APPROVAL REQUIRED',
                'GEOGRAPHICAL RESTRICTIONS',
                'INSTITUTION SPECIFIC RESTRICTIONS',
                'NOT FOR PROFIT USE',
                'PROJECT SPECIFIC RESTRICTIONS',
                'PUBLICATION MORATORIUM',
                'PUBLICATION REQUIRED',
                'RETURN TO DATABASE OR RESOURCE',
                'TIME LIMIT ON USE',
                'USER SPECIFIC RESTRICTION',
            ],
        },
        {
            description: 'Keywords',
            name: 'keywords',
            options: [
                'A&E',
                'A&E Department',
                'A&E Waiting Time',
                'Accident',
                'Accident and Emergency',
                'ACS',
                'Active',
                'Acuity',
                'Acuity Score',
                'Acute',
                'Acute hospitals',
                'Admission',
                'Admitted',
                'Admitted Patient Care',
                'Adult Social Care',
                'Age',
                'Airway pressure',
                'Alert',
                'Ambulance',
                'AMU',
                'Antibiotics',
                'Antigen',
                'APC',
                'Appointment',
                'Arrival Method',
                'Assuring Transformation',
                'AT',
                'Attendance',
                'Attendance Type',
                'Audit',
                'AVPU',
                'Babies',
                'Baby',
                'Bacterial',
                'Biomarkers',
                'Birmingham',
                'Birth',
                'Births',
                'Blood',
                'Blood biomarkers',
                'Blood-poisoning',
                'Bowel',
                'Breast',
                'Breathing',
                'Brecon',
                'Business',
                'CAFE',
                'CAFW',
                'CAMHS',
                'Cancellation',
                'CANCER',
                'CARE',
                'Care escalation',
                'Careers Wales',
                'CARW',
                'CAT',
                'CDDS',
                'CDS',
                'Cellulitis',
                'Census',
                'Central nervous system',
                'Cervical',
                'Chest CT scan',
                'Chest MRI',
                'Chest radiographs',
                'Chest x-ray',
                'Child and Adolescent Mental Health Services',
                'Child Health',
                'Childbirth',
                'Children',
                'Chronic bronchitis',
                'Circulation',
                'Clinical speciality',
                'CMC',
                'CO-CIN',
                'CO-CONNECT',
                'Co-Morbidities',
                'Co-Morbidity',
                'COMMISSIONING DATA SETS',
                'Community',
                'Computer tomography',
                'Computerized axial tomography',
                'Computor tomography',
                'Cone beam computed tomography',
                'Congenital Anomaly',
                'Consolidation',
                'Contacts',
                'COPD',
                'CORONAVIRUS',
                'Coronovirus',
                'Corticosteroids',
                'Covid',
                'COVID-19',
                'COVID-19 Hospitalisation in England Surveillance System',
                'COVID19',
                'CPAP',
                'Critical Care',
                'Critical Care Period',
                'Critical Care Records',
                'CRP',
                'Cystic Fibrosis',
                'D-dimer',
                'Daily',
                'Data',
                'DATA-CAN',
                'Dataset',
                'Day Case',
                'Day cases',
                'Daycases',
                'Death',
                'Deaths',
                'Deceased',
                'Demographic',
                'Demographics',
                'Demography',
                'Deterioration',
                'Diabetes',
                'Diagnosis',
                'Diagnostic',
                'Diagnostic Image',
                'Diagnostic ultrasonography',
                'Did Not Attend',
                'DIGITRIALS',
                'Disabilities',
                'Discharge',
                'DISPENSING',
                'DNA',
                'Do not resuscitate orders',
                'Do not resusictate orders',
                'DSRD',
                'ECDS',
                'Economic activity',
                'Education',
                'Elective',
                'Emergency',
                'Emphysema',
                'Employment',
                'Endocarditis',
                'England',
                'England Deaths',
                'English Deaths',
                'Episode',
                'Episodes',
                'Ethnicity',
                'Exacerbation',
                'Exercise',
                'External Cause',
                'FAE',
                'FCE',
                'Finished Admission Episode',
                'Finished Consultant Episode',
                'Fluoroscopy',
                'Frailty',
                'Fungal',
                'GDPPR',
                'General Practice',
                'Genome',
                'Genomics',
                'Genotype',
                'GP',
                'GP DATA',
                'GP Registrations',
                'Graduates',
                'GRO',
                'HCD',
                'HDR UK',
                'HDU',
                'Health',
                'Healthcare workers',
                'Heart',
                'Hepatobiliary',
                'HES',
                'High Cost Drugs',
                'High Dependency',
                'High Dependency Unit',
                'High flow nasal oxygen',
                'High flow oxygen',
                'Higher Education',
                'Hospital',
                'Hospital Activity',
                'Hospital episode statistics',
                'HOSPITALISATION',
                'Household',
                'ICCD',
                'ICNARC',
                'ICU',
                'Imaging',
                'Immune system',
                'Indicators',
                'Infection',
                'Initial Assessment',
                'Inpatient',
                'Inpatients',
                'Inspiratory pressure',
                'Insulin',
                'Integrated Care Record',
                'Intensive Care',
                'Intensive Care Unit',
                'Interventions',
                'IP',
                'ISARIC 4C',
                'Key Worker',
                'Kidney',
                'LABORATORY',
                'LD',
                'Learning Disabilities',
                'Length of stay',
                'Level 2 Critical Care Bed',
                'Level 3 Critical Care Bed',
                'Life-threatening',
                'LINKABLE',
                'Linked',
                'Liver',
                'Long term medical conditions',
                'Longitudinal',
                'Magnetic resonance imaging',
                'Main Speciality',
                'Major Injuries',
                'Maternity',
                'Medical photography',
                'Medical review',
                'MEDICINES',
                'Mental Health',
                'MH',
                'MHLDDS',
                'MHMDS',
                'Microbiology',
                'Minor Injuries',
                'Misuse',
                'MOBILE APP',
                'MOF',
                'Mortality',
                'Mother',
                'MRI',
                'Multi-morbidity',
                'NATIONAL CONNECTIVITY STUDY',
                'NATIONAL CORE STUDY',
                'National Interim Clinical Imaging Procedure Code Set',
                'National Survey',
                'NATIONAL TARIFF',
                'NCCID',
                'NCS',
                'Nebuliser',
                'Neonatal Care',
                'NEWS2',
                'NHAIS',
                'NHS',
                'NHS Central Register',
                'NHS Scotland',
                'NHS Scotland Outpatients',
                'NHSCR',
                'NICIP',
                'NM',
                'Non-invasive ventilation',
                'NORTHERN IRELAND',
                'Nuclear medicine',
                'Observations',
                'Obstetrics',
                'ONS',
                'OP',
                'Operations',
                'Ordinary',
                'Organ failure',
                'Organ Support',
                'Osteomyelitus',
                'Out of Hours',
                'OUTCOME',
                'Outcomes',
                'Outpatient',
                'Outpatient Care',
                'Outpatients',
                'Oxygen',
                'PACS',
                'Paediatric',
                'Papers',
                'Pathology',
                'Patient',
                'Patient Level Data',
                'PbR',
                'PCP',
                'PDS',
                'Pediatric Care',
                'PEDW',
                'Perscriptions',
                'PET scan',
                'Pharmaceuticals',
                'Physiology',
                'Picture archiving and communication system',
                'PILLAR 1',
                'PILLAR 2',
                'PILLAR 3',
                'Place of review',
                'Plain radiography',
                'Planned',
                'PLD',
                'Pneumonia',
                'Pneumonitis',
                'Population',
                'Positron emission tomography',
                'Postponed',
                'Prednisolone',
                'Pregnancy',
                'Preprints',
                'Prescribing',
                'Prescriptions',
                'Primary care',
                'Primary Care Events',
                'Primary care prescription',
                'Primary Care Prescriptions',
                'Primary care records',
                'Procedure',
                'Procedures',
                'Psychiatric Inpatients',
                'Public Health England',
                'Pulmonary infiltrates',
                'PUPIL',
                'QA',
                'Radiology',
                'Radiology information system',
                'Rare disease',
                'Re-attendance',
                'Referral',
                'Referral to treatment',
                'Registered Deaths',
                'Religion',
                'Resident Population',
                'Respiratory tract',
                'RIS',
                'Risk',
                'Risk Assessments',
                'SAIL',
                'Scan',
                'School',
                'School Workforce',
                'Scotland',
                'Screening',
                'Secondary care records',
                'Secondary infections',
                'Sepsis',
                'Septicaemia',
                'Sequencing',
                'Serial readings',
                'Serology',
                'Severity',
                'SEWS',
                'Sex',
                'SGSS',
                'Shielded',
                'SICSAG',
                'Single photon emission computerized tomography',
                'SITREP',
                'SNOMED',
                'SNOMED CT',
                'SPECT',
                'Spell',
                'Spine',
                'Steroids',
                'Students',
                'Substance',
                'SUS',
                'SUS+',
                'SYMPTOM',
                'Symptoms',
                'Systematized Nomenclature of Medicine Clinical Terms',
                'Teacher',
                'Test',
                'Testing',
                'Therapies',
                'Therapy',
                'Threshold',
                'Tidal volume',
                'Time in A&E',
                'TRACKER',
                'TREATMENT',
                'Treatment Speciality',
                'Treatments',
                'Troponin',
                'Type 1',
                'Type 2',
                'UEC',
                'UHB',
                'Ultrasound',
                'Unplanned Investigation',
                'Unscheduled Care',
                'URGENT AND EMERGENCY CARE',
                'Urgent Care',
                'Urinary tract',
                'US',
                'Ventilated',
                'Viral',
                'Vital signs',
                'Vital-signs',
                'Waiting List',
                'Waiting Times',
                'Wales',
                'Wales Deaths',
                'Wave 1',
                'Wave 2',
                'Welsh',
                'Welsh Deaths',
                'X-Ray',
            ],
        },
        {
            description: 'Physical Sample Availability',
            name: 'physicalSampleAvailability',
            options: [
                'AVAILABILITY TO BE CONFIRMED',
                'BONE MARROW',
                'DNA',
                'FAECES',
                'NOT AVAILABLE',
                'PLASMA',
                'SALIVA',
                'SERUM',
                'TISSUE',
                'URINE',
                'WHOLE BLOOD',
            ],
        },
        {
            description: 'Geographic Coverage',
            name: 'spatial',
            options: [
                'England',
                'England & Wales',
                'England and Wales',
                'England, UK',
                'England, Wales',
                'England, Wales and Isle of Man',
                'England,UK',
                'GB',
                'Great Britain',
                'Https://www.geonames.org/2635167/united-kingdom-of-great-britain-and-northern-ireland.html',
                'Https://www.geonames.org/2638360/scotland.html',
                'NHS Brent CCG; NHS Central London CCG; NHS Ealing CCG; NHS Hammersmith & Fulham CCG; NHS Harrow CCG; NHS Hillingdon CCG; NHS Hounslow CCG; NHS West London CCG',
                'NHS Brent CCG; NHS Central London CCG; NHS Ealing CCG; NHS Hammersmith &Fulham CCG; NHS Harrow CCG; NHS Hillingdon CCG; NHS Hounslow CCG; NHS West London CCG',
                'Nottinghamshire',
                'Scotland',
                'The General / Acute Inpatient and Day Case data covers all residents in Scotland that receive care in hospital and general acute specialities.',
                'The Outpatient Attendance dataset covers all people offered a new or follow up outpatient appointment at a Scottish NHS hospital.',
                'UK',
                'United Kingdom',
                'Wales',
                'Wales, where patient GP Practice has agreed data sharing with SAIL',
                'West Midlands',
                'West Midlands Region, UK',
            ],
        },
    ],
    pageCount: 50,
    pageSize: 50,
    totalCount: 124,
    totalPages: 3,
};
export const page2andSize100PaginationResult = {
    content: [
        {
            id: '258807',
            summary: {
                abstract:
                    'Information on the total time waited from referral by a GP or other medical practitioner to hospital treatment in the NHS.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Referral to treatment', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Referral to Treatment Times',
            },
        },
        {
            id: '360993',
            summary: {
                abstract:
                    'The Scottish Intensive Care Society Audit Group (SICSAG) has maintained a national database of patients admitted to adult general Intensive Care Units (ICU) in Scotland since 1995. Each line relates to a day of stay in critical care.',
                contactPoint: 'phs.edris@nhs.net',
                keywords: ['SICSAG', 'NCS', 'Scotland', 'Intensive Care'],
                publisher: {
                    contactPoint: 'phs.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'PUBLIC HEALTH SCOTLAND',
                },
                title: 'SICSAG Daily (Scottish Intensive Care Audit Group)',
            },
        },
        {
            id: '361045',
            summary: {
                abstract:
                    'The Scottish Intensive Care Society Audit Group (SICSAG) has maintained a national database of patients admitted to adult general Intensive Care Units (ICU) in Scotland since 1995. Each line relates to an ‘episode of care’ in critical care',
                contactPoint: 'phs.edris@nhs.net',
                keywords: ['SICSAG', 'NCS', 'Scotland', 'Intensive Care'],
                publisher: {
                    contactPoint: 'phs.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'PUBLIC HEALTH SCOTLAND',
                },
                title: 'SICSAG Episodes (Scottish Intensive Care Audit Group)',
            },
        },
        {
            id: '382378',
            summary: {
                abstract:
                    'The School Workforce Annual Census (SWAC) is an electronic collection of individual level data on the school workforce in local authority maintained settings in Wales. The first collection was introduced in 2019 and collects information at Nov, yearly.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['SAIL', 'School Workforce', 'Education', 'Teacher', 'School'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'School Workforce Annual Census (SWAC)',
            },
        },
        {
            id: '312795',
            summary: {
                abstract:
                    "The Scottish Birth Record (SBR) records baby's neonatal care in Scotland, from antenatal through to post delivery, including readmissions and transfers in one electronic record. SBR is based on individuals and events rather than episodes and is completed f",
                contactPoint: 'phs.edris@phs.scot',
                keywords: ['NHS Scotland', 'Neonatal Care', 'Births'],
                publisher: {
                    contactPoint: 'phs.edris@phs.scot',
                    memberOf: 'ALLIANCE',
                    name: 'PUBLIC HEALTH SCOTLAND',
                },
                title: 'Scottish Birth Record (SBR)',
            },
        },
        {
            id: '419288',
            summary: {
                abstract:
                    'The Secondary Uses Service (SUS +) is a collection of health care data required by hospitals and used for planning health care, supporting payments, commissioning policy development and research.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'DIGITRIALS',
                    'SUS',
                    'NCS',
                    'SUS+',
                    'PbR',
                    'NATIONAL TARIFF',
                    'CDS',
                    'COMMISSIONING DATA SETS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Secondary Uses Services Payment By Results',
            },
        },
        {
            id: '263213',
            summary: {
                abstract:
                    'The Substance Misuse Data Set captures data relating to all individuals (clients), both young persons and adults, presenting for substance misuse treatment in Wales.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Misuse', 'Substance', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Substance Misuse Dataset',
            },
        },
        {
            id: '338954',
            summary: {
                abstract: 'This is a test.',
                contactPoint: 'test@metadataworks.co.uk',
                doiName: 'test.123.test.123.test',
                keywords: ['test', 'QA'],
                publisher: {
                    contactPoint: 'qa@metadataworks.co.uk',
                    memberOf: 'HUB',
                    name: 'TEST',
                },
                title: 'Test Dataset 1',
            },
        },
        {
            id: '402473',
            summary: {
                abstract: 'This is a test dataset.',
                contactPoint: 'test@metadataworks.co.uk',
                doiName: 'test.123.test.123.test',
                keywords: ['test', 'QA'],
                publisher: {
                    contactPoint: 'qa@metadataworks.co.uk',
                    memberOf: 'OTHER',
                    name: 'TEST',
                },
                title: 'Test Dataset 7',
            },
        },
        {
            id: '408973',
            summary: {
                abstract:
                    'Hospital patients admitted during the COVID pandemic with a focus on COPD. Granular condition,  multi-morbidity. Serial physiology, blood biomarkers, treatments, interventions, ITU spells, outcome, pre/post admission healthcare use. Deeply phenotyped.',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'COPD',
                    'chronic bronchitis',
                    'emphysema',
                    'nhs',
                    'acute',
                    'exacerbation',
                    'COVID',
                    'pneumonia',
                    'pneumonitis',
                    'NEWS2',
                    'deterioration',
                    'alert ',
                    'SEWS',
                    'nebuliser',
                    'prednisolone',
                    'steroids',
                    'corticosteroids',
                    'oxygen',
                    'non-invasive ventilation',
                    'patient',
                    'acute hospitals',
                    'inpatient',
                    'ethnicity',
                    'multi-morbidity ',
                    'blood ',
                    'biomarkers',
                    'physiology',
                    'demographics',
                    'treatments',
                    'therapies',
                    'interventions',
                    'outcomes',
                    'death',
                    'longitudinal',
                    'vital signs',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'The impact of COVID on hospitalised patients with COPD; a dataset in OMOP',
            },
        },
        {
            id: '394495',
            summary: {
                abstract:
                    'A deeply phenotyped dataset of hospitalised COVID-19 patients in Birmingham; including granular ethnicity and multi-morbidity data confirmed in primary care; physiology, blood biomarkers, treatments, interventions, ITU admissions and outcomes.',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'COVID',
                    'Ethnicity',
                    'Covid-19',
                    'Co-Morbidity',
                    'Co-Morbidities',
                    'Coronavirus',
                    'UHB',
                    'Birmingham',
                    'long term medical conditions',
                    'physiology',
                    'age',
                    'sex',
                    'vital signs',
                    'biomarkers',
                    'd-dimer',
                    'troponin',
                    'CRP',
                    'symptoms',
                    'severity',
                    'chest radiographs',
                    'pulmonary infiltrates',
                    'consolidation',
                    'computor tomography',
                    'outcomes',
                    'intensive care',
                    'ventilated',
                    'do not resusictate orders',
                    'primary care records',
                    'secondary care records',
                    'high flow nasal oxygen',
                    'treatments',
                    'antibiotics',
                    'secondary infections',
                    'outcomes',
                    'death',
                    'discharge',
                    'length of stay',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'The impact of ethnicity and multi-morbidity on C19 hospitalised outcomes',
            },
        },
        {
            id: '409365',
            summary: {
                abstract:
                    'Society of Acute Medicine Audit data. Hospital patients. Granular care pathways against national guidelines. Severity, demographics, multi-morbidity, completion of review, interventions and treatments, outcomes. Patient flow through hospital. UHBFT',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'NHS',
                    'acute',
                    'AMU',
                    'NEWS2',
                    'deterioration',
                    'alert',
                    'SEWS',
                    'patient',
                    'acute hospitals',
                    'inpatient',
                    'ethnicity',
                    'multi-morbidity',
                    'blood',
                    'biomarkers',
                    'pregnancy',
                    'care escalation',
                    'physiology',
                    'demographics',
                    'treatments',
                    'therapies',
                    'interventions',
                    'outcomes',
                    'death',
                    'clinical speciality',
                    'medical review',
                    'observations',
                    'place of review',
                    'frailty',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'UHB 2019 Summer Society of Acute Medicine Benchmarking Audit',
            },
        },
        {
            id: '409366',
            summary: {
                abstract:
                    'Society of Acute Medicine Audit data. Hospital patients admitted during the COVID pandemic. Granular care pathways for reconfigured services. Severity, demographics, multi-morbidity, interventions and treatments, outcomes.  Flow through hospital. UHBFT.',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'NHS',
                    'Acute',
                    'AMU',
                    'NEWS2',
                    'deterioration',
                    'alert',
                    'SEWS',
                    'COVID',
                    'Patient',
                    'acute hospitals',
                    'inpatient',
                    'ethnicity',
                    'multi-morbidity',
                    'blood',
                    'biomarkers',
                    'pregnancy',
                    'care escalation',
                    'physiology',
                    'demographics',
                    'treatments',
                    'therapies',
                    'interventions',
                    'outcomes',
                    'death ',
                    'clinical speciality',
                    'medical review',
                    'observations',
                    'place of review',
                    'frailty',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'UHB 2020 Winter Society of Acute Medicine Benchmarking Audit',
            },
        },
        {
            id: '344264',
            summary: {
                abstract:
                    'The UK Cystic Fibrosis Registry is a national, secure, centralized database sponsored and managed by the Cystic Fibrosis Trust, with UK National Health Service (NHS) research ethics approval and consent from each person for whom data are collected.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['SAIL', 'Cystic Fibrosis', 'genotype'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'UK Cystic Fibrosis Registry (CYFI)',
            },
        },
        {
            id: '409169',
            summary: {
                abstract:
                    'Hospital patients admitted during the COVID pandemic requiring/at risk of requiring ventilatory support.  Granular condition,  multi-morbidity, interventions and treatments.  Serial physiology, blood biomarkers, ITU spells, outcome. Deeply phenotyped.',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'NHS',
                    'acute',
                    'exacerbation',
                    'COVID',
                    'pneumonia',
                    'pneumonitis',
                    'NEWS2',
                    'deterioration',
                    'alert',
                    'SEWS',
                    'oxygen',
                    'non-invasive ventilation',
                    ' high flow oxygen',
                    'CPAP',
                    'airway pressure',
                    'inspiratory pressure',
                    'tidal volume',
                    'patient',
                    'acute hospitals',
                    'inpatient',
                    'ethnicity',
                    'multi-morbidity',
                    'blood',
                    'biomarkers',
                    'physiology',
                    'demographics',
                    'treatments',
                    'therapies',
                    'interventions',
                    'outcomes',
                    'death',
                    'longitudinal',
                    'vital signs',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'Ventilatory strategies and outcomes for patients with COVID: a dataset in OMOP',
            },
        },
        {
            id: '266974',
            summary: {
                abstract:
                    'The Welsh Ambulance Services NHS Trust data covers data pertaining to more than 250,000 emergency calls a year, over 50,000 urgent calls and transport over 1.3 million non-emergency patients to over 200 treatment centres throughout England and Wales.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Emergency', 'Ambulance', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Welsh Ambulance Services NHS Trust',
            },
        },
        {
            id: '260075',
            summary: {
                abstract:
                    'The Welsh Cancer Intelligence & Surveillance Unit (WCISU) is the National Cancer Registry for Wales and its primary role is to record, store and report on all incidence of cancer for the resident population of Wales wherever they are treated.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['CANCER', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Welsh Cancer Intelligence and Surveillance Unit (WCISU)',
            },
        },
        {
            id: '259783',
            summary: {
                abstract:
                    'Register of all individuals registered with a Welsh GP, includes individuals anonymised address and practice history.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Welsh', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Welsh Demographic Service (WDS)',
            },
        },
        {
            id: '399068',
            summary: {
                abstract:
                    "The Welsh Dispensing Dataset covers prescriptions that are prescribed in Wales by GP's (general medical practitioners) and non medical prescribers that have prescribed on behalf of the GP practice, that are then dispensed in the community within Wales.",
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Prescriptions', 'Dispensing', 'Prescribing', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Welsh Dispensing Dataset',
            },
        },
    ],
    currentPage: 2,
    filters: [
        {
            description: 'Publisher',
            name: 'name',
            options: [
                'DATA-CAN',
                'DISCOVER NOW',
                'GENOMICS ENGLAND',
                'HEALTH DATA RESEARCH UK',
                'ISARIC 4C',
                'NHS DIGITAL',
                'NHS DIGITRIALS',
                'Office for National Statistics',
                'PIONEER',
                'PIONEER - HDR-UK Health data Hub in Acute Care',
                'PUBLIC HEALTH SCOTLAND',
                'SAIL',
                'SCOTLAND',
                'TEST',
                'University of Nottingham',
            ],
        },
        {
            description: 'Data Use Limitation',
            name: 'dataUseLimitation',
            options: [
                'GENERAL RESEARCH USE',
                'GENETIC STUDIES ONLY',
                'NO GENERAL METHODS RESEARCH',
                'NO RESTRICTION',
                'RESEARCH SPECIFIC RESTRICTIONS',
                'RESEARCH USE ONLY',
            ],
        },
        {
            description: 'Data Use Requirements',
            name: 'dataUseRequirements',
            options: [
                '',
                'COLLABORATION REQUIRED',
                'ETHICS APPROVAL REQUIRED',
                'GEOGRAPHICAL RESTRICTIONS',
                'INSTITUTION SPECIFIC RESTRICTIONS',
                'NOT FOR PROFIT USE',
                'PROJECT SPECIFIC RESTRICTIONS',
                'PUBLICATION MORATORIUM',
                'PUBLICATION REQUIRED',
                'RETURN TO DATABASE OR RESOURCE',
                'TIME LIMIT ON USE',
                'USER SPECIFIC RESTRICTION',
            ],
        },
        {
            description: 'Keywords',
            name: 'keywords',
            options: [
                'A&E',
                'A&E Department',
                'A&E Waiting Time',
                'Accident',
                'Accident and Emergency',
                'ACS',
                'Active',
                'Acuity',
                'Acuity Score',
                'Acute',
                'Acute hospitals',
                'Admission',
                'Admitted',
                'Admitted Patient Care',
                'Adult Social Care',
                'Age',
                'Airway pressure',
                'Alert',
                'Ambulance',
                'AMU',
                'Antibiotics',
                'Antigen',
                'APC',
                'Appointment',
                'Arrival Method',
                'Assuring Transformation',
                'AT',
                'Attendance',
                'Attendance Type',
                'Audit',
                'AVPU',
                'Babies',
                'Baby',
                'Bacterial',
                'Biomarkers',
                'Birmingham',
                'Birth',
                'Births',
                'Blood',
                'Blood biomarkers',
                'Blood-poisoning',
                'Bowel',
                'Breast',
                'Breathing',
                'Brecon',
                'Business',
                'CAFE',
                'CAFW',
                'CAMHS',
                'Cancellation',
                'CANCER',
                'CARE',
                'Care escalation',
                'Careers Wales',
                'CARW',
                'CAT',
                'CDDS',
                'CDS',
                'Cellulitis',
                'Census',
                'Central nervous system',
                'Cervical',
                'Chest radiographs',
                'Child and Adolescent Mental Health Services',
                'Child Health',
                'Childbirth',
                'Children',
                'Chronic bronchitis',
                'Circulation',
                'Clinical speciality',
                'CMC',
                'CO-CIN',
                'CO-CONNECT',
                'Co-Morbidities',
                'Co-Morbidity',
                'COMMISSIONING DATA SETS',
                'Community',
                'Computer tomography',
                'Computerized axial tomography',
                'Computor tomography',
                'Cone beam computed tomography',
                'Congenital Anomaly',
                'Consolidation',
                'Contacts',
                'COPD',
                'CORONAVIRUS',
                'Coronovirus',
                'Corticosteroids',
                'Covid',
                'COVID-19',
                'COVID-19 Hospitalisation in England Surveillance System',
                'COVID19',
                'CPAP',
                'Critical Care',
                'Critical Care Period',
                'Critical Care Records',
                'CRP',
                'Cystic Fibrosis',
                'D-dimer',
                'Daily',
                'Data',
                'DATA-CAN',
                'Dataset',
                'Day Case',
                'Day cases',
                'Death',
                'Deaths',
                'Deceased',
                'Demographic',
                'Demographics',
                'Demography',
                'Deterioration',
                'Diabetes',
                'Diagnosis',
                'Diagnostic',
                'Diagnostic Image',
                'Diagnostic ultrasonography',
                'Did Not Attend',
                'DIGITRIALS',
                'Disabilities',
                'Discharge',
                'DISPENSING',
                'DNA',
                'Do not resuscitate orders',
                'Do not resusictate orders',
                'DSRD',
                'ECDS',
                'Economic activity',
                'Education',
                'Elective',
                'Emergency',
                'Emphysema',
                'Employment',
                'Endocarditis',
                'England',
                'England Deaths',
                'English Deaths',
                'Episode',
                'Episodes',
                'Ethnicity',
                'Exacerbation',
                'Exercise',
                'External Cause',
                'FAE',
                'FCE',
                'Finished Admission Episode',
                'Finished Consultant Episode',
                'Fluoroscopy',
                'Frailty',
                'Fungal',
                'GDPPR',
                'General Practice',
                'Genome',
                'Genomics',
                'Genotype',
                'GP',
                'GP DATA',
                'GP Registrations',
                'Graduates',
                'GRO',
                'HCD',
                'HDR UK',
                'HDU',
                'Health',
                'Healthcare workers',
                'Heart',
                'Hepatobiliary',
                'HES',
                'High Cost Drugs',
                'High Dependency',
                'High Dependency Unit',
                'High flow nasal oxygen',
                'High flow oxygen',
                'Higher Education',
                'Hospital',
                'Hospital Activity',
                'Hospital episode statistics',
                'HOSPITALISATION',
                'Household',
                'ICCD',
                'ICNARC',
                'ICU',
                'Immune system',
                'Indicators',
                'Infection',
                'Initial Assessment',
                'Inpatient',
                'Inpatients',
                'Inspiratory pressure',
                'Insulin',
                'Integrated Care Record',
                'Intensive Care',
                'Intensive Care Unit',
                'Interventions',
                'IP',
                'ISARIC 4C',
                'Key Worker',
                'Kidney',
                'LABORATORY',
                'LD',
                'Learning Disabilities',
                'Length of stay',
                'Level 2 Critical Care Bed',
                'Level 3 Critical Care Bed',
                'Life-threatening',
                'LINKABLE',
                'Linked',
                'Liver',
                'Long term medical conditions',
                'Longitudinal',
                'Magnetic resonance imaging',
                'Main Speciality',
                'Major Injuries',
                'Maternity',
                'Medical photography',
                'Medical review',
                'MEDICINES',
                'Mental Health',
                'MH',
                'MHLDDS',
                'MHMDS',
                'Microbiology',
                'Minor Injuries',
                'Misuse',
                'MOBILE APP',
                'MOF',
                'Mortality',
                'Mother',
                'MRI',
                'Multi-morbidity',
                'NATIONAL CONNECTIVITY STUDY',
                'NATIONAL CORE STUDY',
                'National Interim Clinical Imaging Procedure Code Set',
                'National Survey',
                'NATIONAL TARIFF',
                'NCS',
                'Nebuliser',
                'Neonatal Care',
                'NEWS2',
                'NHAIS',
                'NHS',
                'NHS Central Register',
                'NHS Scotland',
                'NHS Scotland Outpatients',
                'NHSCR',
                'NICIP',
                'NM',
                'Non-invasive ventilation',
                'NORTHERN IRELAND',
                'Nuclear medicine',
                'Observations',
                'Obstetrics',
                'ONS',
                'OP',
                'Operations',
                'Ordinary',
                'Organ failure',
                'Organ Support',
                'Osteomyelitus',
                'OUTCOME',
                'Outcomes',
                'Outpatient',
                'Outpatient Care',
                'Outpatients',
                'Oxygen',
                'PACS',
                'Paediatric',
                'Papers',
                'Pathology',
                'Patient',
                'Patient Level Data',
                'PbR',
                'PCP',
                'PDS',
                'Pediatric Care',
                'PEDW',
                'PET scan',
                'Physiology',
                'Picture archiving and communication system',
                'PILLAR 1',
                'PILLAR 2',
                'PILLAR 3',
                'Place of review',
                'Plain radiography',
                'Planned',
                'PLD',
                'Pneumonia',
                'Pneumonitis',
                'Population',
                'Positron emission tomography',
                'Postponed',
                'Prednisolone',
                'Pregnancy',
                'Preprints',
                'Prescribing',
                'Prescriptions',
                'Primary care',
                'Primary Care Events',
                'Primary care prescription',
                'Primary Care Prescriptions',
                'Primary care records',
                'Procedure',
                'Procedures',
                'Public Health England',
                'Pulmonary infiltrates',
                'PUPIL',
                'QA',
                'Radiology',
                'Radiology information system',
                'Rare disease',
                'Re-attendance',
                'Referral',
                'Referral to treatment',
                'Registered Deaths',
                'Religion',
                'Resident Population',
                'Respiratory tract',
                'RIS',
                'Risk',
                'Risk Assessments',
                'SAIL',
                'Scan',
                'School',
                'School Workforce',
                'Scotland',
                'Screening',
                'Secondary care records',
                'Secondary infections',
                'Sepsis',
                'Septicaemia',
                'Sequencing',
                'Serial readings',
                'Serology',
                'Severity',
                'SEWS',
                'Sex',
                'SGSS',
                'Shielded',
                'SICSAG',
                'Single photon emission computerized tomography',
                'SITREP',
                'SNOMED',
                'SNOMED CT',
                'SPECT',
                'Spell',
                'Spine',
                'Steroids',
                'Students',
                'Substance',
                'SUS',
                'SUS+',
                'SYMPTOM',
                'Symptoms',
                'Systematized Nomenclature of Medicine Clinical Terms',
                'Teacher',
                'Test',
                'Testing',
                'Therapies',
                'Therapy',
                'Threshold',
                'Tidal volume',
                'Time in A&E',
                'TRACKER',
                'TREATMENT',
                'Treatment Speciality',
                'Treatments',
                'Troponin',
                'Type 1',
                'Type 2',
                'UEC',
                'UHB',
                'Ultrasound',
                'Unplanned Investigation',
                'URGENT AND EMERGENCY CARE',
                'Urgent Care',
                'Urinary tract',
                'US',
                'Ventilated',
                'Viral',
                'Vital signs',
                'Vital-signs',
                'Waiting List',
                'Waiting Times',
                'Wales',
                'Wales Deaths',
                'Wave 1',
                'Wave 2',
                'Welsh',
                'Welsh Deaths',
                'X-Ray',
            ],
        },
        {
            description: 'Physical Sample Availability',
            name: 'physicalSampleAvailability',
            options: [
                'AVAILABILITY TO BE CONFIRMED',
                'BONE MARROW',
                'DNA',
                'FAECES',
                'NOT AVAILABLE',
                'PLASMA',
                'SALIVA',
                'SERUM',
                'TISSUE',
                'URINE',
                'WHOLE BLOOD',
            ],
        },
        {
            description: 'Geographic Coverage',
            name: 'spatial',
            options: [
                'England',
                'England & Wales',
                'England and Wales',
                'England, UK',
                'England, Wales and Isle of Man',
                'England,UK',
                'GB',
                'Great Britain',
                'Https://www.geonames.org/2635167/united-kingdom-of-great-britain-and-northern-ireland.html',
                'Https://www.geonames.org/2638360/scotland.html',
                'NHS Brent CCG; NHS Central London CCG; NHS Ealing CCG; NHS Hammersmith & Fulham CCG; NHS Harrow CCG; NHS Hillingdon CCG; NHS Hounslow CCG; NHS West London CCG',
                'NHS Brent CCG; NHS Central London CCG; NHS Ealing CCG; NHS Hammersmith &Fulham CCG; NHS Harrow CCG; NHS Hillingdon CCG; NHS Hounslow CCG; NHS West London CCG',
                'Nottinghamshire',
                'Scotland',
                'The General / Acute Inpatient and Day Case data covers all residents in Scotland that receive care in hospital and general acute specialities.',
                'The Outpatient Attendance dataset covers all people offered a new or follow up outpatient appointment at a Scottish NHS hospital.',
                'UK',
                'United Kingdom',
                'Wales',
                'Wales, where patient GP Practice has agreed data sharing with SAIL',
                'West Midlands',
                'West Midlands Region, UK',
            ],
        },
    ],
    pageCount: 19,
    pageSize: 100,
    totalCount: 119,
    totalPages: 2,
};
export const page1andSize100PaginationResult = {
    content: [
        {
            id: '424732',
            summary: {
                abstract: 'Large scale survey of the adult population in Wales using CAPI. Replaced by NSWD.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Active', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Active Adult Survey',
            },
        },
        {
            id: '260416',
            summary: {
                abstract: 'Register of all births in Wales.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Birth', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Annual District Birth Extract',
            },
        },
        {
            id: '345608',
            summary: {
                abstract: 'Daily version of Annual District Death Dataset.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Daily', 'Wales', 'Death', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Annual District Death Daily',
            },
        },
        {
            id: '345665',
            summary: {
                abstract: 'Register of all deaths relating to Welsh residents, including those that died out of Wales.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Death', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Annual District Death Extract',
            },
        },
        {
            id: '259462',
            summary: {
                abstract:
                    'NHS Screening Services - Bowel Screening Data. The aim of the bowel screening programme is to identify cancer early when treatment is more likely to be successful and also remove precancerous growths.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Bowel', 'Screening', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Bowel Screening Wales',
            },
        },
        {
            id: '259813',
            summary: {
                abstract:
                    'Screening Services - Breast Screening Data. The aim of the breast screening programme is to reduce mortality from breast cancer. Women aged 50 to 70 who are resident in Wales, and registered with a General Practitioner, are invited for a mammogram.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Screening', 'Breast', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Breast Test Wales',
            },
        },
        {
            id: '247767',
            summary: {
                abstract:
                    'A register of children diagnosed with type 1 diabetes, collected from Paediatric diabetes clinics in Wales. Maintained by the Brecon Group. Data has been collected since 1995 and is complete since then, but some people diagnosed earlier are also included.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Diabetes', 'Paediatric', 'SAIL', 'Brecon', 'Children'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Brecon dataset',
            },
        },
        {
            id: '347718',
            summary: {
                abstract:
                    'The indicators and analysis presented in this bulletin are based on responses from the new voluntary fortnightly business survey, which captures business’ views on impact on turnover, workforce prices, trade and business resilience',
                contactPoint: 'research.support@ons.gov.uk',
                keywords: ['COVID-19', 'business', 'health', 'economic activity', 'NCS'],
                publisher: {
                    contactPoint: 'research.support@ons.gov.uk',
                    memberOf: 'OTHER',
                    name: 'Office for National Statistics',
                },
                title: 'Business Impact of COVID-19 Survey (BICS)',
            },
        },
        {
            id: '342939',
            summary: {
                abstract:
                    'CO-CIN has collected data on 105,000 patients of all ages requiring admission to hospital with covid-19, and patients in hospital subsequently diagnosed with covid-19 in England, Scotland and Wales.',
                contactPoint: 'isaric4c-samples@roslin.ed.ac.uk',
                keywords: ['CO-CIN', 'ISARIC 4C', 'COVID-19', 'NCS'],
                publisher: {
                    contactPoint: 'isaric4c-samples@roslin.ed.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'ISARIC 4C',
                },
                title: 'COVID-19 Clinical Information Network (CO-CIN)',
            },
        },
        {
            id: '339533',
            summary: {
                abstract:
                    'COVID-19 Consolidated Deaths dataset. Similar to the usual Annual deaths extract (SAIL Databank), except this dataset contains additional information with relevance to deaths where Covid-19 is attributable.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Covid-19', 'CDDS', 'SAIL', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'COVID-19 Consolidated Deaths',
            },
        },
        {
            id: '383690',
            summary: {
                abstract:
                    'Data forming the COVID-19 SARI-Watch data set (formerly CHESS) relate to demographic, risk factor, treatment, & outcome information for patients admitted to hospital with a confirmed COVID-19 diagnosis, as recorded in the COVID-19 SARI-Watch surveillance',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'COVID-19 Hospitalisation in England Surveillance System',
                    ' ICU',
                    'High Dependency',
                    'HOSPITALISATION',
                    'TESTING',
                    'LABORATORY',
                    'RISK',
                    'OUTCOME',
                    'TREATMENT',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'COVID-19 SARI-Watch (formerly CHESS)',
            },
        },
        {
            id: '346025',
            summary: {
                abstract: 'List of high-risk people advised to self-isolate during Covid pandemic.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['coronavirus', 'shielded', 'covid', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'COVID-19 Shielded People list',
            },
        },
        {
            id: '346791',
            summary: {
                abstract:
                    "The COVID Symptom Tracker was designed by doctors and scientists at King's College London (KCL), Guys and St Thomas’ Hospital working in partnership with ZOE Global. Led by Dr Tim Spector, professor of genetic epidemiology at KCL and director of TwinsUK.",
                contactPoint: 'saildatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['COVID-19', 'TRACKER', 'SAIL', 'COVID', 'MOBILE APP', 'CORONAVIRUS', 'SYMPTOM', 'NCS'],
                publisher: {
                    contactPoint: 'saildatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'COVID-19 Symptom Tracker Dataset',
            },
        },
        {
            id: '346284',
            summary: {
                abstract: 'Test results for COVID-19 tests.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Welsh', 'SAIL', 'coronavirus', 'COVID19', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'COVID-19 Test results',
            },
        },
        {
            id: '264963',
            summary: {
                abstract:
                    'Cafcass (Children and Family Court Advisory and Support Service) England Family Justice dataset.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['CAFE', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Cafcass England',
            },
        },
        {
            id: '382049',
            summary: {
                abstract:
                    'Cafcass (Children and Family Court Advisory and Support Service) Wales Family Justice dataset.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['CAFW', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Cafcass Wales',
            },
        },
        {
            id: '345744',
            summary: {
                abstract:
                    'This database contains residential and geographical information data about care homes in Wales.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['CARE', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Care Home Dataset',
            },
        },
        {
            id: '345967',
            summary: {
                abstract:
                    'Careers Wales dataset. Represents interactions and services provided by Careers Wales to clients throughout Wales over the period 01/04/2012 to 31/03/2020.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['SAIL', 'CARW', 'Careers Wales'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Careers Wales dataset',
            },
        },
        {
            id: '347721',
            summary: {
                abstract:
                    'These tables contain the counts of the number of households (in England and Wales) within each LSOA with each specific combination of ages within it.',
                contactPoint: 'research.support@ons.gov.uk',
                keywords: ['COVID-19', 'household', 'demography', 'health', 'NCS'],
                publisher: {
                    contactPoint: 'research.support@ons.gov.uk',
                    memberOf: 'OTHER',
                    name: 'Office for National Statistics',
                },
                title: 'Census 2011 E&W - Household structure for COVID-19 models',
            },
        },
        {
            id: '259941',
            summary: {
                abstract:
                    'Screening Services - Cervical Screening Data. The cervical screening programme has 3 main aims: Reduce the number of cases of cervical cancer (incidence), deaths from cervical cancer (mortality), and the effects of cancer or cancer treatments on health.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Screening', 'Cervical', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Cervical Screening Wales',
            },
        },
        {
            id: '225874',
            summary: {
                abstract: 'Census data relating to children with a care and support plan.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Children', 'Welsh', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Children Receiving Care and Support Census',
            },
        },
        {
            id: '360247',
            summary: {
                abstract:
                    'Deaths registration data (all deaths in England and Wales) collected from The Registrar General for England and Wales. Record-level person data set, where a record represents one death registration.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Wales Deaths',
                    'Welsh Deaths',
                    'Deaths',
                    'GRO',
                    'Deceased',
                    'English Deaths',
                    'Registered Deaths',
                    'England Deaths',
                    'ONS',
                    'DIGITRIALS',
                    'NCS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'HUB',
                    name: 'NHS DIGITRIALS',
                },
                title: 'Civil Registration - Deaths',
            },
        },
        {
            id: '381980',
            summary: {
                abstract:
                    'Hospital patient acuity scores (2004 onwards). Granular condition, ethnicity, multi-morbidity.  Serial physiology, blood biomarkers, treatments, interventions, ITU spells, outcome, pre/post admission healthcare use. Deeply phenotyped, longitudinal.',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'acuity',
                    'nhs',
                    'acute',
                    'NEWS2',
                    'threshold',
                    'deterioration',
                    'alert',
                    'SEWS',
                    'inpatient',
                    'ethnicity',
                    'multi-morbidity',
                    'treatments',
                    'therapies',
                    'longitudinal',
                    'vital-signs',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER - HDR-UK Health data Hub in Acute Care',
                },
                title: 'Clinical response thresholds (acuity) in acutely unwell patients: onset-outcome',
            },
        },
        {
            id: '259600',
            summary: {
                abstract:
                    'Information about any fetus or baby who has or is suspected of having a congenital anomaly and whose mother is normally resident in Wales at time of birth.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Congenital Anomaly', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Congenital Anomaly Register and Information Service',
            },
        },
        {
            id: '347851',
            summary: {
                abstract:
                    'The purpose of this dataset is to understand the prevalence of COVID-19 in the UK population, including swab results, antibody tests and demographic information.',
                contactPoint: 'research.support@ons.gov.uk',
                keywords: ['COVID-19', 'household', 'health', 'NCS'],
                publisher: {
                    contactPoint: 'research.support@ons.gov.uk',
                    memberOf: 'OTHER',
                    name: 'Office for National Statistics',
                },
                title: 'Covid-19 Infection Survey',
            },
        },
        {
            id: '337250',
            summary: {
                abstract:
                    'Data forming the Covid-19 Second Generation Surveillance Systems data set relate to demographic and diagnostic information from Pillar 1 swab testing in PHE labs and NHS hospitals and Pillar 2 Swab testing in the community.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'DIGITRIALS',
                    'SGSS',
                    'NCS',
                    'PILLAR 1',
                    'PILLAR 2',
                    'PILLAR 3',
                    'ENGLAND',
                    'NATIONAL CORE STUDY',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Covid-19 Second Generation Surveillance System',
            },
        },
        {
            id: '420121',
            summary: {
                abstract:
                    'The Covid-19 UK Non-hospital Antibody Testing Results (Pillar 3) dataset, also referred to as iElisa, documents individuals that have undergone a finger prick test for antibodies from having had Covid-19.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'DIGITRIALS',
                    'PILLAR 3',
                    'SCOTLAND',
                    'ENGLAND',
                    'NORTHERN IRELAND',
                    'WALES',
                    'NCS ',
                    'NATIONAL CORE STUDY',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Covid-19 UK Non-hospital Antibody Testing Results',
            },
        },
        {
            id: '337819',
            summary: {
                abstract:
                    'COVID-19 UK Non-hospital Antigen Testing Results (Pillar 2) data is required by NHS Digital to support COVID-19 requests for linkage, analysis and dissemination.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'DIGITRIALS',
                    'Pillar 2',
                    'NCS',
                    'Key Worker',
                    'Scotland',
                    'England',
                    'Wales',
                    'Northern Ireland',
                    'National Core Study',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Covid-19 UK Non-hospital Antigen Testing Results',
            },
        },
        {
            id: '259178',
            summary: {
                abstract:
                    'Contains a subset of mandatory items for the generation of Critical Care Healthcare Resource Groups (HRGs).',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Critical Care', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Critical Care Dataset',
            },
        },
        {
            id: '267326',
            summary: {
                abstract:
                    'Daily Situation Report dataset.\r\n\r\nDaily situation report for healthcare equipment, staff, activity, capacity and usage.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Covid-19', 'DSRD', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Daily Situation Report Data',
            },
        },
        {
            id: '347745',
            summary: {
                abstract:
                    'Monthly death registration data, collected as part of civil registration, provide information on deaths that occur in and are then registered in England and Wales.',
                contactPoint: 'research.support@ons.gov.uk',
                keywords: ['COVID-19', 'mortality', 'health', 'NCS'],
                publisher: {
                    contactPoint: 'research.support@ons.gov.uk',
                    memberOf: 'OTHER',
                    name: 'Office for National Statistics',
                },
                title: 'Death registration data - Provisional Monthly Extracts',
            },
        },
        {
            id: '382048',
            summary: {
                abstract:
                    'Hospitalised sepsis patients (2000-2020) by cause. Deeply phenotyped, longitudinal.  Granular ethnicity and multi-morbidity.  Serial acuity, physiology, blood parameters, treatments, interventions, ITU spells, outcomes, pre and post sepsis healthcare.',
                contactPoint: 'Pioneer@UHB.NHS.UK',
                keywords: [
                    'Sepsis',
                    'Infection',
                    'NHS',
                    'Acute',
                    'immune system',
                    'life-threatening',
                    'septicaemia',
                    'blood-poisoning',
                    'MOF',
                    'organ failure',
                    'respiratory tract',
                    'pneumonia',
                    'urinary tract',
                    'hepatobiliary',
                    'central nervous system',
                    'cellulitis',
                    'osteomyelitus',
                    'endocarditis',
                    'viral',
                    'bacterial',
                    'fungal',
                    'blood biomarkers',
                    'microbiology',
                    'treatments',
                    'antibiotics',
                    'physiology',
                    'serial readings',
                    'outcomes',
                    'intensive care',
                    'death',
                    'discharge',
                    'length of stay',
                    'multi-morbidity',
                    'COVID-19',
                    'wave 1',
                    'wave 2',
                ],
                publisher: {
                    contactPoint: 'Pioneer@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER - HDR-UK Health data Hub in Acute Care',
                },
                title: 'Deeply phenotyped sepsis patients within hospital: onset, treatments & outcomes',
            },
        },
        {
            id: '360811',
            summary: {
                abstract:
                    'Hospital COVID patients (6th Jan 2020 onwards). Granular severity, ethnicity, co-morbidity.  Serial physiology, blood biomarkers, images, treatments, ITU, outcome, pre/post admission healthcare use. Deeply phenotyped, longitudinal. Waves 1/ 2',
                contactPoint: 'PioneerDIH@UHB.NHS.UK',
                keywords: [
                    'COVID',
                    'Covid-19',
                    'Acuity Score',
                    'NEWS2',
                    'SEWS',
                    'AVPU',
                    'Coronovirus',
                    'UHB',
                    'Birmingham',
                    'age',
                    'sex',
                    'ethnicity ',
                    'physiology',
                    'vital signs',
                    'biomarkers',
                    'd-dimer',
                    'troponin',
                    'CRP',
                    'sepsis',
                    'symptoms',
                    'chest radiographs',
                    'computer tomography',
                    'outcomes',
                    'intensive care',
                    'ventilated',
                    'do not resuscitate orders',
                    'primary care records',
                    'secondary care records',
                    'high flow nasal oxygen',
                    'treatments',
                    'antibiotics',
                    'secondary infections',
                    'outcomes',
                    'death',
                    'discharge',
                    'length of stay',
                ],
                publisher: {
                    contactPoint: 'PioneerDIH@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'Deeply-phenotyped hospital COVID patients: severity, acuity, therapies, outcomes',
            },
        },
        {
            id: '420897',
            summary: {
                abstract:
                    'The Diagnostic Imaging Data Set (DID) collects data about diagnostic imaging tests carried out on NHS patients, extracted from local radiology information systems.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Scan',
                    'Radiology',
                    'Diagnostic Image',
                    'X-Ray',
                    'Plain radiography',
                    'CAT',
                    'Computerized axial tomography',
                    'Fluoroscopy',
                    'MRI',
                    'Magnetic resonance imaging',
                    'NM',
                    'Nuclear medicine',
                    'US',
                    'Diagnostic ultrasonography',
                    'Ultrasound',
                    'Medical photography',
                    'PET scan',
                    'Positron emission tomography',
                    'SPECT',
                    'Single photon emission computerized tomography',
                    'Cone beam computed tomography',
                    'SNOMED',
                    'SNOMED CT',
                    'Systematized Nomenclature of Medicine Clinical Terms',
                    'NICIP',
                    'National Interim Clinical Imaging Procedure Code Set',
                    'RIS',
                    'radiology information system ',
                    'PACS',
                    'picture archiving and communication system',
                    'DIGITRIALS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Diagnostic Imaging Dataset',
            },
        },
        {
            id: '258855',
            summary: {
                abstract: 'Waiting times for diagnostic scans and waiting times for access to therapy services.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Diagnostic', 'Therapy', 'Waiting Times', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Diagnostic and Therapy Services Waiting Times',
            },
        },
        {
            id: '337903',
            summary: {
                abstract:
                    'Pillar 1 & 2 COVID antigen testing data.  Electronic Communication of Surveillance in Scotland (ECOSS): Holds all positive microbiology laboratory specimen results and a subset of antimicrobial susceptibility/resistance data in Scotland.',
                contactPoint: 'phs.edris@nhs.net',
                keywords: ['COVID', 'Testing', 'Scotland', 'antigen'],
                publisher: {
                    contactPoint: 'phs.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'SCOTLAND',
                },
                title: 'ECOSS (-/+VE tested patients only)',
            },
        },
        {
            id: '264389',
            summary: {
                abstract: 'Schools and Pupil data for Wales.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['PUPIL', 'SAIL', 'LINKABLE', 'SCHOOL', 'EDUCATION'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Education - Wales',
            },
        },
        {
            id: '342260',
            summary: {
                abstract:
                    'The Emergency Care Data Set (ECDS) is the national data set for urgent and emergency care. It replaced Accident & Emergency Commissioning Data Set (CDS type 010) and was implemented through: ECDS (CDS 6.2.2 Type 011).',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'DIGITRIALS',
                    'A&E',
                    'ACCIDENT AND EMERGENCY',
                    'ECDS',
                    'NCS',
                    'NATIONAL CORE STUDY',
                    'URGENT AND EMERGENCY CARE',
                    'UEC',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Emergency Care Data Set (ECDS)',
            },
        },
        {
            id: '260208',
            summary: {
                abstract: 'Attendance and clinical information for all Accident and Emergency attendances.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Emergency', 'A&E', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Emergency Department Data Set',
            },
        },
        {
            id: '264053',
            summary: {
                abstract: 'Daily version of Emergency Department Dataset.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Emergency', 'A&E', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Emergency Department Dataset Daily',
            },
        },
        {
            id: '180228',
            summary: {
                abstract:
                    'Contains tables with data related to genomic data and the outputs from the GEL interpretation pipeline data for participants from both cancer and rare disease programmes. These tables do not directly include primary + secondary sources of clinical data.',
                contactPoint: 'gecip-help@genomicsengland.co.uk',
                doiName: 'Not Available',
                keywords: ['sequencing', 'genomics', 'data', 'DNA', 'DATA-CAN'],
                publisher: {
                    contactPoint: 'gecip-help@genomicsengland.co.uk',
                    memberOf: 'ALLIANCE',
                    name: 'GENOMICS ENGLAND',
                },
                title: 'GENOMICS ENGLAND 100K BIOINFORMATICS DATA',
            },
        },
        {
            id: '240982',
            summary: {
                abstract:
                    'Cancer data are presented for either the patient level cancer diagnosis or “disease type” or the tumour specific sample details of participants in the Cancer arm of the 100,000 Genomes Project.',
                contactPoint: 'gecip-help@genomicsengland.co.uk',
                doiName: 'Not Available',
                keywords: ['cancer', 'genomics', 'genome', 'England', 'DNA', 'sequencing', 'data', 'DATA-CAN'],
                publisher: {
                    contactPoint: 'gecip-help@genomicsengland.co.uk',
                    memberOf: 'ALLIANCE',
                    name: 'GENOMICS ENGLAND',
                },
                title: 'GENOMICS ENGLAND 100K CANCER & COMMON',
            },
        },
        {
            id: '241001',
            summary: {
                abstract:
                    'NHS national data sets collect information from care records, systems and organisations on specific areas of health and care.',
                contactPoint: 'gecip-help@genomicsengland.co.uk',
                doiName: 'Not Available',
                keywords: ['genome', 'genomics', 'data', 'Hospital episode statistics', 'DNA', 'DATA-CAN'],
                publisher: {
                    contactPoint: 'gecip-help@genomicsengland.co.uk',
                    memberOf: 'ALLIANCE',
                    name: 'GENOMICS ENGLAND',
                },
                title: 'GENOMICS ENGLAND 100K NHSD LINKED DATA',
            },
        },
        {
            id: '242598',
            summary: {
                abstract:
                    'This dataset brings together data from more than 500 local and regional datasets to build a picture of an individual’s treatment from diagnosis. Available for patients diagnosed with Cancer (ICD10 C00-97, D00-48) from 1 January 1995 -31 December 2017.',
                contactPoint: 'gecip-help@genomicsengland.co.uk',
                doiName: 'Not Available',
                keywords: ['genome', 'genomics', 'data', 'DNA', 'Public Health England', 'DATA-CAN'],
                publisher: {
                    contactPoint: 'gecip-help@genomicsengland.co.uk',
                    memberOf: 'ALLIANCE',
                    name: 'GENOMICS ENGLAND',
                },
                title: 'GENOMICS ENGLAND 100K PHE LINKED DATA',
            },
        },
        {
            id: '242481',
            summary: {
                abstract: 'Data views that bring together data from several LabKey tables for convenient access',
                contactPoint: 'gecip-help@genomicsengland.co.uk',
                doiName: 'Not Available',
                keywords: ['cancer', 'rare disease', 'genome', 'genomics', 'DNA', 'data', 'DATA-CAN'],
                publisher: {
                    contactPoint: 'gecip-help@genomicsengland.co.uk',
                    memberOf: 'ALLIANCE',
                    name: 'GENOMICS ENGLAND',
                },
                title: 'GENOMICS ENGLAND 100K QUICK VIEW',
            },
        },
        {
            id: '240105',
            summary: {
                abstract:
                    'Rare Disease (RD) data are presented at the level of RD families, RD pedigrees, and participants. Participants are consenting individuals who have had their genome sequenced. Pedigree members are extended members of the proband’s family.',
                contactPoint: 'gecip-help@genomicsengland.co.uk',
                doiName: 'Not Available',
                keywords: ['rare disease', 'genome', 'genomics', 'data', 'DNA', 'DATA-CAN'],
                publisher: {
                    contactPoint: 'gecip-help@genomicsengland.co.uk',
                    memberOf: 'ALLIANCE',
                    name: 'GENOMICS ENGLAND',
                },
                title: 'GENOMICS ENGLAND 100K RARE DISEASE & COMMON',
            },
        },
        {
            id: '339238',
            summary: {
                abstract:
                    'Attendance and clinical information for all general practice interactions: includes patients symptoms, investigations, diagnoses, prescribed medication and referrals to tertiary care.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'Not Available',
                keywords: ['Primary care', 'GP', 'SAIL', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'GP dataset - Welsh Primary Care',
            },
        },
        {
            id: '394343',
            summary: {
                abstract:
                    'NHS Digital’s fortnightly collection of GP data will provide data to support vital planning and research into coronavirus (COVID-19).',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: ['DIGITRIALS', 'GDPPR', 'NCS', 'GP DATA'],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'GPES Data for Pandemic Planning and Research (COVID-19)',
            },
        },
        {
            id: '338206',
            summary: {
                abstract:
                    'The General / Acute and Inpatient Day Case dataset (SMR01) collects episode level data on hospital inpatient and day case discharges from acute specialities from hospitals in Scotland.',
                contactPoint: 'nss.edris@nhs.net',
                keywords: ['Inpatients ', 'NCS', 'Day cases', 'Scotland'],
                publisher: {
                    contactPoint: 'nss.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'SCOTLAND',
                },
                title: 'General Acute Inpatient and Day Case - Scottish Morbidity Record (SMR01)',
            },
        },
        {
            id: '263839',
            summary: {
                abstract:
                    'Publications that mention HDR-UK (or any variant thereof) in Acknowledgements or Author Affiliations',
                contactPoint: 'susheel.varma@hdruk.ac.uk',
                doiName: 'https://doi.org/10.5281/zenodo.326615',
                keywords: ['Preprints', 'Papers', 'HDR UK'],
                publisher: {
                    contactPoint: 'susheel.varma@hdruk.ac.uk',
                    memberOf: 'OTHER',
                    name: 'HEALTH DATA RESEARCH UK',
                },
                title: 'HDR UK Papers & Preprints',
            },
        },
        {
            id: '267397',
            summary: {
                abstract:
                    'This dataset provides limited information about which NHS workers completed Risk Assessments in the course of normal work.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Risk', 'Risk Assessments', 'SAIL', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Healthcare Workers Risk Assessment',
            },
        },
        {
            id: '267305',
            summary: {
                abstract:
                    'The Higher Education Statistics Agency Student Data covers information on students, staff, graduates, finances, estates, and other populations from all aspects of the UK higher eduation sector.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Higher Education', 'Graduates', 'Students', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Higher Education Statistics Agency (HESA) Student Data',
            },
        },
        {
            id: '256101',
            summary: {
                abstract:
                    'Record-level patient data set of patients attending Accident and Emergency Departments (including minor injury units and walk-in centres) in England. A record represents one attendance.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Episodes',
                    'Hospital',
                    'Diagnosis',
                    'Emergency',
                    'Accident',
                    'Operations',
                    'A&E',
                    'A&E Department',
                    'Major Injuries',
                    'Minor Injuries',
                    'Urgent Care',
                    'Arrival Method',
                    'Ambulance',
                    'A&E Waiting Time',
                    'Initial Assessment',
                    'Time in A&E',
                    'Re-attendance',
                    'Unplanned Investigation',
                    'Treatment',
                    'Diagnosis',
                    'DIGITRIALS',
                    'HES',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'HUB',
                    name: 'NHS DIGITRIALS',
                },
                title: 'Hospital Episode Statistics Accident and Emergency',
            },
        },
        {
            id: '358896',
            summary: {
                abstract:
                    'Record-level patient data set of patients admitted for treatment at NHS hospitals in England, including delivery and birth data. A record represents one episode.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Episodes',
                    'Hospital',
                    'Diagnosis',
                    'Inpatients',
                    'Emergency',
                    'Operations',
                    'Admission',
                    'Finished Consultant Episode',
                    'Finished Admission Episode',
                    'FCE',
                    'FAE',
                    'Episode',
                    'Day Case',
                    'Ordinary',
                    'Elective',
                    'Emergency',
                    'Waiting List',
                    'Planned',
                    'Main Speciality',
                    'Treatment Speciality',
                    'External Cause',
                    'Hospital Activity',
                    'HES',
                    'APC',
                    'DIGITRIALS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'HUB',
                    name: 'NHS DIGITRIALS',
                },
                title: 'Hospital Episode Statistics Admitted Patient Care',
            },
        },
        {
            id: '256657',
            summary: {
                abstract:
                    'Record-level patient data set of patients admitted for treatment and receiving Critical Care (intensive care or high dependency care) at NHS hospitals in England. A record represents one episode of Critical Care.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Episodes',
                    'Hospital',
                    'Critical Care',
                    'Diagnosis',
                    'Emergency',
                    'Operations',
                    'Critical Care Period',
                    'Critical Care Records',
                    'Intensive Care Unit',
                    'ICU',
                    'High Dependency Unit ',
                    'HDU',
                    'Level 2 Critical Care Bed',
                    'Level 3 Critical Care Bed',
                    'Neonatal Care',
                    'Babies',
                    'Pediatric Care',
                    'Children',
                    'Organ Support',
                    'Heart',
                    'Kidney',
                    'Liver',
                    'Breathing',
                    'Circulation',
                    'HES',
                    'DIGITRIALS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'HUB',
                    name: 'NHS DIGITRIALS',
                },
                title: 'Hospital Episode Statistics Critical Care',
            },
        },
        {
            id: '359375',
            summary: {
                abstract:
                    'Record-level patient data set of patients attending outpatient clinics at NHS hospitals in England. A record represents one appointment.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Episodes',
                    'Hospital',
                    'Diagnosis',
                    'Outpatients',
                    'Emergency',
                    'Operations',
                    'Appointment',
                    'Attendance',
                    'Did Not Attend',
                    'DNA',
                    'Cancellation',
                    'Attendance Type',
                    'Diagnosis',
                    'Procedure',
                    'Main Speciality',
                    'Treatment Speciality',
                    'HES',
                    'DIGITRIALS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'HUB',
                    name: 'NHS DIGITRIALS',
                },
                title: 'Hospital Episode Statistics Outpatients',
            },
        },
        {
            id: '382739',
            summary: {
                abstract: 'Intensive care case mix and activity',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Intensive Care', 'ICNARC'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Intensive Care National Audit and Research Centre',
            },
        },
        {
            id: '383437',
            summary: {
                abstract: 'Intensive care case mix and activity - National emergency response purposes version.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Intensive Care', 'SAIL', 'Audit', 'ICNARC', 'ICCD'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Intensive Care National Audit and Research Centre - Covid19',
            },
        },
        {
            id: '361228',
            summary: {
                abstract:
                    'The primary purpose of the Labour Force Survey (LFS) is "providing good quality point in time and change estimates for various labour market outputs and related topics” (National Statistics Quality Review (NSQR) of Labour Force Survey 2014).',
                contactPoint: 'social.surveys@ons.gov.uk',
                keywords: ['education', 'economic activity', 'employment', 'NCS'],
                publisher: {
                    contactPoint: 'social.surveys@ons.gov.uk',
                    memberOf: 'NCS',
                    name: 'OFFICE FOR NATIONAL STATISTICS',
                },
                title: 'Labour Force Survey (Household)',
            },
        },
        {
            id: '361202',
            summary: {
                abstract:
                    'The primary purpose of the Labour Force Survey (LFS) is "providing good quality point in time and change estimates for various labour market outputs and related topics” (National Statistics Quality Review (NSQR) of Labour Force Survey 2014).',
                contactPoint: 'social.surveys@ons.gov.uk',
                keywords: ['employment', 'education', 'economic activity', 'NCS'],
                publisher: {
                    contactPoint: 'social.surveys@ons.gov.uk',
                    memberOf: 'NCS',
                    name: 'OFFICE FOR NATIONAL STATISTICS',
                },
                title: 'Labour Force Survey (Longitudinal)',
            },
        },
        {
            id: '361229',
            summary: {
                abstract:
                    'The primary purpose of the Labour Force Survey (LFS) is "providing good quality point in time and change estimates for various labour market outputs and related topics” (National Statistics Quality Review (NSQR) of Labour Force Survey 2014).',
                contactPoint: 'social.surveys@ons.gov.uk',
                keywords: ['employment', 'education', 'economic activity', 'NCS'],
                publisher: {
                    contactPoint: 'social.surveys@ons.gov.uk',
                    memberOf: 'NCS',
                    name: 'OFFICE FOR NATIONAL STATISTICS',
                },
                title: 'Labour Force Survey (Person)',
            },
        },
        {
            id: '347748',
            summary: {
                abstract: 'Bespoke linked dataset that contains death registrations linked to the 2011 Census.',
                contactPoint: 'research.support@ons.gov.uk',
                keywords: ['religion', 'COVID-19', 'disabilities', 'mortality', 'ethnicity', 'NCS'],
                publisher: {
                    contactPoint: 'research.support@ons.gov.uk',
                    memberOf: 'OTHER',
                    name: 'Office for National Statistics',
                },
                title: 'Linked Census and death occurrences',
            },
        },
        {
            id: '263807',
            summary: {
                abstract:
                    'Information about looked after children. Includes demographic and episode level information.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Children', 'Welsh', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Looked After Children Wales',
            },
        },
        {
            id: '313510',
            summary: {
                abstract:
                    'Patient-level data set that captures information about activity carried out by Maternity Services relating to mother and baby(s), from the first booking appointment until mother and baby(s) are discharged from the services.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: ['Childbirth', 'Mother', 'Maternity', 'Births', 'Baby', 'DIGITRIALS'],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'HUB',
                    name: 'NHS DIGITRIALS',
                },
                title: 'MSDS (Maternity Services Data Set)',
            },
        },
        {
            id: '260345',
            summary: {
                abstract:
                    'The Maternity Indicators Data Set captures data relating to the woman at initial assessment and to mother and baby (or babies) for all births. This relates to initial assessment and birth activity undertaken in Wales only.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Maternity', 'Indicators', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Maternity Indicators Dataset',
            },
        },
        {
            id: '312054',
            summary: {
                abstract:
                    'The Maternity Inpatient and Day Case dataset (SMR02) collects episode level data every time a mother goes in for an obstetric event (this can be an antenatal, delivery or postnatal episode).',
                contactPoint: 'nss.edris@nhs.net',
                keywords: ['NHS Scotland', 'Obstetrics', 'Maternity'],
                publisher: {
                    contactPoint: 'nss.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'SCOTLAND',
                },
                title: 'Maternity Inpatient and Day Case - Scottish Morbidity Record (SMR02)',
            },
        },
        {
            id: '360709',
            summary: {
                abstract:
                    'The Medicines Dispensed in Primary Care (NHSBSA) data comprises prescriptions for medicines that are dispensed or supplied by community pharmacists, appliance contractors and dispensing doctors in England.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'DIGITRIALS',
                    'NCS',
                    'DIGITRIALS',
                    'PRESCRIBING',
                    'MEDICINES',
                    'DISPENSING',
                    'NCS',
                    'NATIONAL CONNECTIVITY STUDY',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'Medicines dispensed in Primary Care (NHSBSA data)',
            },
        },
        {
            id: '360202',
            summary: {
                abstract:
                    'The MHSDS includes patient level clinical and administrative data for patients, who are in contact with NHS funded mental health services in England. This data set defines the data items and values extracted from local electronic patient record systems.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'Mental Health',
                    'Learning Disabilities',
                    'Child and Adolescent Mental Health Services',
                    'CAMHS',
                    'MH',
                    'LD',
                    'Assuring Transformation',
                    'AT',
                    'MHMDS',
                    'MHLDDS',
                    'DIGITRIALS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'HUB',
                    name: 'NHS DIGITRIALS',
                },
                title: 'Mental Health Services Data Set',
            },
        },
        {
            id: '360246',
            summary: {
                abstract:
                    'This dataset contains a subset of information relating to GP contacts in Scotland and the reason for the contact.',
                contactPoint: 'phs.edris@nhs.net',
                keywords: ['GP', 'Contacts'],
                publisher: {
                    contactPoint: 'phs.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'PUBLIC HEALTH SCOTLAND',
                },
                title: 'NHS Scotland General Practice (GP) Contact Data - Subset',
            },
        },
        {
            id: '248121',
            summary: {
                abstract:
                    'The Child Health System in Wales; includes birth registration and monitoring of child health examinations and immunisations.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Child Health', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'National Community Child Health Database (NCCHD)',
            },
        },
        {
            id: '421591',
            summary: {
                abstract:
                    'Audit collects Information about general diabetes care.\r\nData submitted by health care services, relevant to service they provide i.e. Secondary Care Bodies = Type 1, GP practices = Type 2. Includes demographics and diabetes relevant biometric information.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: ['Diabetes', 'Type 2', 'Insulin', 'Type 1', 'Audit', 'DIGITRIALS'],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'ALLIANCE',
                    name: 'NHS DIGITAL',
                },
                title: 'National Diabetes Audit',
            },
        },
        {
            id: '247828',
            summary: {
                abstract:
                    'The National Exercise Referral Scheme (NERS) is a Public Health Wales (PHW) funded scheme which has been in development since 2007. The Scheme targets clients aged 16 and over who have, or are at risk of developing, a chronic disease.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['exercise', 'referral', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'National Exercise Referral Scheme',
            },
        },
        {
            id: '338287',
            summary: {
                abstract: 'All Registrations to the National Records of Scotland of deaths',
                contactPoint: 'nss.edris@nhs.net',
                keywords: ['Scotland ', 'Deaths', 'NCS'],
                publisher: {
                    contactPoint: 'nss.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'SCOTLAND',
                },
                title: 'National Records of Scotland (NRS) - Deaths Data',
            },
        },
        {
            id: '253703',
            summary: {
                abstract:
                    'National Survey for Wales questionnaire responses following consent to link. NSWD superceded Welsh Health Survey in 2015.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['National Survey', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'National Survey for Wales',
            },
        },
        {
            id: '257777',
            summary: {
                abstract:
                    'The NWL A&E linked table is taken from the Secondary Users Service database which contains records of care administered in an A&E setting within NWL. Some of the data items included are dates, referral sources, diagnosis and treatments.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['A&E', 'Accident and Emergency'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Accident and Emergency Data (NWL A&E)',
            },
        },
        {
            id: '257998',
            summary: {
                abstract:
                    'The NWL APC linked table is taken from the Secondary Users Service database which contains records of care administered in an APC setting within NWL. Some of the data items included are admission and discharge dates, diagnosis(ICD10) and procedures(OPCS4)',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['IP', 'APC', 'Spell', 'Inpatient', 'Episode'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Admitted Patient Care Data (NWL APC)',
            },
        },
        {
            id: '258485',
            summary: {
                abstract:
                    'The NWL ASC linked table holds care package data relating to patients registered with a NWL GP. A locally agreed data set between NWL boroughs and commissioners has defined data items such as start and end dates, allocated teams and service descriptions.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['ACS', 'Adult Social Care'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Adult Social Care Data (NWL ASC)',
            },
        },
        {
            id: '247481',
            summary: {
                abstract:
                    'The NWL COVID19 PLD SITREP linked table is a direct daily feed from NWL providers. The table provides the patient level data related to COVID admissions in hospital since the outbreak of the pandemic, includes bed status/ventilation status etc.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['SITREP'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London COVID-19 Patient Level Situation Report (NWL COVID19 PLD SITREP)',
            },
        },
        {
            id: '258588',
            summary: {
                abstract:
                    'The NWL COM linked table holds activity data from Community Providers which contains records of care administered in a Community setting within NWL. Some of the data items included are breastfeeding and nutrition, scored assessments and screening activity',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['Community'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Community Data (NWL COM)',
            },
        },
        {
            id: '247440',
            summary: {
                abstract:
                    'The NWL CMC linked table is an innovative NHS service that builds medical care around the wishes of each patient. Patients registered with a NWL General Practice can create plans with their GP and share with healthcare professionals treating them.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['CMC'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Coordinate My Care (NWL CMC)',
            },
        },
        {
            id: '258362',
            summary: {
                abstract:
                    'The NWL HCD linked table are a number of high cost drugs that are excluded from the Payment by Results (PbR) tariff. They are typically specialist and are delivered to patients within NWL. The data includes details such as drug name, quantity and dosage.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['High Cost Drugs', 'HCD'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London High Cost Drugs Data (NWL HCD)',
            },
        },
        {
            id: '239826',
            summary: {
                abstract:
                    'The NWL ICR links all the tables held within DISCOVER-NOW via a de-identified patient key. This can be used for pathway analysis and population health analysis across care settings as Discover-NOW has data for primary, secondary and social care.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: [
                    'Primary Care Events',
                    'Dataset',
                    'Adult Social Care',
                    'High Cost Drugs',
                    'Primary Care Prescriptions',
                    'Mental Health',
                    'Community',
                    'Patient Level Data',
                    'Integrated Care Record',
                    'Outpatient Care',
                    'Accident and Emergency',
                    'Linked',
                    'Admitted Patient Care',
                ],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Integrated Care Record (NWL ICR)',
            },
        },
        {
            id: '258529',
            summary: {
                abstract:
                    'The NWL MH linked table holds activity data from Mental health providers and contains the type and location of care received, episodes of care, diagnoses and questionnaire scores for individuals who have accessed adult mental health services within NWL.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['MH', 'Mental Health'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Mental Health Data (NWL MH)',
            },
        },
        {
            id: '247550',
            summary: {
                abstract:
                    'The NWL OP linked table is taken from the Secondary Users Service database which contains records of care administered in an OP setting within NWL. Some of the data items included are appointment dates, HRG codes, specialties and referral sources.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['OP', 'Outpatient'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Outpatient Care Data (NWL OP)',
            },
        },
        {
            id: '247519',
            summary: {
                abstract:
                    'The NWL Pathology linked table is a direct feed from The Doctors Laboratory and North West London Pathology for patients registered within NWL. Some of the data items included are test dates and times, test codes and names and test results.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['Pathology'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Pathology (NWL PATH)',
            },
        },
        {
            id: '258394',
            summary: {
                abstract:
                    'The NWL Patient Index linked tables holds demographics for patients registered to a practices within NWL such as patient age, gender, first language and ethnicity. Lower Super Output Area and Index of Multiple Deprivation Rank is also included.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['Patient Level Data', 'PLD'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Patient Index (NWL PI)',
            },
        },
        {
            id: '258432',
            summary: {
                abstract:
                    'The NWL Primary Care Events holds coded interactions between NWL registered patients and GP using EMIS or S1. This includes all clinical and non-clinical events the GPs have recorded for the patient, the data has now got SNOMED codes incorporated.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['General Practice', 'GP'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Primary Care Events Data (NWL PCE)',
            },
        },
        {
            id: '258460',
            summary: {
                abstract:
                    'The NWL Primary Care Prescriptions covers all General Practices in NWL using EMIS or S1. This provides details of supply of prescriptions, issue date, primary care organisation that issued as well as whether it is a repeat or one-off prescription.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['Primary care prescription', 'PCP'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London Primary Care Prescriptions Data (NWL PCP)',
            },
        },
        {
            id: '247458',
            summary: {
                abstract:
                    'The NWL POP table holds the NWL registered patients and key demographic information about them i.e. age, gender, ethinicity etc.',
                contactPoint: 'researchers@registerfordiscover.org.uk',
                doiName: 'In Progress',
                keywords: ['NHAIS'],
                publisher: {
                    contactPoint: 'researchers@registerfordiscover.org.uk',
                    memberOf: 'HUB',
                    name: 'DISCOVER NOW',
                },
                title: 'North West London population data (NWL POP)',
            },
        },
        {
            id: '359687',
            summary: {
                abstract:
                    'Limited OMOP dataset of Hospital COVID patients at the Queen Elizabeth Hospital, Birmingham from 6.01.2020-20.08.2020.  Granular severity, ethnicity, co-morbidity.  Serial acuity, physiology, blood biomarkers, treatments, ITU, outcome. Deeply phenotyped,',
                contactPoint: 'PioneerDIH@UHB.NHS.UK',
                keywords: [
                    'COVID',
                    'Covid-19',
                    'Acuity score',
                    'Coronovirus',
                    'NEWS2',
                    'SEWS',
                    'AVPU',
                    'age',
                    'sex',
                    'ethnicity ',
                    'physiology',
                    'vital signs',
                    'biomarkers',
                    'd-dimer',
                    'troponin',
                    'CRP',
                    'sepsis',
                    'symptoms',
                    'outcomes',
                    'intensive care',
                    'ventilated',
                    'do not resuscitate orders',
                    'secondary care records',
                    'high flow nasal oxygen',
                    'treatments',
                    'antibiotics',
                    'secondary infections',
                    'outcomes',
                    'discharge',
                    'death',
                    'length of stay',
                ],
                publisher: {
                    contactPoint: 'PioneerDIH@UHB.NHS.UK',
                    memberOf: 'HUB',
                    name: 'PIONEER',
                },
                title: 'OMOP dataset: Hospital COVID patients: severity, acuity, therapies, outcomes',
            },
        },
        {
            id: '347133',
            summary: {
                abstract:
                    'A census in the UK is a count of all people and households. The latest census in the UK was held on 27 March 2011.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['SAIL', 'Census', 'Population', 'Resident Population', 'ONS', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'ONS 2011 Census Wales (CENW)',
            },
        },
        {
            id: '311961',
            summary: {
                abstract:
                    'The Outpatients (SMR00) dataset collects episode level data from patients on new and follow up appointments at outpatient clinics in all specialities (except A&E and Genito-Urinary Medicine).',
                contactPoint: 'nss.edris@nhs.net',
                keywords: ['NHS Scotland Outpatients'],
                publisher: {
                    contactPoint: 'nss.edris@nhs.net',
                    memberOf: 'ALLIANCE',
                    name: 'SCOTLAND',
                },
                title: 'Outpatient Appointments and Attendances - Scottish Morbidity Record (SMR00)',
            },
        },
        {
            id: '339464',
            summary: {
                abstract: 'Attendance information for all hospital outpatient appointments.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Outpatient', 'Dataset', 'SAIL', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Outpatient Dataset',
            },
        },
        {
            id: '259064',
            summary: {
                abstract: 'Data on Outpatient referrals from primary care.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Referral', 'Outpatient', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Outpatient Referral',
            },
        },
        {
            id: '402026',
            summary: {
                abstract:
                    'The PANTHER study aims to deepen the understanding of susceptibility to, immunity from and transmission of Sars-Cov2 (the virus that causes Covid-19) in an at risk population.',
                contactPoint: 'Benjamin.Ollivere@nottingham.ac.uk',
                keywords: ['COVID', 'Serology', 'Healthcare workers', 'CO-CONNECT'],
                publisher: {
                    contactPoint: 'Benjamin.Ollivere@nottingham.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'University of Nottingham',
                },
                title: 'PANTHER study',
            },
        },
        {
            id: '259426',
            summary: {
                abstract:
                    'Pathology Test Results and all Radiology reports for Wales. Data coverage differs by geography:\r\n2012 for Swansea (ABMU)\r\n\r\n2017 for Newport (AB)\r\n\r\n2012 for North Wales (BC)\r\n\r\n2007 for Cwm Taf\r\n\r\n2015/16 for Cardiff (CV)\r\n\r\n2014 for West Wales (HD)',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Pathology', 'SAIL', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Pathology Data from WRRS',
            },
        },
        {
            id: '394670',
            summary: {
                abstract:
                    'The database contains all inpatient and day case activity undertaken in NHS Wales plus data on Welsh residents treated in English Trusts.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['PEDW', 'SAIL', 'NCS'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Patient Episode Dataset for Wales',
            },
        },
        {
            id: '257205',
            summary: {
                abstract:
                    'The PDS helps healthcare professionals to identify patients and match them to their health records. It also allows them to contact and communicate with patients.',
                contactPoint: 'enquiries@nhsdigital.nhs.uk',
                keywords: [
                    'NHS Central Register',
                    'Spine',
                    'Demographic',
                    'GP Registrations',
                    'PDS',
                    'NHSCR',
                    'DIGITRIALS',
                ],
                publisher: {
                    contactPoint: 'enquiries@nhsdigital.nhs.uk',
                    memberOf: 'HUB',
                    name: 'NHS DIGITRIALS',
                },
                title: 'Personal Demographic Service',
            },
        },
        {
            id: '259363',
            summary: {
                abstract: 'Reason for cancelled admitted procedures.',
                contactPoint: 'SAILDatabank@swansea.ac.uk',
                doiName: 'In Progress',
                keywords: ['Admitted', 'Procedures', 'Postponed', 'SAIL'],
                publisher: {
                    contactPoint: 'SAILDatabank@swansea.ac.uk',
                    memberOf: 'ALLIANCE',
                    name: 'SAIL',
                },
                title: 'Postponed Admitted Procedures',
            },
        },
        {
            id: '402471',
            summary: {
                abstract:
                    'Near-real time aggregated cancer activity data from 8 major sites across the UK, for the purpose of testing the effect of the COVID-19 pandemic on cancer diagnostic and cancer treatment pathways.',
                contactPoint: 'datacan@uclpartners.com',
                keywords: ['cancer', 'COVID-19', 'coronavirus'],
                publisher: {
                    contactPoint: 'datacan@uclpartners.com',
                    memberOf: 'HUB',
                    name: 'DATA-CAN',
                },
                title: 'Real Time Data Network (RTDN)',
            },
        },
    ],
    currentPage: 1,
    filters: [
        {
            description: 'Publisher',
            name: 'name',
            options: [
                'DATA-CAN',
                'DISCOVER NOW',
                'GENOMICS ENGLAND',
                'HEALTH DATA RESEARCH UK',
                'ISARIC 4C',
                'NHS DIGITAL',
                'NHS DIGITRIALS',
                'Office for National Statistics',
                'PIONEER',
                'PIONEER - HDR-UK Health data Hub in Acute Care',
                'PUBLIC HEALTH SCOTLAND',
                'SAIL',
                'SCOTLAND',
                'TEST',
                'University of Nottingham',
            ],
        },
        {
            description: 'Data Use Limitation',
            name: 'dataUseLimitation',
            options: [
                'GENERAL RESEARCH USE',
                'GENETIC STUDIES ONLY',
                'NO GENERAL METHODS RESEARCH',
                'NO RESTRICTION',
                'RESEARCH SPECIFIC RESTRICTIONS',
                'RESEARCH USE ONLY',
            ],
        },
        {
            description: 'Data Use Requirements',
            name: 'dataUseRequirements',
            options: [
                '',
                'COLLABORATION REQUIRED',
                'ETHICS APPROVAL REQUIRED',
                'GEOGRAPHICAL RESTRICTIONS',
                'INSTITUTION SPECIFIC RESTRICTIONS',
                'NOT FOR PROFIT USE',
                'PROJECT SPECIFIC RESTRICTIONS',
                'PUBLICATION MORATORIUM',
                'PUBLICATION REQUIRED',
                'RETURN TO DATABASE OR RESOURCE',
                'TIME LIMIT ON USE',
                'USER SPECIFIC RESTRICTION',
            ],
        },
        {
            description: 'Keywords',
            name: 'keywords',
            options: [
                'A&E',
                'A&E Department',
                'A&E Waiting Time',
                'Accident',
                'Accident and Emergency',
                'ACS',
                'Active',
                'Acuity',
                'Acuity Score',
                'Acute',
                'Acute hospitals',
                'Admission',
                'Admitted',
                'Admitted Patient Care',
                'Adult Social Care',
                'Age',
                'Airway pressure',
                'Alert',
                'Ambulance',
                'AMU',
                'Antibiotics',
                'Antigen',
                'APC',
                'Appointment',
                'Arrival Method',
                'Assuring Transformation',
                'AT',
                'Attendance',
                'Attendance Type',
                'Audit',
                'AVPU',
                'Babies',
                'Baby',
                'Bacterial',
                'Biomarkers',
                'Birmingham',
                'Birth',
                'Births',
                'Blood',
                'Blood biomarkers',
                'Blood-poisoning',
                'Bowel',
                'Breast',
                'Breathing',
                'Brecon',
                'Business',
                'CAFE',
                'CAFW',
                'CAMHS',
                'Cancellation',
                'CANCER',
                'CARE',
                'Care escalation',
                'Careers Wales',
                'CARW',
                'CAT',
                'CDDS',
                'CDS',
                'Cellulitis',
                'Census',
                'Central nervous system',
                'Cervical',
                'Chest radiographs',
                'Child and Adolescent Mental Health Services',
                'Child Health',
                'Childbirth',
                'Children',
                'Chronic bronchitis',
                'Circulation',
                'Clinical speciality',
                'CMC',
                'CO-CIN',
                'CO-CONNECT',
                'Co-Morbidities',
                'Co-Morbidity',
                'COMMISSIONING DATA SETS',
                'Community',
                'Computer tomography',
                'Computerized axial tomography',
                'Computor tomography',
                'Cone beam computed tomography',
                'Congenital Anomaly',
                'Consolidation',
                'Contacts',
                'COPD',
                'CORONAVIRUS',
                'Coronovirus',
                'Corticosteroids',
                'Covid',
                'COVID-19',
                'COVID-19 Hospitalisation in England Surveillance System',
                'COVID19',
                'CPAP',
                'Critical Care',
                'Critical Care Period',
                'Critical Care Records',
                'CRP',
                'Cystic Fibrosis',
                'D-dimer',
                'Daily',
                'Data',
                'DATA-CAN',
                'Dataset',
                'Day Case',
                'Day cases',
                'Death',
                'Deaths',
                'Deceased',
                'Demographic',
                'Demographics',
                'Demography',
                'Deterioration',
                'Diabetes',
                'Diagnosis',
                'Diagnostic',
                'Diagnostic Image',
                'Diagnostic ultrasonography',
                'Did Not Attend',
                'DIGITRIALS',
                'Disabilities',
                'Discharge',
                'DISPENSING',
                'DNA',
                'Do not resuscitate orders',
                'Do not resusictate orders',
                'DSRD',
                'ECDS',
                'Economic activity',
                'Education',
                'Elective',
                'Emergency',
                'Emphysema',
                'Employment',
                'Endocarditis',
                'England',
                'England Deaths',
                'English Deaths',
                'Episode',
                'Episodes',
                'Ethnicity',
                'Exacerbation',
                'Exercise',
                'External Cause',
                'FAE',
                'FCE',
                'Finished Admission Episode',
                'Finished Consultant Episode',
                'Fluoroscopy',
                'Frailty',
                'Fungal',
                'GDPPR',
                'General Practice',
                'Genome',
                'Genomics',
                'Genotype',
                'GP',
                'GP DATA',
                'GP Registrations',
                'Graduates',
                'GRO',
                'HCD',
                'HDR UK',
                'HDU',
                'Health',
                'Healthcare workers',
                'Heart',
                'Hepatobiliary',
                'HES',
                'High Cost Drugs',
                'High Dependency',
                'High Dependency Unit',
                'High flow nasal oxygen',
                'High flow oxygen',
                'Higher Education',
                'Hospital',
                'Hospital Activity',
                'Hospital episode statistics',
                'HOSPITALISATION',
                'Household',
                'ICCD',
                'ICNARC',
                'ICU',
                'Immune system',
                'Indicators',
                'Infection',
                'Initial Assessment',
                'Inpatient',
                'Inpatients',
                'Inspiratory pressure',
                'Insulin',
                'Integrated Care Record',
                'Intensive Care',
                'Intensive Care Unit',
                'Interventions',
                'IP',
                'ISARIC 4C',
                'Key Worker',
                'Kidney',
                'LABORATORY',
                'LD',
                'Learning Disabilities',
                'Length of stay',
                'Level 2 Critical Care Bed',
                'Level 3 Critical Care Bed',
                'Life-threatening',
                'LINKABLE',
                'Linked',
                'Liver',
                'Long term medical conditions',
                'Longitudinal',
                'Magnetic resonance imaging',
                'Main Speciality',
                'Major Injuries',
                'Maternity',
                'Medical photography',
                'Medical review',
                'MEDICINES',
                'Mental Health',
                'MH',
                'MHLDDS',
                'MHMDS',
                'Microbiology',
                'Minor Injuries',
                'Misuse',
                'MOBILE APP',
                'MOF',
                'Mortality',
                'Mother',
                'MRI',
                'Multi-morbidity',
                'NATIONAL CONNECTIVITY STUDY',
                'NATIONAL CORE STUDY',
                'National Interim Clinical Imaging Procedure Code Set',
                'National Survey',
                'NATIONAL TARIFF',
                'NCS',
                'Nebuliser',
                'Neonatal Care',
                'NEWS2',
                'NHAIS',
                'NHS',
                'NHS Central Register',
                'NHS Scotland',
                'NHS Scotland Outpatients',
                'NHSCR',
                'NICIP',
                'NM',
                'Non-invasive ventilation',
                'NORTHERN IRELAND',
                'Nuclear medicine',
                'Observations',
                'Obstetrics',
                'ONS',
                'OP',
                'Operations',
                'Ordinary',
                'Organ failure',
                'Organ Support',
                'Osteomyelitus',
                'OUTCOME',
                'Outcomes',
                'Outpatient',
                'Outpatient Care',
                'Outpatients',
                'Oxygen',
                'PACS',
                'Paediatric',
                'Papers',
                'Pathology',
                'Patient',
                'Patient Level Data',
                'PbR',
                'PCP',
                'PDS',
                'Pediatric Care',
                'PEDW',
                'PET scan',
                'Physiology',
                'Picture archiving and communication system',
                'PILLAR 1',
                'PILLAR 2',
                'PILLAR 3',
                'Place of review',
                'Plain radiography',
                'Planned',
                'PLD',
                'Pneumonia',
                'Pneumonitis',
                'Population',
                'Positron emission tomography',
                'Postponed',
                'Prednisolone',
                'Pregnancy',
                'Preprints',
                'Prescribing',
                'Prescriptions',
                'Primary care',
                'Primary Care Events',
                'Primary care prescription',
                'Primary Care Prescriptions',
                'Primary care records',
                'Procedure',
                'Procedures',
                'Public Health England',
                'Pulmonary infiltrates',
                'PUPIL',
                'QA',
                'Radiology',
                'Radiology information system',
                'Rare disease',
                'Re-attendance',
                'Referral',
                'Referral to treatment',
                'Registered Deaths',
                'Religion',
                'Resident Population',
                'Respiratory tract',
                'RIS',
                'Risk',
                'Risk Assessments',
                'SAIL',
                'Scan',
                'School',
                'School Workforce',
                'Scotland',
                'Screening',
                'Secondary care records',
                'Secondary infections',
                'Sepsis',
                'Septicaemia',
                'Sequencing',
                'Serial readings',
                'Serology',
                'Severity',
                'SEWS',
                'Sex',
                'SGSS',
                'Shielded',
                'SICSAG',
                'Single photon emission computerized tomography',
                'SITREP',
                'SNOMED',
                'SNOMED CT',
                'SPECT',
                'Spell',
                'Spine',
                'Steroids',
                'Students',
                'Substance',
                'SUS',
                'SUS+',
                'SYMPTOM',
                'Symptoms',
                'Systematized Nomenclature of Medicine Clinical Terms',
                'Teacher',
                'Test',
                'Testing',
                'Therapies',
                'Therapy',
                'Threshold',
                'Tidal volume',
                'Time in A&E',
                'TRACKER',
                'TREATMENT',
                'Treatment Speciality',
                'Treatments',
                'Troponin',
                'Type 1',
                'Type 2',
                'UEC',
                'UHB',
                'Ultrasound',
                'Unplanned Investigation',
                'URGENT AND EMERGENCY CARE',
                'Urgent Care',
                'Urinary tract',
                'US',
                'Ventilated',
                'Viral',
                'Vital signs',
                'Vital-signs',
                'Waiting List',
                'Waiting Times',
                'Wales',
                'Wales Deaths',
                'Wave 1',
                'Wave 2',
                'Welsh',
                'Welsh Deaths',
                'X-Ray',
            ],
        },
        {
            description: 'Physical Sample Availability',
            name: 'physicalSampleAvailability',
            options: [
                'AVAILABILITY TO BE CONFIRMED',
                'BONE MARROW',
                'DNA',
                'FAECES',
                'NOT AVAILABLE',
                'PLASMA',
                'SALIVA',
                'SERUM',
                'TISSUE',
                'URINE',
                'WHOLE BLOOD',
            ],
        },
        {
            description: 'Geographic Coverage',
            name: 'spatial',
            options: [
                'England',
                'England & Wales',
                'England and Wales',
                'England, UK',
                'England, Wales and Isle of Man',
                'England,UK',
                'GB',
                'Great Britain',
                'Https://www.geonames.org/2635167/united-kingdom-of-great-britain-and-northern-ireland.html',
                'Https://www.geonames.org/2638360/scotland.html',
                'NHS Brent CCG; NHS Central London CCG; NHS Ealing CCG; NHS Hammersmith & Fulham CCG; NHS Harrow CCG; NHS Hillingdon CCG; NHS Hounslow CCG; NHS West London CCG',
                'NHS Brent CCG; NHS Central London CCG; NHS Ealing CCG; NHS Hammersmith &Fulham CCG; NHS Harrow CCG; NHS Hillingdon CCG; NHS Hounslow CCG; NHS West London CCG',
                'Nottinghamshire',
                'Scotland',
                'The General / Acute Inpatient and Day Case data covers all residents in Scotland that receive care in hospital and general acute specialities.',
                'The Outpatient Attendance dataset covers all people offered a new or follow up outpatient appointment at a Scottish NHS hospital.',
                'UK',
                'United Kingdom',
                'Wales',
                'Wales, where patient GP Practice has agreed data sharing with SAIL',
                'West Midlands',
                'West Midlands Region, UK',
            ],
        },
    ],
    pageCount: 100,
    pageSize: 100,
    totalCount: 119,
    totalPages: 2,
};
