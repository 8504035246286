const html = `
<!DOCTYPE html>
<!-- saved from url=(0024)https://www.nhsx.nhs.uk/ -->
<html class="no-js" lang="en">


    <body class="template-homepage">

        <footer role="contentinfo">
  <div class="nhsuk-footer" id="nhsuk-footer" data-testid="footer__logo">
    <div class="nhsuk-width-container">
      <h2 class="nhsuk-u-visually-hidden">Support links</h2>
      <ul class="nhsuk-footer__list">
      
        <li class="nhsuk-footer__list-item"><a class="nhsuk-footer__list-item-link" href="#">Accessibility statement</a></li>

      
        <li class="nhsuk-footer__list-item"><a class="nhsuk-footer__list-item-link" href="#">Cookies</a></li>

      
        <li class="nhsuk-footer__list-item"><a class="nhsuk-footer__list-item-link" href="#">Privacy policy</a></li>

      
        <li class="nhsuk-footer__list-item"><a class="nhsuk-footer__list-item-link" href="#">Terms and conditions</a></li>

      
      </ul>
      <p class="nhsuk-footer__copyright">Â© Crown copyright</p>
    </div>
  </div>
</footer>

        

</body></html>`;

export default html;
