export const fieldErrorKeyword = '{fieldError} ';

export const processFieldErrorsResponse = (fetchResult) => {
    const fieldErrorObjectKeys = Object.keys(fetchResult.fieldErrors ? fetchResult.fieldErrors : {});
    if (!fetchResult.fieldErrors || !fieldErrorObjectKeys.length) {
        return [];
    } else {
        return Object.keys(fetchResult.fieldErrors).map((key) => {
            return `${fieldErrorKeyword}${key} ${fetchResult.fieldErrors[key][0]}`;
        });
    }
};

export const processGlobalErrorsResponse = (fetchResult) => {
    if (!fetchResult.globalErrors || !fetchResult.globalErrors.length) {
        return [];
    } else if (fetchResult.globalErrors.length) {
        return [...fetchResult.globalErrors];
    }
};

export const processWarningsResponse = (fetchResult) => {
    if (!fetchResult.warnings || !fetchResult.warnings.length) {
        return [];
    } else if (fetchResult.warnings.length) {
        return [...fetchResult.warnings.map((warning) => 'Warning: ' + warning)];
    }
};

export const processLocalError = (fetchResult) => {
    if (fetchResult.status && fetchResult.error) {
        return [fetchResult.status, fetchResult.error];
    } else if (`${fetchResult}` !== `[object Object]`) {
        console.error(`${fetchResult}`);
        return ['Problem getting response from server'];
    } else {
        return [];
    }
};

//export const resultsResponseMethodGenerator = (successfulResponseMiddleWare, fetchResult) => {
export const resultsResponseMethodGenerator = (callback) => (fetchResult) => {
    const resultObjectKeys = Object.keys(fetchResult.result ? fetchResult.result : {});
    if (!fetchResult.result || !resultObjectKeys.length) {
        return [];
    } else {
        return validateSuccessfulCallbackArrayReturnValue(callback(fetchResult.result));
    }
};

export const processResultMethodGenerator = (callback) => (fetchResult, setResultMessageCallback) => {
    //const processResultsResponse = resultsResponseMethodGenerator(successfulResponseMiddleWare, fetchResult)
    const result = [...callback(fetchResult)];
    setResultMessageCallback([
        ...result,
        ...processWarningsResponse(fetchResult),
        ...processGlobalErrorsResponse(fetchResult),
        ...processFieldErrorsResponse(fetchResult),
        ...processLocalError(fetchResult),
    ]);
    return result[0] === 'Success' ? true : false;
};

const validateSuccessfulCallbackArrayReturnValue = (callBackReturnValue) => {
    if (callBackReturnValue && !Array.isArray(callBackReturnValue)) {
        throw Error(
            'Callback passed to resultsResponseMethodGenerator must either return an array or not return at all.',
        );
    } else {
        return ['Success'];
    }
};
