import { Box, Image, Stack } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import store from '../../../redux/store';
import { triggerForSearchAllDatasetInput } from '../../../redux/actions/actions';
import styled from 'styled-components';
import { searchDarkGrey } from '../../../assets/icons/MDW-icons';
import theme from '../../../style/theme';
import { Input } from '../../MDW/MDW';
import ControlledOpenSelect from '../ControlledOpenSelect/ControlledOpenSelect';
const StyledIcon = styled(Box)`
    cursor: pointer;
`;

const StyledInput = styled(Input)`
    color: black;
    &:focus-within {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
    border-color: black;
    border-radius: ${theme.radii.xlarge} 0px 0px ${theme.radii.xlarge};
    box-sizing: border-box;
    height: 43.5px;
`;

const StyledSubmitButton = styled.button`
    position: relative;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 0px;
    &:focus > div {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

const SearchBar = ({ listStateArray, setEmptyInput, emptyInput, ...props }) => {
    console.log(props.inputValue);
    const ref = React.createRef();
    const inputRef = React.createRef();
    const [inputHeight, setInputHeight] = React.useState();
    const [inputValue, setInputValue] = React.useState(props.inputValue);
    const [render, setRender] = React.useState(false);
    const selector = useSelector((state) => state.triggerForSearchAllDatasetInput);

    React.useEffect(() => {
        setInputHeight(ref.current.clientHeight);
        if (selector === 'clear') {
            setInputValue('');
            setRender(!render);
            store.dispatch(triggerForSearchAllDatasetInput(''));
        }
    }, [setInputHeight, ref]);

    const onChange = React.useCallback((e) => {
        setInputValue(e.target.value);
        setEmptyInput(false);
    }, []);

    const { onSubmit } = props;
    const onSubmitWrapper = React.useCallback(() => {
        if (onSubmit) {
            onSubmit(inputValue);
            //inputRef.current.value = '';
        }
    }, [onSubmit, inputValue, inputRef]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            onSubmitWrapper();
        }
    };
    console.log(emptyInput);
    return (
        <Box direction="row" align="center">
            <Stack fill anchor="left">
                <Box fill ref={ref}>
                    <div style={{ border: emptyInput ? '1px solid red' : '' }}>
                        <StyledInput
                            aria-label="Enter a search term to search all datasets"
                            icon={<div></div>}
                            inputRef={inputRef}
                            name={'search-bar'}
                            type={'text'}
                            placeholder={props.placeholder || 'Search'}
                            onChange={props.onSearchBarChanged || onChange}
                            onKeyPress={handleKeyPress}
                            background="white"
                            colors="black"
                            value={inputValue}
                            title="Enter a search term to search all datasets"
                        />
                    </div>
                </Box>
                <StyledSubmitButton
                    onClick={onSubmitWrapper}
                    data-testid={'search-bar-submit-button'}
                    title="Run search"
                >
                    <StyledIcon
                        background="white"
                        pad="7px"
                        width={`${40}px`}
                        height={`${31}px`}
                        // round={{ size: '1px', corner: 'left' }}
                        margin={{ left: '4px' }}
                    >
                        <Image alt={'Search'} src={searchDarkGrey} width="20px" height="20px"></Image>
                    </StyledIcon>
                </StyledSubmitButton>
            </Stack>
            <div
                style={{
                    backgroundColor: window.config?.branding === 'dsc2' ? '#00594C' : '#303F6C',
                    borderRadius: '0px 12px 12px 0px',
                    height: '44px',
                }}
            >
                <ControlledOpenSelect
                    // submitValues={setAdvancedSearchSubmitValue}
                    key={render}
                    onSubmit={onSubmit}
                    inputValue={inputValue}
                    setEmptyInput={setEmptyInput}
                />
            </div>
        </Box>
    );
};

export default SearchBar;

SearchBar.propTypes = {
    listStateArray: PropTypes.array.isRequired,
    onSubmit: PropTypes.func,
    onSearchBarChanged: PropTypes.func,
    placeholder: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    inputValue: PropTypes.string,
};
