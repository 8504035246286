import { Box, DataTable, Text } from 'grommet';
import React from 'react';
import theme from '../../../../../../style/theme';
import { ToolTip } from '../../../../../MDW/MDW';
import { ElementPopUpContentBlock, StyledGridBox, StyledGridText } from '../DataElementPopup/DataElementPopupUtils';

const DataElementPopUpProfilingTab = ({ popUpItemRefs, profilingData, ...props }) => {
    return (
        <>
            <Box
                pad={{
                    bottom: 'none',
                }}
            >
                <ElementPopUpContentBlock title={''} isAtBottom={profilingData}>
                    <Box
                        pad={{
                            bottom: 'none',
                        }}
                    >
                        <Box direction="row" pad={{ vertical: 'small' }} justify="between">
                            <StyledGridBox data-tip={profilingData[0].key}>
                                <StyledGridText
                                    size="medium"
                                    weight={theme.fontWeights.semibold}
                                    color={`${theme.global.colors.textLightGrey}`}
                                >
                                    {`${profilingData[0].key}: `}
                                </StyledGridText>
                            </StyledGridBox>
                            <StyledGridBox pad={{ right: 'xsmall' }}>
                                <StyledGridText
                                    size="medium"
                                    textAlign="center"
                                    weight={theme.fontWeights.semibold}
                                    color={`${theme.global.colors.textLightGrey}`}
                                >
                                    {`${profilingData[0].value} `}
                                </StyledGridText>
                            </StyledGridBox>
                        </Box>
                        <DataTable
                            columns={[
                                {
                                    property: `key`,
                                    header: (
                                        <Text size="16px" weight={theme.fontWeights.semibold}>
                                            {profilingData[1].key[0]}
                                        </Text>
                                    ),
                                    render: (datum) => (
                                        <Box pad={{ vertical: 'xsmall' }}>
                                            <Text size="small">{datum.key}</Text>
                                        </Box>
                                    ),
                                },
                                {
                                    property: `value`,
                                    header: (
                                        <Text size="16px" weight={theme.fontWeights.semibold}>
                                            {profilingData[1].key[1]}
                                        </Text>
                                    ),
                                    render: (datum) => (
                                        <Box pad={{ vertical: 'xsmall' }}>
                                            <Text size="small">{datum.value}</Text>
                                        </Box>
                                    ),
                                    align: 'center',
                                },
                            ]}
                            data={profilingData.slice(2)}
                        />
                    </Box>
                    <ToolTip />
                </ElementPopUpContentBlock>
            </Box>
        </>
    );
};

export default DataElementPopUpProfilingTab;
