import { TextInput as GrommetInput } from 'grommet';
import { useId } from 'react-id-generator';
import styled from 'styled-components';
import theme from '../../../style/theme';

const StyledInput = styled(GrommetInput)`
    font-weight: ${theme.fontWeights.body};
    font-size: ${theme.fontSizes[2]};
    ${(props: { background?: string }) => props?.background && `background: ${props?.background};`}
    color: ${theme.global!.colors!.primary};
    // border-radius: ${theme.radii.xlarge};
`;

interface InputPropTypes {
    name: string;
    type: string;
    id?: string;
    placeholder?: string;
    inputRef: any;
    background?: string;
}

const Input = ({ name, type, ...props }: InputPropTypes) => {
    const [inputId] = useId();
    return (
        <StyledInput
            {...props}
            ref={props.inputRef}
            name={name}
            type={type}
            placeholder={props.placeholder}
            id={props.id || inputId}
        />
    );
};

export default Input;
