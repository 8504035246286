import { Box, Text } from 'grommet';
import React from 'react';
import theme from '../../../../../../style/theme';
import { StyledListRowLine } from '../../../../../organisms/List/useListRow';
import styled, { css } from 'styled-components';
import {
    ElementPopUpContentBlock,
    ElementPopUpEnumList,
    ElementPopUpTitleValueBlock,
    StyledGridBox,
    StyledGridText,
} from '../DataElementPopup/DataElementPopupUtils';
import * as DOMPurify from 'dompurify';
import { ToolTip } from '../../../../../MDW/MDW';

const StyledBrandTitleText = styled(Text)`
    /*text-transform: capitalize;*/
    word-break: break-word;
    ${(props) =>
        props.underline &&
        css`
            text-decoration: underline;
        `}
`;

const DataElementpopUpContact = ({ ...props }) => {
    const { contactPoint, description, identifier } = props.contact;
    // DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    //     // set all elements owning target to target=_blank
    //     if ('target' in node) {
    //         node.setAttribute('target', '_blank');
    //         node.setAttribute('rel', 'noopener');
    //     }
    // });
    // let desc = DOMPurify.sanitize(description, { USE_PROFILES: { html: true } });
    return (
        <Box
            pad={{
                bottom: 'none',
            }}
        >
            <Box
                round={{ size: '6px', corner: 'bottom' }}
                background={'white'}
                pad={{ top: 'small', horizontal: 'small', bottom: 'medium' }}
                borderTop="none"
                width={'100%'}
            >
                <Box direction="row" justify="evenly" align="center">
                    <Box width="20%">
                        <StyledBrandTitleText size="16px" weight={theme.fontWeights.semibold}>
                            Contact Point
                        </StyledBrandTitleText>
                    </Box>
                    <Box width="40%" pad={'small'}>
                        <StyledBrandTitleText size="small" weight="normal">
                            {contactPoint || '[No Contact Point]'}
                        </StyledBrandTitleText>
                    </Box>
                </Box>
                <Box direction="row" justify="evenly" align="center" width={'100%'}>
                    <Box width="20%">
                        <StyledBrandTitleText size="16px" weight={theme.fontWeights.semibold}>
                            Description
                        </StyledBrandTitleText>
                    </Box>
                    <Box pad={'small'} width="40%">
                        <StyledBrandTitleText size="small" weight="normal">
                            {description || '[No Description]'}
                        </StyledBrandTitleText>
                    </Box>
                </Box>
                <Box direction="row" justify="evenly" align="center" width={'100%'}>
                    <Box width="20%">
                        <StyledBrandTitleText size="16px" weight={theme.fontWeights.semibold}>
                            Identifier
                        </StyledBrandTitleText>
                    </Box>
                    <Box pad={'small'} width="40%">
                        <StyledBrandTitleText size="small" weight="normal">
                            {identifier || '[No Identifier]'}
                        </StyledBrandTitleText>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default DataElementpopUpContact;
