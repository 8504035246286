import { Box, Button, Image, Text } from 'grommet';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import { backButtonGrey } from '../../../assets/icons/MDW-icons';
import useDeepEffect from '../../../hooks/useDeepEffect/useDeepEffect';
import { setBrowserAssetsData } from '../../../redux/actions/actions';
import store from '../../../redux/store';
import theme from '../../../style/theme';
import { Loader } from '../../MDW/MDW';
import {
    DataDownloadInformation,
    FileInfo,
} from '../IndividualDatasetPage/IndividualDatasetPageContents/IndividualDatasetPageContents';
import BrowserAssetsFileRow from './BrowserAssetsFileRow';
// import BrowserAssetsFolderRow from './BrowserAssetsFolderRow';

export const StyledLink = styled.a`
    text-decoration: none;
    color: black;
    &:hover,
    &:focus {
        text-decoration: underline;
        font-weight: 500;
    }
`;

const AccessibleBox = styled(Box)`
    &:focus-within {
        background: ${theme.a11yBoxShadow.colour}44;
    }
    border-radius: ${theme.radii.large};
`;
const AccessibleButton = styled(Button)`
    &:focus-within div span {
        color: ${theme.a11yBoxShadow.colour};
    }
`;

/**
 * Represents a (node of a) File Tree, which can be a Folder or a File.
 * A (node of a) File Tree has a size, a fullPathName, and then the rest of the fields are child nodes
 * e.g.
 {
  "data": {
    "size": 0,
    "fullPathName": "data/",
    "directory1": {
      "size": 0,
      "fullPathName": "data/directory1/",
      "TestFile1.txt": {
        "size": 10,
        "fullPathName": "data/directory1/TestFile1.txt"
      }
    },
    "directory2": {
      "size": 0,
      "fullPathName": "data/directory2/",
      "TestFile2.txt": {
        "size": 21,
        "fullPathName": "data/directory2/TestFile2.txt"
      }
    }
  }
}
 */
type FileTreeNode = {
    size?: number;
    fullPathName?: string;
    id?: number;
} & FileTreeNodeChildren;

type FileTreeNodeChildren = {
    [filename: string]: FileTreeNode;
};

// function getFileTreeNodeChildren(fileTreeNode: FileTreeNode): FileTreeNodeChildren {
//     const { size, fullPathName, ...fileTreeNodeChildren } = fileTreeNode;
//     return fileTreeNodeChildren as FileTreeNodeChildren;
// }

// function isFolder(fileTreeNode: FileTreeNode): boolean {
//     return fileTreeNode.fullPathName?.slice(-1) === '/';
// }

/**
 * Convert
 * from data: [{name: "path/to/file1.txt", size:10},{name: "path/to/file2.txt", size: 20}, {name: "path/file3.txt", size: 40}]
 * to a FileTreeNode.
 * If you remove the size & fullPathName from each node of the FileTreeNode it looks like this:
 * {path:
 *    {to:
 *      {file1.txt:{}, file2.txt:{}}
 *    },
 *    file3.txt:{}}
 * }
 * @param data
 */
function dataDownloadInformationToFileTreeNode(data?: DataDownloadInformation): FileTreeNode {
    let result: FileTreeNode = {};
    // This is some interesting imperative-functional programming stuff...
    data!.files!.forEach((fileInfo: FileInfo) => {
        const pathSteps = fileInfo.name!.split('/');
        // pathSteps might be e.g. ["path"], ["path", "to"], or ["path", "to", "file1.txt"] (ideally in that order but if not we can handle it)
        pathSteps.reduce((previousNode: FileTreeNode, currentPathStep: string, currentIndex: number) => {
            if (currentPathStep === '') return {};
            // e.g. previousNode = {}, currentPathStep = "path", nextNode = { ...information for "path"...}
            const nextNode =
                previousNode[currentPathStep] ||
                (currentIndex === pathSteps.length - 1
                    ? {
                          // last pathStep -- could be a file or a folder
                          size: typeof fileInfo.size === 'string' ? parseInt(fileInfo.size) : fileInfo.size,
                          fullPathName: fileInfo.name,
                      }
                    : {
                          // not last pathStep -- therefore it's a folder (so needs a trailing slash)
                          size: 0,
                          fullPathName:
                              fileInfo.name
                                  .split('/')
                                  .slice(0, currentIndex + 1)
                                  .join('/') + '/',
                      });
            previousNode[currentPathStep] = nextNode;
            return nextNode; // nextNode will become previousNode in the next iteration of reduce (with the next currentPathStep)
        }, result);
    });

    return result;
}

const StyledBackButton = styled(Button)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:focus-within div img {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

function Breadcrumb(props: { navigateToPlaceInStack: (level?: number) => void; navigationStack: string[] }) {
    return (
        <Box direction="row" margin="xsmall">
            <AccessibleButton
                plain
                onClick={() => props.navigationStack.length !== 0 && props.navigateToPlaceInStack(0)}
                data-testid="Home Breadcrumb"
                label={
                    <Box>
                        <Text>{`Home`}</Text>
                    </Box>
                }
            />
            {props.navigationStack.map((navigationItem: string, index: number) => (
                <Box direction="row" key={index}>
                    <Box pad={{ left: '1ch' }}>
                        <Text>{`>`}</Text>
                    </Box>
                    <AccessibleButton
                        plain
                        data-testid={`Breadcrumb ${index + 1}`}
                        onClick={() =>
                            !(props.navigationStack.length === index + 1) && props.navigateToPlaceInStack(index + 1)
                        }
                        label={
                            <Box pad={{ left: '1ch' }}>
                                <Text wordBreak="break-word">{`${navigationItem}`}</Text>
                            </Box>
                        }
                    />
                </Box>
            ))}
        </Box>
    );
}

// type NamedFileTreeNode = [string, FileTreeNode];

// const compareNamedFileTreeNodes: (a: NamedFileTreeNode, b: NamedFileTreeNode) => number = (
//     [fileTreeNodeNameA, fileTreeNodeA],
//     [fileTreeNodeNameB, fileTreeNodeB],
// ) => {
//     if (isFolder(fileTreeNodeA)) {
//         console.log(fileTreeNodeA);

//         if (isFolder(fileTreeNodeB)) {
//             return fileTreeNodeNameA.localeCompare(fileTreeNodeNameB) as number;
//         } else {
//             // A is folder, B is file, A < B (A before B)
//             return -1;
//         }
//     } else {
//         if (isFolder(fileTreeNodeB)) {
//             // A is file, B is folder, so B < A (B before A), which is to say, A > B
//             return 1;
//         } else {
//             return fileTreeNodeNameA.localeCompare(fileTreeNodeNameB) as number;
//         }
//     }
// };

const BrowserAssets = ({ data }: { data?: any }) => {
    const [currentFileTreeNode, setCurrentFileTreeNode] = useState({});
    const [navigationStack, setNavigationStack] = useState<string[]>([]);
    const { files } = data;
    const navigateToPlaceInStack = (level = -1) => {
        let newCurrentFileTreeNode = store.getState().s3Data;
        let newStack = navigationStack?.slice(0, level);
        for (let index of newStack) {
            newCurrentFileTreeNode = newCurrentFileTreeNode[index];
        }
        setNavigationStack(newStack);
        setCurrentFileTreeNode(newCurrentFileTreeNode);
    };

    const navigateBackward = () => navigateToPlaceInStack();

    useDeepEffect(() => {
        let topLevelFileTreeNode = dataDownloadInformationToFileTreeNode(data);
        setCurrentFileTreeNode(topLevelFileTreeNode);
        store.dispatch(setBrowserAssetsData(topLevelFileTreeNode));
    }, [data]);

    // const currentFileTreeNodeChildren: FileTreeNodeChildren = getFileTreeNodeChildren(currentFileTreeNode);

    // const sortedNamedFileTreeNodes: NamedFileTreeNode[] =
    //     Object.entries(currentFileTreeNodeChildren)?.sort(compareNamedFileTreeNodes);

    return isEmpty(currentFileTreeNode) ? (
        <Loader />
    ) : (
        <Box fill>
            <Breadcrumb navigationStack={navigationStack} navigateToPlaceInStack={navigateToPlaceInStack}></Breadcrumb>
            {navigationStack.length > 0 && (
                <StyledBackButton
                    plain
                    data-testid="Navigation Back Button"
                    label={
                        <Box pad="small">
                            <Image alt={'Search'} src={backButtonGrey} width="20px" height="20px"></Image>
                        </Box>
                    }
                    onClick={navigateBackward}
                />
            )}
            <Box border={{ color: 'grey', side: 'between' }} gap="xsmall">
                {files.map((file: any) => {
                    return (
                        <AccessibleBox
                            justify="center"
                            pad="xsmall"
                            margin={{ horizontal: 'xsmall' }}
                            key={file.id}
                            flex={{ grow: 0, shrink: 0 }}
                        >
                            {/* {isFolder(fileTreeNode) ? (
                                <Button
                                    plain
                                    data-testid="Folder Navigation Button"
                                    onClick={() => {
                                        setNavigationStack((previousNavigationStack: string[]) => {
                                            return [...previousNavigationStack, fileTreeNodeName];
                                        });
                                        setCurrentFileTreeNode(currentFileTreeNode[fileTreeNodeName]);
                                    }}
                                    label={<BrowserAssetsFolderRow fileTreeNodeName={fileTreeNodeName} />}
                                />
                            ) : (
                                <BrowserAssetsFileRow
                                    size={fileTreeNode.size || 0}
                                    fileTreeNodeName={fileTreeNodeName}
                                    fullPathName={fileTreeNode.fullPathName || ''}
                                    linkStem={data?.location}
                                    //@ts-ignore: Unreachable code error
                                    id={data.files[0].id}
                                ></BrowserAssetsFileRow>
                            )} */}
                            <BrowserAssetsFileRow
                                size={file.size || 0}
                                fileTreeNodeName={file.name}
                                fullPathName={file.name || ''}
                                linkStem={data?.location}
                                //@ts-ignore: Unreachable code error
                                id={file.id}
                            ></BrowserAssetsFileRow>
                        </AccessibleBox>
                    );
                })}
            </Box>
        </Box>
    );
};

BrowserAssets.whyDidYouRender = true;

export default BrowserAssets;
