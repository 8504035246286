import React from 'react';
import { useLocation } from 'react-router';
import { HistoryLocation } from '../utils/TypeUtilities';

export type LocationAspect = string;
export type LocationToLocationAspect = (location: HistoryLocation) => LocationAspect;

type LocationAspectFunctions = {
    locationPathname: LocationToLocationAspect;
    locationPathnameSearch: LocationToLocationAspect;
    locationSearch: LocationToLocationAspect;
};
export const locationAspectFunctions: LocationAspectFunctions = {
    /**
     * Do an action whenever the search location changes,
     * e.g. "/browser/search"
     * ===> "/browser/dataset"
     * @param location
     */
    locationPathname: (location) => {
        return location.pathname;
    },
    /**
     * Do an action whenever the pathname + search query changes,
     * e.g. "/browser/search?search-term=testA"
     * ===> "/browser/search?search-term=testB"
     * @param location
     */
    locationPathnameSearch: (location) => {
        return location.pathname + location.search;
    },
    /**
     * Do an action whenever the search query changes,
     * e.g. "?search-term=testA"
     * ===> "?search-term=testB"
     * @param location
     */
    locationSearch: (location) => {
        return location.search;
    },
};

type LocationAction = (location: HistoryLocation) => void;
const useActionWhenLocationAspectChanges = (
    action: LocationAction,
    locationToLocationAspect: LocationToLocationAspect,
) => {
    const location: HistoryLocation = useLocation();
    const locationAspect: LocationAspect = locationToLocationAspect(location);
    React.useEffect(() => {
        action(location);
        // We don't want the effect to fire every time `location` changes, but rather every time `locationAspect` changes,
        // so we have `locationAspect` rather than `location` in the dependency array:
        // eslint-disable-next-line
    }, [action, locationAspect]);
};

export default useActionWhenLocationAspectChanges;
