import { Box, ResponsiveContext, Text } from 'grommet';
import { FormNext, FormPrevious } from 'grommet-icons';
import PropTypes from 'prop-types';
import { React, useContext, useState } from 'react';
import Youtube from 'react-youtube';
import theme from '../../../style/theme';
import { youTubeRegExp1 as regExp1 } from '../../../utils/javascriptUtilities';
import { youTubeRegExp2 as regExp2 } from '../../../utils/javascriptUtilities';
import { Button, Loader } from '../../MDW/MDW';
import { Layer, Video, VideoOverlayCloseButton, VideoOverlayHideableBox } from './utils';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    &:focus {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

const StyledBox = styled(Box)`
    background-color: #00000080;
    border-radius: ${theme.radii.small};
`;

const VideoOverlay = ({ videoArray, show, setShow, ...props }) => {
    const [vidIndex, setVidIndex] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    const setLoaded = () => setIsLoaded(true);
    const setNotLoaded = () => setIsLoaded(false);

    const size = useContext(ResponsiveContext);

    const hasNext = () => {
        return vidIndex < videoArray.length - 1;
    };
    const hasPrevious = () => {
        return vidIndex > 0;
    };

    const onLeftButtonClick = () => {
        setNotLoaded();
        setVidIndex(vidIndex - 1);
    };

    const onRightButtonClick = () => {
        setNotLoaded();
        setVidIndex(vidIndex + 1);
    };

    const onCloseButtonClick = () => setShow(false);

    const match = videoArray.length !== 0 && videoArray[vidIndex].match(regExp1) && videoArray[vidIndex].match(regExp2);

    const opts = {
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            modestBranding: 1,
            playsInLine: 0,
            rel: 0,
            autoplay: 0,
        },
    };

    return (
        <Box className="video-overlay-box">
            {show && (
                <Layer
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}
                    size={size}
                    className="video-overlay-layer"
                >
                    {videoArray.length === 0 ? (
                        <Box justify="center" align="center">
                            <Box justify="end">
                                <VideoOverlayCloseButton onCloseButtonClick={onCloseButtonClick} />

                                <Box
                                    round={theme.radii.large}
                                    width="500px"
                                    height="350px"
                                    background="black"
                                    direction="row"
                                    justify="center"
                                    align="center"
                                >
                                    <Text>No Video Given</Text>
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <>
                            {!isLoaded && (
                                <Box justify="center" align="center" id="LoaderTest" data-testid="video-overlay-loader">
                                    <Loader />
                                </Box>
                            )}
                            <VideoOverlayHideableBox
                                isVisible={isLoaded}
                                data-value={isLoaded}
                                justify="center"
                                direction="row"
                                className="video-overlay-hideable-box layer-content"
                                data-testid="layer-content"
                            >
                                <StyledBox
                                    direction="row"
                                    justify="around"
                                    gap="xsmall"
                                    width={{ min: 'medium', max: 'large' }}
                                >
                                    <StyledButton
                                        plain
                                        a11yTitle="Previous Video"
                                        disabled={!hasPrevious()}
                                        label={<FormPrevious color="white" size="large" />}
                                        onClick={onLeftButtonClick}
                                    />
                                    <Box className="video-and-close-button">
                                        <VideoOverlayCloseButton onCloseButtonClick={onCloseButtonClick} />
                                        {match && match[7].trim().length === 11 ? (
                                            <VideoOverlayHideableBox
                                                isVisible={isLoaded}
                                                className="video-overlay-hideable-box youtube-video-player"
                                                data-testid="youtube-video-player"
                                            >
                                                <Youtube
                                                    id={match[7].trim()}
                                                    videoId={match[7].trim()}
                                                    opts={opts}
                                                    onReady={(event) => {
                                                        setLoaded();
                                                    }}
                                                    onStateChange={(event) => {
                                                        if (event.data === Youtube.PlayerState.CUED) {
                                                            setLoaded();
                                                        }
                                                    }}
                                                />
                                            </VideoOverlayHideableBox>
                                        ) : (
                                            <Video
                                                aria-label="Video Player"
                                                controls="over"
                                                key={videoArray[vidIndex]}
                                                onLoadedData={setLoaded}
                                            >
                                                <source key="video" src={videoArray[vidIndex]} type="video/mp4" />
                                                <track key="cc" label="English" kind="subtitles" srcLang="en" default />
                                            </Video>
                                        )}
                                    </Box>
                                    <StyledButton
                                        plain
                                        a11yTitle="Next Video"
                                        disabled={!hasNext()}
                                        label={<FormNext color="white" size="large" />}
                                        onClick={onRightButtonClick}
                                    />
                                </StyledBox>
                            </VideoOverlayHideableBox>
                        </>
                    )}
                </Layer>
            )}
        </Box>
    );
};

VideoOverlay.propTypes = {
    videoArray: PropTypes.arrayOf(PropTypes.string).isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
};

export default VideoOverlay;
