import React from 'react';
import PropTypes from 'prop-types';
import { Button, MessageModal } from '../../MDW/MDW';
import theme from '../../../style/theme';
import { Formik, Form } from 'formik';
import formUtils from './utils';
import { fieldErrorKeyword } from '../../../utils/globalReponseHandlers';
import { useMessageHub } from '../../molecules/MessageHub/MessageHubProvider';
import { Box, Text } from 'grommet';

const FormContainerFieldsContext = React.createContext();

const FormContainerFields = ({
    children,
    initialValues,
    validationSchema,
    successMessage,
    fetchCall,
    isButtonDisabled,
    ...props
}) => {
    const { buttonText, showNotification } = props;
    const utils = formUtils(fetchCall, props.preFetchMiddlewareMethod, props.postFetchMiddlewareMethod);

    const isMountedRef = React.useRef(null);
    const [resultMessageArray, setResultMessageArray] = React.useState([]);

    const { createNotificationMessage } = useMessageHub();

    const checkIfMounted = React.useCallback(() => {
        return isMountedRef.current !== null;
    }, []);

    const setMessageArray = React.useCallback(
        (messageArray) => {
            checkIfMounted() && setResultMessageArray(messageArray);
        },
        [checkIfMounted],
    );

    const processFetchResult = React.useCallback(
        (fetchResult) => {
            const isSuccessful = utils.processResult(fetchResult, setMessageArray);
            if (isSuccessful) {
                showNotification !== false && createNotificationMessage(successMessage);
            }
        },
        [createNotificationMessage, setMessageArray, successMessage, utils, showNotification],
    );

    const onAnyInputFocused = React.useCallback(
        (e) => {
            resultMessageArray.length > 0 && setResultMessageArray([]);
        },
        [resultMessageArray, setResultMessageArray],
    );

    const resultMessageFilter = React.useCallback((message) => message.includes(fieldErrorKeyword), []);

    return (
        <Box ref={isMountedRef}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={utils.onSubmit(processFetchResult)}
            >
                {(props) => (
                    <Form>
                        <FormContainerFieldsContext.Provider
                            value={{
                                onAnyInputFocused,
                                formikSetFieldValue: props.setFieldValue,
                            }}
                        >
                            {children}
                        </FormContainerFieldsContext.Provider>
                        <Box direction="row" flex justify="between" align="start">
                            <Box width={'100%'}>
                                <Box
                                    margin={{
                                        top: 'small',
                                    }}
                                    alignSelf="start"
                                >
                                    {resultMessageArray.length > 0 &&
                                        resultMessageArray.map((message, index) => (
                                            <Text
                                                key={`corg-result-${index}`}
                                                size="xsmall"
                                                color={theme.global.colors.error}
                                            >
                                                {message && `${message.replace(fieldErrorKeyword, '')}.`}
                                            </Text>
                                        ))}
                                </Box>
                            </Box>
                        </Box>
                        <Button
                            disabled={isButtonDisabled}
                            width="100%"
                            type="submit"
                            margin={{
                                top: resultMessageArray ? 'small' : 'medium',
                                bottom: 'medium',
                            }}
                            label={buttonText || 'Submit'}
                        />
                    </Form>
                )}
            </Formik>
            {checkIfMounted() && (
                <MessageModal
                    messageArray={resultMessageArray}
                    subStringToRemove={fieldErrorKeyword}
                    filterFunction={resultMessageFilter}
                />
            )}
        </Box>
    );
};

FormContainerFields.whyDidYouRender = true;

FormContainerFields.propTypes = {
    initialValues: PropTypes.object.isRequired,
    validationSchema: PropTypes.object.isRequired,
    successMessage: PropTypes.string.isRequired,
    fetchCall: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    buttonText: PropTypes.string,
    notificationTimeout: PropTypes.number,
    preFetchMiddlewareMethod: PropTypes.func,
    postFetchMiddlewareMethod: PropTypes.func,
    showNotification: PropTypes.bool,
    isButtonDisabled: PropTypes.bool,
};

export default FormContainerFields;

export const useFormContainerFields = () => {
    const context = React.useContext(FormContainerFieldsContext);

    if (context === undefined) {
        throw new Error('useFormContainerFields must be used within a FormContainerFieldsProvider');
    }

    return context;
};
