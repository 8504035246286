import { Box, BoxTypes, ButtonProps, DropButtonProps, Text } from 'grommet';
import PropTypes from 'prop-types';
import { MouseEventHandler, useState } from 'react';
import theme from '../../../style/theme';
import { StyledDropdown, StyledGrommetDropdownButton } from './utils';

interface DropdownProps extends DropButtonProps, ButtonProps {
    callback: MouseEventHandler<HTMLButtonElement>;
    items: Array<number | string>;
    defaultItem?: string;
    title: string;
    plain?: boolean;
    boxProps?: BoxTypes;
}

const Dropdown = (props: DropdownProps) => {
    const { callback, items, defaultItem, title } = props;
    const [isDropOpen, setisDropOpen] = useState(false);
    const toggleDrop = () => setisDropOpen(!isDropOpen);
    const selected = defaultItem || items[0];
    const select = (e: any) => {
        callback(e);
        setisDropOpen(false);
    };

    return (
        <Box {...props.boxProps}>
            <StyledDropdown
                a11yTitle={`${title} Button`}
                plain={props.plain}
                primary
                open={isDropOpen}
                onClick={toggleDrop}
                color={props.plain ? 'transparent' : 'lightestBlue'}
                dropAlign={props.dropAlign ? props.dropAlign : { top: 'bottom', right: 'right' }}
                dropProps={{
                    plain: true,
                    stretch: false,
                    onClickOutside: toggleDrop,
                    onEsc: toggleDrop,
                }}
                label={
                    props.label || (
                        <Box pad="2px" a11yTitle={`Button Label`}>
                            <Text size="xsmall" alignSelf="start" weight={theme.fontWeights.bold}>
                                {title}
                            </Text>
                        </Box>
                    )
                }
                dropContent={
                    <Box width={{ min: '70px' }} a11yTitle={'Dropdown Content'}>
                        {items.map((item, index) => {
                            return (
                                <StyledGrommetDropdownButton
                                    a11yTitle={`Dropdown Item ${index + 1}: ${item} Button`}
                                    color={
                                        theme.global.colors[window.localStorage.getItem('branding') || 'default']
                                            ?.mainBackgroundColor
                                    }
                                    primary
                                    onClick={select}
                                    key={index}
                                    margin={
                                        index === 0
                                            ? props.plain
                                                ? { top: '5px' }
                                                : { top: 'xsmall' }
                                            : { top: '0px' }
                                    }
                                    label={
                                        <Box
                                            pad={{
                                                vertical: '2px',
                                                horizontal: '8px',
                                            }}
                                        >
                                            <Text
                                                size="xsmall"
                                                alignSelf="start"
                                                weight={
                                                    `${item}` === `${selected}`
                                                        ? theme.fontWeights.bold
                                                        : theme.fontWeights.body
                                                }
                                            >
                                                {item}
                                            </Text>
                                        </Box>
                                    }
                                />
                            );
                        })}
                    </Box>
                }
            />
        </Box>
    );
};

Dropdown.propTypes = {
    plain: PropTypes.bool,
    dropAlign: PropTypes.object,
    callback: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    defaultItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Dropdown;
