import { Box } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../../style/theme';
const GenericPageContentWrapper = ({ children, ...props }) => {
    let adrConfig = window.config.branding === 'adr';
    return (
        <Box
            fill
            flex
            elevate="small"
            background={theme.global.colors[window.localStorage.getItem('branding') || 'default']?.mainBackgroundColor}
            pad={{ top: 'large', bottom: '', left: 'large', right: 'large' }}
            as="main"
        >
            {children}
        </Box>
    );
};

GenericPageContentWrapper.whyDidYouRender = true;

GenericPageContentWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default GenericPageContentWrapper;
