import queryString from 'query-string';
export const xApiVersion = '2.0';
export const getDataSets = () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var api = url.origin + '/api';
    return fetch(
        `${window?.config?.reactAppDatasetApi || api}/dataset?${extractParameters()}`.replace(
            /searchterm/,
            'searchTerm',
        ),
        {
            method: 'get',
            headers: {
                'X-API-Version': xApiVersion,
            },
        },
    );
};

export const extractParameters = () => {
    const parameters = queryString.parse(document.location.search);
    const result =
        Object.keys(parameters).length > 0
            ? Object.keys(parameters).reduce((acc, key) => `${acc}&${key}=${encodeURIComponent(parameters[key])}`, '')
            : '';
    return result;
};
