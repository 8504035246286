import { Box } from 'grommet';
import styled from 'styled-components';
import theme from '../../../../style/theme';
import { BoxTypes } from 'grommet/components/Box';
import { Dataset } from './IndividualDatasetPageContents';
import { MenuItems } from './IndividualDatasetPageTabs';
import { camelCaseConverter } from '../IndividualDatasetPageComponents/DescriptiveMetadata/DescriptiveMetadataUtils';

type StyledBoxProps = BoxTypes & { classTitle: boolean };
export const StyledMetadataTitleBox = styled(Box)<StyledBoxProps>`
    box-shadow: 0px 5px 5px -1px #00000030;
    z-index: 999;
    position: relative;
    border: solid 1px ${theme?.global?.colors?.grey}61;
    border-bottom: none;
    ${(props) =>
        props.classTitle &&
        `
  border-top: solid 1px ${theme?.global?.colors?.grey}47;
  `}
`;

export const IndividualDatasetPageContentsBox = styled(Box)`
    z-index: 0;
    margin-bottom: 50px;
`;
let dataModelType: any;
export const extractSearchMenuLabels: (dataSet: Dataset) => MenuItems = (dataSet) => {
    const { id, dataStatus, ...everythingButIdAndDataStatus } = dataSet;
    // delete dataModel type on Tabs temporary
    dataModelType = everythingButIdAndDataStatus.dataModelType;
    delete everythingButIdAndDataStatus.dataModelType;

    const menuItems: MenuItems = Object.keys(everythingButIdAndDataStatus).map((key) => {
        return camelCaseConverter(key) as string;
    });
    return menuItems;
};

export const individualDatasetPageTabRenaming: (menuItems: MenuItems) => MenuItems = (menuItems) => {
    return menuItems.map((menuItem) => {
        if (menuItem === dataTabName) {
            return dataDownloadTabName;
        } else if (menuItem === structuralMetadataTabName) {
            if (dataModelType === 'CONCEPTUAL') {
                return glossary;
            }

            return terminology;
        } else {
            return menuItem;
        }
    });
};

export const structuralMetadataTabName = 'Structural Metadata';
export const dataDictionaryTabName = 'Data Dictionary';
export const terminology = 'Data Dictionary';
export const glossary = 'Terminology';
export const dataTabName = 'Data';
export const dataDownloadTabName = 'Data Download';
