import { Anchor, Box, Image, ResponsiveContext, Text } from 'grommet';
import { CatalogOption, ChatOption, Chat, HelpOption, Accessibility } from 'grommet-icons';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
    thinArrowLeftWhite,
    thinArrowRightWhite,
    onboardingWhite,
    onboardingBlack,
    importerWhite,
    importerBlack,
    managerWhite,
    managerBlack,
    catalogueWhite,
    catalogueBlack,
    logoWhite,
    logoBlack,
    outlineContactSupportWhite,
    outlineContactSupportBlack,
    commentAltSolid,
    BookIconBlack,
    CommentIconBlack,
    QuestionIconBlack,
    CommentDotsIcon,
    CommentDotsIconBlack,
    AccessibilityIconBlack,
    BookIconWhite,
    CommentIconWhite,
    AccessibilityIconWhite,
} from '../../../assets/icons/MDW-icons';
import theme from '../../../style/theme';
import { Button, ToolTip } from '../../MDW/MDW';
import { sidebarTitles } from '../../templates/GenericPageTemplate/GenericPageTemplate';

const StyledNav = styled(Box)`
    position: relative;
`;

const StyledMenuButtonBox = styled(Box)`
    position: absolute;
    right: -12px;
    top: 300px;
    &:focus-within {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

const StyledMenuButtonArrowImage = styled(Image)`
    width: 4px;
    transform: translate(${(props) => (props.arrowDirection === 'right' ? `0` : `-1`)}px, 0px);
`;

const StyledAnchor = styled(Anchor)`
    width: 100%;
    &:hover {
        text-decoration: underline;
        color: white;
    }
    &:focus-within {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 2px ${theme.global.colors.secondary};
    }
`;
const LinksWrapper = styled(Box)`
    &:focus-within {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 2px #6fcdbd;
    }
`;
const StyledAnchorLinks = styled(Box)`
    width: 100%;
    color: white;
    font-size: 0.8rem;
    font-weight: normal;
    margin-left: 10px;
`;
const StyledAnchorLink = styled.a`
    color: ${(props) => props.textColor};
`;
const StyledBoxWithLinks = styled(Box)`
    width: 100%;
    &:hover {
        text-decoration: underline;
        color: white;
    }
    &:focus-within {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 2px ${theme.global.colors.secondary};
    }
`;

const ActiveApplicationIndicator = styled(Box)`
    height: 50px;
    background-color: white;
    width: 4px;
    align-items: stretch;
`;

const ActiveLinksStyled = styled(Box)`
    z-index: 9999;
    position: absolute;
    background-color: ${(props) => props.bg};
    min-width: 260px;
    min-height: 50px;
    border-radius: 10px;
    top: -46px;
    left: 80px;
    display: flex;
`;

const StyledText = styled(Text)`
    padding-left: 8px;
    padding-right: 8px;
`;

const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px;
    &:hover {
        border-radius: 6px;
        box-shadow: 0 0 0 2px #6fcdbd;
    }
`;

const ApplicationIconLink = (props) => {
    const applicationIconInner = (
        <Box
            align="center"
            height="auto"
            gap="xsmall"
            data-tip={props.iconLabel}
            pad={
                props.applicationIconInnerPadding
                    ? props.applicationIconInnerPadding
                    : props.size && (props.size === 'small' ? { horizontal: 'medium' } : { horizontal: 'small' })
            }
        >
            <Box width="25px" height="25px">
                <img src={props.imgSrc} alt={props.altText} />
            </Box>
            <StyledText size="small" color={props.adrConfig ? 'black' : 'white'} weight={400}>
                {props.iconLabel}
            </StyledText>
        </Box>
    );
    const anchorLabel = props.active ? (
        <Box direction="row" align="center">
            <ActiveApplicationIndicator />
            {applicationIconInner}
        </Box>
    ) : (
        applicationIconInner
    );
    return props?.istargetBlank ? (
        <StyledAnchor href={props.href} label={anchorLabel} target="_blank" />
    ) : (
        <StyledAnchor href={props.href} label={anchorLabel} />
    );
};
const Links = (props) => {
    return (
        props.helpbar && (
            <ActiveLinksStyled
                bg={theme.global.colors[window.localStorage.getItem('branding')].navbarHelpItemBackgroundColor}
            >
                <div style={{ margin: '10px', color: 'darkblue' }}>
                    {window?.config?.faqLink && (
                        <StyledWrapper>
                            <img src={QuestionIconBlack} style={{ width: '6%', color: 'darkblue' }} />
                            <StyledAnchorLinks>
                                <StyledAnchorLink
                                    textColor={
                                        window.localStorage.getItem('branding') !== 'adr' &&
                                        window.localStorage.getItem('branding') !== 'dsc2'
                                            ? 'white'
                                            : 'black'
                                    }
                                    href={window?.config?.faqLink}
                                    target="_blank"
                                >
                                    FAQs
                                </StyledAnchorLink>
                            </StyledAnchorLinks>
                        </StyledWrapper>
                    )}
                    <StyledWrapper>
                        <img
                            src={
                                window.localStorage.getItem('branding') !== 'adr' &&
                                window.localStorage.getItem('branding') !== 'dsc2'
                                    ? BookIconWhite
                                    : BookIconBlack
                            }
                            style={{ width: '5%', color: 'darkblue' }}
                        />
                        <StyledAnchorLinks>
                            <StyledAnchorLink
                                textColor={
                                    window.localStorage.getItem('branding') !== 'adr' &&
                                    window.localStorage.getItem('branding') !== 'dsc2'
                                        ? 'white'
                                        : 'black'
                                }
                                href={window?.config?.knowledgeBaseLink}
                                target="_blank"
                            >
                                Knowledge Base
                            </StyledAnchorLink>
                        </StyledAnchorLinks>
                    </StyledWrapper>
                    <StyledWrapper>
                        <img
                            src={
                                window.localStorage.getItem('branding') !== 'adr' &&
                                window.localStorage.getItem('branding') !== 'dsc2'
                                    ? CommentIconWhite
                                    : CommentIconBlack
                            }
                            style={{ width: '6%', color: 'darkblue' }}
                        />
                        <StyledAnchorLinks>
                            <StyledAnchorLink
                                textColor={
                                    window.localStorage.getItem('branding') !== 'adr' &&
                                    window.localStorage.getItem('branding') !== 'dsc2'
                                        ? 'white'
                                        : 'black'
                                }
                                href={window?.config?.serviceDeskLink}
                                target="_blank"
                            >
                                Service Desk
                            </StyledAnchorLink>
                        </StyledAnchorLinks>
                    </StyledWrapper>
                </div>
            </ActiveLinksStyled>
        )
    );
};

const HelpLink = (props) => {
    const applicationIconInner = (
        <Box
            align="center"
            height="auto"
            gap="xsmall"
            data-tip={props.iconLabel}
            pad={
                props.applicationIconInnerPadding
                    ? props.applicationIconInnerPadding
                    : props.size && (props.size === 'small' ? { horizontal: 'medium' } : { horizontal: 'small' })
            }
        >
            <Box width="25px" height="auto">
                <Image src={props.imgSrc} aria-hidden alt={props.altText} />
            </Box>
            <StyledText size="small" color={props.adrConfig ? 'black' : 'white'} weight={400}>
                {props.iconLabel}
            </StyledText>
        </Box>
    );

    const anchorLabel = props.active ? (
        <Box direction="row" align="center">
            <ActiveApplicationIndicator />
            {applicationIconInner}
        </Box>
    ) : (
        applicationIconInner
    );
    return <StyledBoxWithLinks>{applicationIconInner}</StyledBoxWithLinks>;
};

const Navbar = React.memo(({ showSidebar, setShowSidebar, isSideBarEmpty, ...props }) => {
    const size = React.useContext(ResponsiveContext);
    const onClickMenuButton = React.useCallback(() => setShowSidebar(!showSidebar), [setShowSidebar, showSidebar]);
    const refMenu = useRef();
    const refFeedback = useRef();
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        let handlerHelpbar = (event) => {
            if (!refMenu.current.contains(event.target)) {
                setToggle(false);
            }
        };

        document.addEventListener('mousedown', handlerHelpbar);

        return () => {
            document.removeEventListener('mousedown', handlerHelpbar);
        };
    }, [toggle]);

    let adrConfig = window.config?.branding === 'adr' || window.config?.branding === 'dsc2';

    return (
        <StyledNav
            className="navbar"
            tag="nav"
            direction="column"
            align="center"
            justify="start"
            background={theme.global.colors[window.localStorage.getItem('branding') || 'default'].navbarBackgroundColor}
            color={theme.global.colors[window.localStorage.getItem('branding') || 'default'].primaryText}
            style={{ zIndex: '999', borderRight: '1px solid rgba(22, 33, 66, 0.8)' }}
            {...(size === 'small'
                ? {
                      pad: { vertical: 'large' },
                      gap: 'xlarge',
                  }
                : {
                      pad: { vertical: 'medium' },
                      gap: '35px',
                  })}
            {...props}
        >
            <ApplicationIconLink
                href={`/browser/search`}
                iconLabel="Find"
                imgSrc={adrConfig ? logoBlack : logoWhite}
                size={size}
                active
                applicationIconInnerPadding={{
                    // hard-coding pixels is usually not recommended and I'm not sure if it's properly responsive...
                    left: '23px',
                    right: '23px',
                }}
                adrConfig={adrConfig}
                altText="Search datasets"
            />
            {window?.config?.onboarding && (
                <ApplicationIconLink
                    href={`${window?.config.exchangeStem}onboarding`}
                    iconLabel="Onboarding"
                    imgSrc={adrConfig ? onboardingBlack : onboardingWhite}
                    adrConfig={adrConfig}
                    altText="Launch onboarding"
                />
            )}
            {window?.config?.catalogue && (
                <ApplicationIconLink
                    href={`${window?.config.exchangeStem}dashboard/index`}
                    iconLabel="Advanced View"
                    imgSrc={adrConfig ? catalogueBlack : catalogueWhite}
                    adrConfig={adrConfig}
                    altText="Browse catalogue"
                />
            )}
            {window?.config?.manager && (
                <ApplicationIconLink
                    href={`${window?.config.exchangeStem}manager`}
                    iconLabel="Manager"
                    imgSrc={adrConfig ? managerBlack : managerWhite}
                    adrConfig={adrConfig}
                    altText="Launch manager"
                />
            )}
            {window?.config?.importer && (
                <ApplicationIconLink
                    adrConfig={adrConfig}
                    href={window.config.importer}
                    iconLabel="Importer"
                    imgSrc={adrConfig ? importerBlack : importerWhite}
                    altText="Launch importer"
                />
            )}
            {window?.config?.helpbar && (
                <div style={{ width: '90%', height: '1px', background: adrConfig ? 'black' : 'white' }}></div>
            )}
            <div ref={refMenu} style={{ position: 'relative' }}>
                {window?.config?.helpbar && (
                    <LinksWrapper
                        onClick={() => {
                            setToggle(!toggle);
                        }}
                        style={{ position: 'relative' }}
                    >
                        <HelpLink
                            iconLabel="Help"
                            imgSrc={adrConfig ? outlineContactSupportBlack : outlineContactSupportWhite}
                            adrConfig={adrConfig}
                            altText="Get application help"
                        />
                    </LinksWrapper>
                )}
                {toggle && <Links helpbar={true} />}
            </div>
            {window?.config?.feedbackLink && (
                <ApplicationIconLink
                    href={`${window?.config?.feedbackLink}`}
                    iconLabel="Feedback"
                    imgSrc={adrConfig ? CommentDotsIconBlack : CommentDotsIcon}
                    istargetBlank={true}
                    adrConfig={adrConfig}
                    altText="Share feedback"
                />
            )}

            {props.sideBarTitle !== sidebarTitles.structuralMetadata && (
                <StyledMenuButtonBox
                    width="24px"
                    height="24px"
                    background={
                        window.localStorage.getItem('branding') !== 'dsc2'
                            ? theme.global.colors.default.primary
                            : theme.global.colors.dsc2.contactButtonBgColor
                    }
                    align="center"
                    justify="center"
                    round="50%"
                    border={{
                        color: theme.global.colors.backgroundBlue,
                        size: '2px',
                        style: 'solid',
                        side: 'all',
                    }}
                >
                    <Button data-testid="nav-menu-button" plain onClick={onClickMenuButton}>
                        <Box width="20px" height="20px" align="center" justify="center">
                            <StyledMenuButtonArrowImage
                                src={showSidebar ? thinArrowLeftWhite : thinArrowRightWhite}
                                alt={showSidebar ? 'Close Sidebar' : 'Open Sidebar'}
                                arrowDirection={showSidebar ? 'left' : 'right'}
                            />
                        </Box>
                    </Button>
                </StyledMenuButtonBox>
            )}
            <ToolTip />
        </StyledNav>
    );
});

Navbar.propTypes = {
    setShowSidebar: PropTypes.func.isRequired,
    showSidebar: PropTypes.bool.isRequired,
};

Navbar.whyDidYouRender = true;

export default Navbar;
