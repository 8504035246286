import { ResponsiveContext } from 'grommet';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { processMenuItems } from '../../../api/fetchEndpoint';
import { getDataSets } from '../../../api/getDataSets';
import useFetchItemsToRedux from '../../../hooks/useFetchItemsToRedux/useFetchItemsToRedux';
import { MDX_BROWSER_PREFIX, ADR_BROWSER_PREFIX, useTitle } from '../../../hooks/useTitle/useTitle';
import { receiveDataSets, receiveFilterMenuItems, setFilteredDataSets } from '../../../redux/actions/actions';
import store from '../../../redux/store';
import { removeArrayDuplicates } from '../../../utils/javascriptUtilities';
import { Loader } from '../../MDW/MDW';
import { useSideBarAndDataDownloadContext } from '../../templates/GenericPageTemplate/GenericPageTemplate';
import { IndividualDatasetPageContainerFromPrefix } from '../IndividualDatasetPage/IndividualDatasetPageContainer';
import BrokerSearchPage from './BrokerSearchPage';
import { locationIsIndividualDatasetPageLocation } from './BrokerSearchPageContainerUtils';

const BrokerSearchPageContainer = ({ dispatchReceiveDataSets, dispatchReceiveFilterMenuItems, ...props }) => {
    useTitle((window.config?.branding == 'adr' ? ADR_BROWSER_PREFIX : MDX_BROWSER_PREFIX) + 'Search');
    const [errorMessage, setErrorMessage] = React.useState('');

    const lastLocation = useLastLocation();
    const location = useLocation();

    const size = useContext(ResponsiveContext);

    const processDataSetResponse = useCallback(
        (data) => {
            const parameters = queryString.parse(location.search);
            if (data && data.filters) {
                const processedFilters = data.filters.map((item) => {
                    return { ...item, options: processMenuItems(removeArrayDuplicates(item.options), item.name) };
                });
                dispatchReceiveFilterMenuItems(processedFilters);
            }
            if (
                locationIsIndividualDatasetPageLocation(lastLocation) &&
                parameters &&
                Object.keys(parameters)[0] === 'searchterm'
            ) {
                store.dispatch(setFilteredDataSets(data));
            }
            return data;
        },
        [dispatchReceiveFilterMenuItems, lastLocation, location.search],
    );

    const { setShowSidebar } = useSideBarAndDataDownloadContext();

    React.useEffect(() => {
        setShowSidebar(size !== 'small');
        return () => {};
    }, [setShowSidebar, size]);

    const overrideDatasetsState = () => {
        const isFromIndividualDatasetPage = locationIsIndividualDatasetPageLocation(lastLocation);
        if (
            isFromIndividualDatasetPage &&
            (location.state === undefined || location.state?.from === IndividualDatasetPageContainerFromPrefix)
        ) {
            location.state = { from: {} };
            return true;
        } else {
            return false;
        }
    };

    const { isSuccessful: receivedDatasets } = useFetchItemsToRedux(
        getDataSets,
        dispatchReceiveDataSets,
        setErrorMessage,
        'dataSets',
        processDataSetResponse,
        overrideDatasetsState(),
    );

    React.useEffect(() => {
        if (errorMessage !== '') {
            throw new Error(errorMessage);
        }
    }, [errorMessage]);

    return !receivedDatasets ? <Loader /> : <BrokerSearchPage errorMessage={errorMessage} />;
};

const mapDispatchToProps = {
    dispatchReceiveDataSets: receiveDataSets,
    dispatchReceiveFilterMenuItems: receiveFilterMenuItems,
};

BrokerSearchPageContainer.whyDidYouRender = true;

export default connect(null, mapDispatchToProps)(BrokerSearchPageContainer);

export const BrokerSearchPageContainerFromPrefix = 'browser-search-page';

BrokerSearchPageContainer.propTypes = {
    dispatchReceiveDataSets: PropTypes.func.isRequired,
    dispatchReceiveFilterMenuItems: PropTypes.func.isRequired,
};
